import {
  React,
  numeral,
  _
} from "$Imports/Imports";

import {
  BookedSalesReportView
} from "$Generated/api";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  CompanyLegend
} from "$Imports/CommonComponents";

import {
  GraphLabel
} from "$Components/common/Recharts/GraphLabel";

import {
  renderSalesMetricCells
} from "./BookedSalesMetricCell";

import {
  BookedSalesTooltip
} from "./BookedSalesTooltip";

import {
  getBarHeight,
  getContainerMinHeight
} from "$Utilities/barChartUtil";

interface IGraphFeetProps {
  bookedSalesData: BookedSalesReportView[];
}

export class GraphFeet extends React.Component<IGraphFeetProps> {

  render() {
    const {
      bookedSalesData
    } = this.props;

    // lodash orderBy returns a new array
    const feetGraphData = _.orderBy(bookedSalesData, "totalRollupLength").reverse();
    const height = getBarHeight(feetGraphData.length);
    const minHeight = getContainerMinHeight(feetGraphData.length > 0);

    return (
      <ResponsiveContainer height={height} minHeight={minHeight} width="99%">      
        <BarChart
          data={feetGraphData}
          layout="vertical"
        >
          <XAxis
            type="number"
            xAxisId={1}
          />
          <YAxis
            dataKey="username"
            type="category"
            yAxisId={1}
            width={90}
          />
          <Bar
            dataKey="totalRollupLength"
            xAxisId={1}
            yAxisId={1}
          >
            {renderSalesMetricCells(feetGraphData)}
            <LabelList dataKey="totalRollupLength" content={(props)=>( 
                <GraphLabel
                  {...props}
                  formatValue={(value) =>(numeral(value).format("0,0") + " ft")}
                />
              )} 
            />
          </Bar>
          <Tooltip
            cursor={{ stroke: "#5a5c63", strokeWidth: "1", fillOpacity: "0" }}
            content={(props: any) => {
              return (
                <BookedSalesTooltip
                  payload={props.payload?.[0]?.payload}
                  isActive={props.active}
                />
              );
            }}
          />
          <Legend content={<CompanyLegend showOrange />} />
          <CartesianGrid horizontal={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
