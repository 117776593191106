import {
  React
} from "$Imports/Imports";

import {
  BookedSalesReportView
} from "$Generated/api";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

interface IBookedSalesTooltipProps {
  payload?: BookedSalesReportView | undefined;
  isActive?: boolean | undefined;
}

const styles: {
  tooltip: string;
  spaceAbove: string;
} = require("./BookedSalesView.scss");

export class BookedSalesTooltip extends React.PureComponent<IBookedSalesTooltipProps> {

  render() {
    const {
      payload,
      isActive
    } = this.props;

    return payload && isActive ?
      (
        <div className={styles.tooltip}>
          <table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{payload.username}</td>
              </tr>
              <tr>
                <td>Company</td>
                <td>{payload.companyName}</td>
              </tr>
              <tr>
                <td>Terminal</td>
                <td>{payload.terminalName}</td>
              </tr>
              <tr>
                <td className={styles.spaceAbove}>Sales</td>
                <td><DisplayFormattedNumber value={payload.charges} formatString="$0,0" /></td>
              </tr>
              <tr>
                <td>FB Count</td>
                <td><DisplayFormattedNumber value={payload.bills} formatString="0,0" /></td>
              </tr>
              <tr>
                <td>FB Miles</td>
                <td><DisplayFormattedNumber value={payload.totalDistance} formatString="0,0" /></td>
              </tr>
              <tr>
                <td className={styles.spaceAbove}>Weight</td>
                <td><DisplayFormattedNumber value={payload.totalWeight} formatString="0,0" /></td>
              </tr>
              <tr>
                <td>Rate/K</td>
                <td><DisplayFormattedNumber value={payload.rk} formatString="$0,0" /></td>
              </tr>
              <tr>
                <td>Rate/K/Mile</td>
                <td><DisplayFormattedNumber value={payload.rkm} formatString="0,0.00" /></td>
              </tr>
              <tr>
                <td className={styles.spaceAbove}>Feet</td>
                <td><DisplayFormattedNumber value={payload.totalRollupLength} formatString="0,0" /></td>
              </tr>
              <tr>
                <td>Rate/Ft</td>
                <td><DisplayFormattedNumber value={payload.rf} formatString="$0,0" /></td>
              </tr>
              <tr>
                <td>Rate/Ft/Mile</td>
                <td><DisplayFormattedNumber value={payload.rfm} formatString="$0,0.00" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      )
      : null;
  }
}