import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

export const defaultTheme: IThemeConfiguration = {
  themeOptions: {
    palette: {
      primary: {
        main: "#8C3F61",
      },
      secondary: {
        main: "#0071EB",
      },
      background: {
        default: "#E0E0E0"
      }
    },
    components: {

      MuiCard: {
        styleOverrides:
        {
          root: {
            fontSize: "14px",
            color: "#000000DE"
          }
        }
      },
      MuiTextField: {
        defaultProps:{
          variant: "standard"
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard"
        }
      },
      MuiCardHeader: {        
        defaultProps: {
          title: {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#5a5c63",
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "14px"
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "14px"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip:{
            fontSize: "14px"
          }
        }
      },
      MuiButton:{
        defaultProps:{
          variant: "contained"
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-child(even):not(.Mui-selected)": { // for specificity reasons. just trust me.
              backgroundColor: "#f2f2f2"
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            color: "#8C3F61"
          },
          root: {
            padding: "10px",
            borderBottom: "0"
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
              fontWeight: "bold"
          },
          row: {
            "&:nth-child(even):not(.Mui-selected)": { // specificity
              backgroundColor: "#f2f2f2"
            },
            "&:hover": {
              backgroundColor: "initial"
            }
          },
          cell: {
            borderBottom: 0,
            ":focus": {
              outline: "none"
            },
            ":focus-within": {
              outline: "none"
            }
          },
          columnHeader: {
            ":focus-within": {
              outline: "none"
            }
          }
        }
      }
    }
  },
  sideNavigationColor: "#212121",
  sideNavigationMenu: {
    components: {
      MuiMenuItem:{
        styleOverrides:{
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.Mui-selected": {
              "backgroundColor": "#8C3F61"
            }
          }
        }
      },
      MuiTab: {
        styleOverrides:{
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.Mui-selected": {
              "backgroundColor": "#0071EB"
            }
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides:{
          root: {
            maxWidth: "inherit",
            color: "white",
            fontSize: "14px",
            paddingTop: "10px",
            paddingBottom: "10px"
          }
        }
      }      
    }
  },
  appBarNavigationMenu: {
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
          },
          indicator: {
            display: "none"
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: "white",
            "&.Mui-selected": {
              color: "black",
              fontWeight: "bold",
              backgroundColor: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px"
            }
          },
        }
      }
    }
  },
};
