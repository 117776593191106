export default class SharedConstants {
    // 
    public static ZipCodeRegex = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$|^\d{5}$/;
    
    public static USZipCodeRegex = /^\d{5}$/;

    public static CanadianZipCodeRegex = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;

    public static TimeRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/;

    public static PhoneNumberRegex = /^\d{3}-?\d{3}-?\d{4}$/;

    public static PhoneNumberExtRegex = /^\d{3}-?\d{3}-?\d{4}(\s[x]\d{1,6})?$/;

    public static CityStateRegex = /^.*,[ ]?[A-Za-z]{2}[ ]*$/;

    public static lbsKgConversion = 2.20462262;

    public static inchesFtConversion = 12;

    public static inchesMmConversion = 25.4;

    public static mmsMeterConversion = 1000;

    public static mmsCmConversion = 10;
}