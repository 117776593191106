import {
  React,
  bind
} from "$Imports/Imports";

import { IThemeConfiguration } from "$Themes/themeConfiguration";
import { defaultTheme } from "$Themes/defaultTheme";

interface IYaharaThemeContext {
  themeConfig: IThemeConfiguration;
}

const defaultThemeContext: IYaharaThemeContext = {
  themeConfig: defaultTheme,
};

const YaharaThemeContext = React.createContext(defaultThemeContext);

const YaharaThemeConsumer = YaharaThemeContext.Consumer;

interface IYaharaThemeProviderProps {
  themeConfig: IThemeConfiguration;
}

interface IYaharaThemeProviderState {
  themeContext: IYaharaThemeContext;
  lastThemeConfig: IThemeConfiguration | null;
}

export class YaharaThemeProvider extends React.Component<IYaharaThemeProviderProps, IYaharaThemeProviderState> {
  state = {
    themeContext: {
      themeConfig: defaultTheme,
    },
    lastThemeConfig: null,
  };

  static getDerivedStateFromProps(props: IYaharaThemeProviderProps, state: IYaharaThemeProviderState): Partial<IYaharaThemeProviderState> | null {

    // Update the state of the lastValue does not match the prop.
    if (state === null || state.lastThemeConfig === null || props.themeConfig !== state.lastThemeConfig) {
      return {
        themeContext: {
          themeConfig: props.themeConfig
        },
        lastThemeConfig: props.themeConfig
      };
    }
    return null;
  }

  render() {
    return (
      <YaharaThemeContext.Provider
        value={this.state.themeContext}
      >
        {this.props.children}
      </YaharaThemeContext.Provider>
    );
  }
}

export {
  YaharaThemeConsumer as ThemeConsumer,
  YaharaThemeContext as ThemeContext
};
