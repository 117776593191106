import {
  React,
  _,
  bind,
  numeral,
  memoizeOne
} from "$Imports/Imports";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  DataGridPro,
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  TripDetailsLegView
} from "$Generated/api";

import {
  CURRENCY_FORMAT,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

interface IOwnProps {
  legs: TripDetailsLegView[];
}

// leg data does not have a unique identifier, assign the index to support lookback
interface ILegEntry extends TripDetailsLegView {
  id: number;
}

function addLegIds(entries: TripDetailsLegView[]): ILegEntry[] {
  return _.map(entries, (x, idx) => ({
    id: idx,
    ...x
  }));
}

const styles: {
  noSelection: string;
} = require("./TripDetails.scss");

class _TripDetails extends React.PureComponent<IOwnProps> {
  private readonly _addLegIds = memoizeOne(addLegIds);

  private readonly _columns: GridColDef[] = [{
    headerName: "Leg #",
    field: "legNumber",
    sortable: false,
    width: 60
  }, {
    headerName: "Miles",
    field: "distance",
    valueGetter: this._emptyIfMatchedLookback,
    renderCell: (params: GridRenderCellParams<number | undefined>) => params.value !== undefined
      ? <DisplayFormattedNumber value={params.value} formatString={NUMERIC_SEPARATED_FORMAT} postfix=" mi" />
      : "",
    sortable: false,
    width: 80
  }, {
    headerName: "Origin City",
    field: "originCity",
    valueGetter: this._emptyIfMatchedLookback,
    sortable: false,
    flex: 1
  }, {
    headerName: "Origin Zip",
    field: "originPostalCode",
    valueGetter: this._emptyIfMatchedLookback,
    sortable: false,
    width: 90
  }, {
    headerName: "Destination City",
    field: "destinationCity",
    valueGetter: this._emptyIfMatchedLookback,
    sortable: false,
    flex: 1
  }, {
    headerName: "Destination Zip",
    field: "destinationPostalCode",
    valueGetter: this._emptyIfMatchedLookback,
    sortable: false,
    width: 120
  }, {
    headerName: "P/D",
    field: "legType",
    sortable: false,
    width: 40
  }, {
    headerName: "FB #",
    field: "billNumber",
    sortable: false,
    width: 120
  }, {
    headerName: "FB Destination",
    field: "billDestination",
    sortable: false,
    flex: 1
  }, {
    headerName: "Revenue",
    field: "revenue",
    valueGetter: this._emptyIfNoPDType,
    renderCell: (params: GridRenderCellParams<number | undefined>) => params.value === undefined
      ? "-"
      : <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
    sortable: false
  }, {
    headerName: "Feet",
    field: "length",
    valueGetter: this._emptyIfNoPDType,
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => this._denotePositive(params, " ft"),
    sortable: false,
    width: 60
  }, {
    headerName: "Weight",
    field: "weight",
    valueGetter: this._emptyIfNoPDType,
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => this._denotePositive(params, " lbs"),
    sortable: false
  }];

  @bind
  private _emptyIfMatchedLookback(params: GridValueGetterParams<any, ILegEntry>): GridCellValue {
    const index = params.id as number;
    const lookback = index ? this.props.legs[index - 1] : undefined;

    return lookback?.legNumber === params.row.legNumber ? undefined : params.value;
  }

  private _emptyIfNoPDType(params: GridValueGetterParams<any, ILegEntry>): GridCellValue {
    return !params.row.legType ? undefined : params.value;
  }

  private _denotePositive(params: GridValueFormatterParams, unit: string): GridCellValue {
    const value = params.value as number | undefined;
    if (value === undefined) {
      return "-";
    }

    const formatted = numeral(value).format(NUMERIC_SEPARATED_FORMAT) + unit;

    return value > 0 ? `+${formatted}` : formatted;
  }

  render() {
    const legs = this._addLegIds(this.props.legs);

    return (
      <>
        {legs.length ? (
          <DataGridPro
            columns={this._columns}
            rows={legs}
            density="compact"
            autoHeight
            disableChildrenSorting
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
          />
        ) : (
          <div className={styles.noSelection}>
            Select a trip to view leg-specific details
          </div>
        )}
      </>
    );
  }
}

export const TripDetails = _TripDetails;
