import {
  React,
  _,
  bind,
  numeral,
  memoizeOne,
  moment
} from "$Imports/Imports";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  DataGridPro,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  Grid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
  Stack,
  Button,
  GridValueFormatterParams,
  GridApiPro
} from "$Imports/MaterialUIComponents";

import {
  Download
} from "$Imports/MaterialUIIcons";

import {
  LaneRevenueParametersVM,
  LaneRevenueReportView
} from "$Generated/api";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";

import {
  ICompanyServiceInjectedProps,
  CompanyService
} from "$State/CompanyFreezerService";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT,
  NUMERIC_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

import {
  LaneRevenueFilters
} from "./LaneRevenueFilters"

import {
  LaneRevenueSearchForm
} from "./LaneRevenueSearchForm";

import {
  ReportMetricCell
} from "./ReportMetricCell";

import {
  TripDetails
} from "./TripDetails";

interface IOwnProps {
  companyId?: number;
}

type OwnProps = IOwnProps
  & IReportServiceInjectedProps
  & IStateServiceInjectedProps
  & ICompanyServiceInjectedProps;

interface IOwnState {
  selectedTripNumber?: number;
  selectedOriginPostalCodes: string[];
  selectedDestinationPostalCodes: string[];
  reportFilter: GridFilterModel;
}

const styles: {
  mainContainer: string,
  filtersContainer: string,
  metricsContainer: string,
  reportContainer: string,
  tripDetailsHeader: string
} = require("./LaneRevenueView.scss");
class _LaneRevenueView extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    selectedOriginPostalCodes: [],
    selectedDestinationPostalCodes: [],
    reportFilter: { items: [] }
  };

  private readonly _numericOperators = getGridNumericOperators();
  private readonly _selectOperators = getGridSingleSelectOperators();
  private readonly _dateOperators = getGridDateOperators();

  // columns with select filters (origin/destination city) need available options provided
  private _getColumns = (originCities: string[], destinationCities: string[]): GridColDef[] => [{
    headerName: "Trip #",
    field: "tripNumber",
    width: 70,
    filterOperators: this._numericOperators
  }, {
    headerName: "Completion Date",
    field: "completeDate",
    valueFormatter: (params: GridValueFormatterParams<Date | undefined>) => !!params.value ? moment(params.value).local().format(DATE_ONLY_FORMAT) : "",
    renderCell: (params: GridRenderCellParams<Date | undefined>) => !!params.value ? <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} /> : "",
    width: 130,
    filterOperators: this._dateOperators
  }, {
    headerName: "Origin City",
    field: "originCity",
    flex: 1,
    filterOperators: this._selectOperators,
    valueOptions: _.map(originCities, (x) => ({ label: x, value: x }))
  }, {
    headerName: "Origin Zip",
    field: "originPostalCode",
    width: 90,
    filterable: false
  }, {
    headerName: "Destination City",
    field: "destinationCity",
    flex: 1,
    filterOperators: this._selectOperators,
    valueOptions: _.map(destinationCities, (x) => ({ label: x, value: x }))
  }, {
    headerName: "Destination Zip",
    field: "destinationPostalCode",
    width: 120,
    filterable: false
  }, {
    headerName: "Revenue",
    field: "revenue",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
    renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "",
    filterOperators: this._numericOperators
  }, {
    headerName: "Miles",
    field: "distance",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(NUMERIC_SEPARATED_FORMAT) : "",
    renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} postfix=" mi" formatString={NUMERIC_SEPARATED_FORMAT} /> : "",
    width: 80,
    filterOperators: this._numericOperators
  }, {
    headerName: "Feet",
    field: "length",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(NUMERIC_SEPARATED_FORMAT) : "",
    renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} postfix=" ft" formatString={NUMERIC_SEPARATED_FORMAT} /> : "",
    width: 60,
    filterOperators: this._numericOperators
  }, {
    headerName: "Weight",
    field: "weight",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(NUMERIC_SEPARATED_FORMAT) : "",
    renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} postfix=" lbs" formatString={NUMERIC_SEPARATED_FORMAT} /> : "",
    filterOperators: this._numericOperators
  }, {
    headerName: "$/Ft/Mile",
    field: "rateLength",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
    valueGetter: (params: GridValueGetterParams<undefined, LaneRevenueReportView>) =>
      params.row.length && params.row.distance
        ? (params.row.revenue ?? 0) / params.row.length / params.row.distance
        : undefined,
    renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} emptyDisplay="-" formatString={CURRENCY_FORMAT} /> : "",
    width: 80,
    filterOperators: this._numericOperators
  }, {
    headerName: "$/K/Mile",
    field: "rateWeight",
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
    valueGetter: (params: GridValueGetterParams<undefined, LaneRevenueReportView>) =>
      params.row.weight && params.row.distance
        ? (params.row.revenue ?? 0) / (params.row.weight / 1000) / params.row.distance
        : undefined,
    renderCell: (params: GridRenderCellParams<number | undefined>) =>!!params.value ? <DisplayFormattedNumber value={params.value} emptyDisplay="-" formatString={CURRENCY_FORMAT} /> : "",
    width: 80,
    filterOperators: this._numericOperators
  }];

  private getColumns_memoized = memoizeOne(this._getColumns);

  // pull available postal code and city filters from full returned dataset
  private _getLocations = (reportData: LaneRevenueReportView[]) => {    
    const originCityMap = new Map();
    const originPostalCodeMap = new Map();
    const destinationCityMap = new Map();
    const destinationPostalCodeMap = new Map();      

    if (reportData.length) {
      reportData.forEach((x) => {
        originCityMap.set(x.originCity, x.originCity);
        originPostalCodeMap.set(x.originPostalCode, x.originPostalCode);
        destinationCityMap.set(x.destinationCity, x.destinationCity);
        destinationPostalCodeMap.set(x.destinationPostalCode, x.destinationPostalCode);
      });
    }

    return {
      originCities: Array.from(originCityMap.values()).sort(),
      originPostalCodes: Array.from(originPostalCodeMap.values()).sort(),
      destinationCities: Array.from(destinationCityMap.values()).sort(),
      destinationPostalCodes: Array.from(destinationPostalCodeMap.values()).sort()
    };
  }

  private getLocations_memoized = memoizeOne(this._getLocations);

  componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.companyService.fetchData();
  }

  @bind
  private _onSubmit(criteria: LaneRevenueParametersVM) {
    this.props.reportService.fetchLaneRevenueReport(criteria);

    // reset all in-grid filters when a fresh dataset is requested
    this.setState({
      reportFilter: { items: [] }
    });
  }

  @bind
  private _onSelectTrip(params: GridSelectionModel) {
    if (!params || !params.length) {
      this.setState({ selectedTripNumber: undefined });
      return;
    }

    const tripNumber = params[0] as number;
    if (tripNumber === this.state.selectedTripNumber) {
      return;
    }

    this.props.reportService.fetchTripDetails(tripNumber!);

    this.setState({ selectedTripNumber: tripNumber });
  }

  @bind
  private _onFilter(selectedOriginPostalCodes: string[], selectedDestinationPostalCodes: string[]) {
    this.setState({
      selectedOriginPostalCodes: selectedOriginPostalCodes,
      selectedDestinationPostalCodes: selectedDestinationPostalCodes
    });
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      selectedTripNumber,
      selectedOriginPostalCodes,
      selectedDestinationPostalCodes,
      reportFilter
    } = this.state;

    const {
      laneRevenueFetchResults,
      laneRevenueParameters,
      tripDetailsFetchResults
    } = this.props.reportService.getState();

    const {
      companyFetchResults
    } = CompanyService.getState();
    const companies = companyFetchResults.data ?? [];

    const { regionFetchResults } = this.props.regionService.getState();

    const reportRows = laneRevenueFetchResults.data ?? [];
    const filteredRows: LaneRevenueReportView[] = [];

    const {
      originCities,
      originPostalCodes,
      destinationCities,
      destinationPostalCodes      
    } = this.getLocations_memoized(reportRows);

    const columns = this.getColumns_memoized(originCities, destinationCities);

    const selectedTripLegs = selectedTripNumber
      ? tripDetailsFetchResults.data ?? []
      : [];

    // metric cards - only recalculate on data change; sorting changes handled by grid itself
    // client-side aggregation, as rows are filtered on client-side
    let totalRevenue = 0;
    let totalLength = 0;
    let totalWeight = 0;
    let totalDistance = 0;

    // metrics act on the pre-filtered (origin/destination zip) data only
    // additional grid/column filters _will not_ be reflected in the metrics
    reportRows.forEach((x) => {
      if (selectedOriginPostalCodes.length && (selectedOriginPostalCodes.indexOf(x.originPostalCode!) === -1)) {
        return;
      }

      if (selectedDestinationPostalCodes.length && (selectedDestinationPostalCodes.indexOf(x.destinationPostalCode!) === -1)) {
        return;
      }

      totalRevenue += (x.revenue ?? 0);
      totalLength += (x.length ?? 0);
      totalWeight += (x.weight ?? 0);
      totalDistance += (x.distance ?? 0);

      filteredRows.push(x);
    });

    const rateFtMi = (totalLength && totalDistance)
      ? numeral(totalRevenue / totalLength / totalDistance).format(CURRENCY_FORMAT)
      : "-";

    const rateKMi = (totalWeight && totalDistance)
      ? numeral(totalRevenue / (totalWeight / 1000) / totalDistance).format(CURRENCY_FORMAT)
      : "-";

    return (
      <div className={styles.mainContainer}>
        <div className={styles.filtersContainer}>
          <LaneRevenueFilters
            allOriginPostalCodes={originPostalCodes}
            allDestinationPostalCodes={destinationPostalCodes}
            onSubmit={this._onFilter}
          />
        </div>

        <CardLinedHeader
          style={{ display: "flex", flex: "1", flexDirection: "column" }}
          titleText="Lane Revenue Analysis"
          titleComponents={
            <Button
              className="cardHeaderButton"
              onClick={() => OnExportCsvClick('Lane_Revenue_Report_', this._gridApiRef)}
              title="Download"
              disabled={filteredRows.length === 0}
            >
              <Download /><b> Download</b>
            </Button>
          }
        >
          <LaneRevenueSearchForm
            criteria={laneRevenueParameters}
            regions={regionFetchResults.data ?? []}
            companies={companies}
            onSubmit={this._onSubmit}
          />

          <Grid
            className={styles.metricsContainer}
            gap={"1rem"}
            container
          >
            <ReportMetricCell
              title="Total Trips"
              value={filteredRows.length.toString()}
            />

            <ReportMetricCell
              title="Revenue / Ft / Mi"
              value={rateFtMi}
            />

            <ReportMetricCell
              title="Revenue / K / Mi"
              value={rateKMi}
            />

            <ReportMetricCell
              title="Total Revenue"
              value={numeral(totalRevenue).format(CURRENCY_FORMAT)}
            />

            <ReportMetricCell
              title="Total Miles"
              value={numeral(totalDistance).format(NUMERIC_SEPARATED_FORMAT)}
            />
          </Grid>

          <AjaxActionIndicator state={laneRevenueFetchResults} />

          <div className={styles.reportContainer}>
            <DataGridPro
              columns={columns}
              rows={filteredRows}
              density="compact"
              getRowId={(row: LaneRevenueReportView) => row.tripNumber ?? 0}
              onFilterModelChange={(model) => { this.setState({ reportFilter: model }) }}
              onSelectionModelChange={this._onSelectTrip}              
              components={{
                NoRowsOverlay: () =>
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {!laneRevenueFetchResults.hasFetched
                      ? "Enter search criteria to view trip data"
                      : "No trip data for selected criteria or all trips have been filtered out"
                    }
                  </Stack>,
                NoResultsOverlay: () =>
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    All trips have been filtered out
                  </Stack>
              }}
              initialState={{
                sorting: { sortModel: [{ field: "completeDate", sort: "asc" }] }
              }}
              apiRef={this._gridApiRef}
              filterModel={reportFilter}
              disableMultipleSelection
              hideFooter
            />
          </div>

          {(filteredRows.length > 0) && (
            <>
              <div className={styles.tripDetailsHeader}>
                Trip {selectedTripNumber ? `#${selectedTripNumber}` : ""} Details
              </div>

              <AjaxActionIndicator state={tripDetailsFetchResults} />

              <TripDetails legs={selectedTripLegs} />
            </>
          )}
        </CardLinedHeader>
      </div>
    );
  }
}

export const LaneRevenueView = ReportService.inject(
  StateService.inject(
    CompanyService.inject(
      _LaneRevenueView
    )
  )
);
