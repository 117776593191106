import {
  React,
  numeral,
  _
} from "$Imports/Imports";

import {
  BookedSalesReportView
} from "$Generated/api";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  CompanyLegend
} from "$Imports/CommonComponents";

import {
  GraphLabel
} from "$Components/common/Recharts/GraphLabel";

import {
  renderSalesMetricCells
} from "./BookedSalesMetricCell";

import {
  BookedSalesTooltip
} from "./BookedSalesTooltip";

import {
  getBarHeight,
  getContainerMinHeight
} from "$Utilities/barChartUtil";

interface IGraphSalesProps {
  bookedSalesData: BookedSalesReportView[];
}

export class GraphSales extends React.Component<IGraphSalesProps> {

  render() {
    const {
      bookedSalesData
    } = this.props;

    // lodash orderBy returns a new array
    const salesGraphData = _.orderBy(bookedSalesData, "charges").reverse();
    const height = getBarHeight(salesGraphData.length);
    const minHeight = getContainerMinHeight(salesGraphData.length > 0);

    return (
      <ResponsiveContainer height={height} minHeight={minHeight} width="99%">      
        <BarChart
          data={salesGraphData}
          layout="vertical"
        >
          <XAxis
            type="number"
            xAxisId={1}
          />
          <YAxis
            dataKey="username"
            type="category"
            yAxisId={1}
            width={90}
          />
          <Bar
            dataKey="charges"
            xAxisId={1}
            yAxisId={1}
          >
            {renderSalesMetricCells(salesGraphData)}
            <LabelList dataKey="charges" content={(props)=>( 
                <GraphLabel
                  {...props}
                  formatValue={(value) =>(numeral(value).format("$ 0,0"))}
                />
              )} 
            />
          </Bar>

          <Tooltip
            cursor={{ stroke: "#5a5c63", strokeWidth: "1", fillOpacity: "0" }}
            content={(props: any) => {
              return (
                <BookedSalesTooltip
                  payload={props.payload?.[0]?.payload}
                  isActive={props.active}
                />
              );
            }}
          />
          <Legend content={<CompanyLegend showOrange />} />
          <CartesianGrid horizontal={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
