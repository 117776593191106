import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  FormHelperText,
  ListItemText
} from "$Imports/MaterialUIComponents";

import {
  OpportunitySearchCriteria,
  OpportunitySearchCriteriaDateTypeEnum,
  OpportunitySearchCriteriaStatusEnum,
  Employee,
  Region
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  OpportunityReportCriteriaValidationSchema
} from "$State/ReportFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  searchContainer: string;
} = require("./SalesOpportunities.scss");

interface IOwnProps {
  criteria: OpportunitySearchCriteria;
  salesReps: Employee[];
  regions: Region[];
  onSubmit: (criteria: OpportunitySearchCriteria) => void;
}

interface IOwnState {
  criteria: OpportunitySearchCriteria;
  errors: ValidationError | null;
}

export class SalesOpportunitiesSearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    criteria: {
      dateType: "CloseDate",
      status: ["Discovery", "Interested", "Converted"]
    },
    errors: null
  };

  componentDidMount() {
    this.setState({ criteria: this.props.criteria });
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.criteria !== prev.criteria) {
      this.setState({ criteria: this.props.criteria });
    }
  }

  @bind
  private _onDateTypeChange(event: SelectChangeEvent) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        dateType: event.target.value as OpportunitySearchCriteriaDateTypeEnum
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onStatusChange(event: SelectChangeEvent<OpportunitySearchCriteriaStatusEnum[]>) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        status: event.target.value as OpportunitySearchCriteriaStatusEnum[]
      }
    }));
  }

  @bind
  private _onSaleRepChange(event: SelectChangeEvent<number>) {
    const salesRepId = +event.target.value;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        createdById: salesRepId === 0 ? undefined : salesRepId
      }
    }));
  }

  @bind
  private _onRegionChange(event: SelectChangeEvent<number | string>) {
    const regionId = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        customerRegionId: regionId === 0 ? undefined : regionId
      }
    }));
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(OpportunityReportCriteriaValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(this.state.criteria);
  }

  render() {
    const {
      regions,
      salesReps
    } = this.props;

    const {
      criteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<OpportunitySearchCriteria>(errors);
    const statusHasError = !validationParser.isValid("status");

    const statusArray: OpportunitySearchCriteriaStatusEnum[] = [
      "Discovery",
      "Interested",
      "Converted",
      "Abandoned"
    ];

    return (
      <SearchControlsContainer
        className={styles.searchContainer}
        onSubmit={this._onSearchClick}
      >
        <FormControl style={{ flex: "1 1", alignSelf: "end" }}>
          <InputLabel>Date Type</InputLabel>
          <Select
            onChange={this._onDateTypeChange}
            value={criteria.dateType}
          >
            <MenuItem value={"CloseDate"}>Close Date</MenuItem>
            <MenuItem value={"CreationDate"}>Creation Date</MenuItem>
          </Select>
        </FormControl>
        <DateRangePicker
          startDate={criteria.startDate}
          startError={validationParser.validationMessage("startDate")}
          endDate={criteria.endDate}
          endError={validationParser.validationMessage("endDate")}
          onChange={this._onDateRangeChange}
          horizontal
        />
        <FormControl style={{ flex: "1 1" }} error={statusHasError}>
          <InputLabel>Status</InputLabel>
          <Select
            value={criteria.status}
            onChange={this._onStatusChange}
            multiple
            renderValue={(selected) => {
              if (selected.length === 6) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as OpportunitySearchCriteriaStatusEnum[], (s, idx) => {
                  return (
                    <span key={idx}>
                      {s}
                      <>{idx !== (selected as OpportunitySearchCriteriaStatusEnum[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            {
              statusArray.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={_.findIndex(criteria.status, s => s === status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                )
              })
            }
          </Select>
          {statusHasError && <FormHelperText error={statusHasError}>{validationParser.validationMessage("status")}</FormHelperText>}
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel shrink>Sales Representative</InputLabel>
          <Select
            value={criteria.createdById ?? 0}
            onChange={this._onSaleRepChange}
            displayEmpty
          >
            <MenuItem value={"0"}>
              All
            </MenuItem>
            {salesReps.map((e, idx) =>
              <MenuItem value={e.id} key={idx}>
                {`${e.firstName} ${e.lastName}`}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel>Region</InputLabel>
          <Select
            value={criteria.customerRegionId ?? ""}
            onChange={this._onRegionChange}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {regions.map((region, idx) =>
              <MenuItem value={region.id} key={idx}>
                {region.regionName}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </SearchControlsContainer>
    );
  }
}