// convert enumeration (string) values into fit-for-display values
import {
  QuoteApprovalReasonApprovalNeededReasonIdEnum
} from "$Generated/api";

export const approvalReasonTextMap: { [key in QuoteApprovalReasonApprovalNeededReasonIdEnum | ""]: string } = {
  "": "",
  "DeclaredValue": "Declared Value",
  "OverDimensional": "Over-dimensional",
  "LowNegotiatedRate": "Low Negotiated Rate",
  "MileageDifferenceOverThreshold": "Mileage Out of Range",
  "NegotiatedRateOutOfRange": "Negotiated Rate Out of Range",
  "ShipperZoneChanged": "Shipper Address Changed",
  "ConsigneeZoneChanged": "Consignee Address Changed"
};
