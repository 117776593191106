import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  AboutApiFactory,
  AboutVM
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import { 
  ErrorService 
} from "./ErrorFreezerService";

interface IAboutServiceState {
  modalOpen: boolean;
  aboutFetchResults: IAjaxState<AboutVM>;
}

const InjectedPropName = "aboutService";

const initialState = {
  modalOpen: false,
  aboutFetchResults: managedAjaxUtil.createInitialState()
} as IAboutServiceState;

class AboutFreezerService extends FreezerService<IAboutServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchData() {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "aboutFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = AboutApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1AboutGet(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch application version info.");
      }
    })
  }


  public openModal() {
    this.freezer.get().set({ modalOpen: true });
  }

  public closeModal() {
    this.freezer.get().set({ modalOpen: false });
  }
}

export const AboutService = new AboutFreezerService();
export type IAboutServiceInjectedProps = ReturnType<AboutFreezerService["getPropsForInjection"]>;
