import {
  React,
  _
} from "$Imports/Imports";

import {
  SharedSecurityContext
} from '$Shared/utilities/Security/ApplicationSecuritySettings';

interface IUserAccessControlProps {
  roles: string[];
}

export class UserAccessControl extends React.PureComponent<IUserAccessControlProps> {
  render() {
    const {
      roles
    } = this.props;

    return SharedSecurityContext.hasRole(roles) ? this.props.children : null;
  }
}
