import {
  moment
} from "../imports/Imports";

import {
  yup,
  AnyObject,
  Maybe
} from "../imports/Yup";

import SharedConstants from "./SharedConstants";

yup.addMethod<yup.StringSchema>(yup.string, "zipCode", function (message?: string) {
  return this.matches(SharedConstants.ZipCodeRegex, "Invalid zip code format.")
});

yup.addMethod<yup.StringSchema>(yup.string, "usZipCode", function (message?: string) {
  return this.matches(SharedConstants.USZipCodeRegex, "Invalid zip code format.")
});

yup.addMethod<yup.StringSchema>(yup.string, "canadianZipCode", function (message?: string) {
  return this.matches(SharedConstants.CanadianZipCodeRegex, "Invalid zip code format.")
});

yup.addMethod<yup.StringSchema>(yup.string, "allowEmpty", function allowEmpty() {
  return this.transform((value) => {
    return (value === "") || (value === null) ? undefined : value;
  });
});

yup.addMethod<yup.NumberSchema>(yup.number, "allowNaN", function allowNaN() {
  return this.transform((value) => {
    return isNaN(value) || (value === "") || (value === null) ? undefined : value;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, "timeHHmm", function (message?: string) {
  return this.matches(SharedConstants.TimeRegex, "Invalid time")
});

yup.addMethod<yup.StringSchema>(yup.string, "phoneNumber", function (message?: string) {
  return this.matches(SharedConstants.PhoneNumberExtRegex, "Invalid phone number");
});

yup.addMethod<yup.StringSchema>(yup.string, "cityState", function (message?: string) {
  return this.matches(SharedConstants.CityStateRegex, "Invalid City, State search");
});

// plain url() allows non-http/https protocols
yup.addMethod<yup.StringSchema>(yup.string, "website", function (message?: string) {
  return this.test("website", "${message}", (value: string | undefined, testContext: any) => {
    if (value === undefined) {
      return true;
    }

    if (!value.startsWith("http://") && !value.startsWith("https://")) {
      return testContext.createError({ message: message });
    }

    return true;
  })
    .url("Invalid URL");
});

yup.addMethod<yup.DateSchema>(yup.date, "mustBeAfter", function (field: string, message: string | undefined) {
  return this.when(field, {
    is: (value?: Date) => !!value,
    then: (schema) => schema.test(field, "${message}", (value: Date | undefined, testContext: any) => {
      const startDate = testContext.parent[field];

      if (moment(value).isBefore(moment(startDate))) {
        return testContext.createError({ message: message });
      }

      return true;
    })
  })
});

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
    zipCode(message?: string): StringSchema<TType, TContext>;
    usZipCode(message?: string): StringSchema<TType, TContext>;
    canadianZipCode(message?: string): StringSchema<TType, TContext>;
    allowEmpty(): StringSchema<TType, TContext>;
    timeHHmm(message?: string): StringSchema<TType, TContext>;
    phoneNumber(message?: string): StringSchema<TType, TContext>;
    cityState(message?: string): StringSchema<TType, TContext>;
    website(message?: string): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    allowNaN(): NumberSchema<TType, TContext>;
  }

  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    mustBeAfter(field: keyof TContext, message?: string): DateSchema<TType, TContext>;
  }
}

export default yup;