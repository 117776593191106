import {
  transportBlue,
  caTerminalOrange,
  logisticsGreen
} from "$Themes/index"

interface IOwnProps {
  blueValue?: string;
  greenValue?: string;
  showOrange?: boolean;
}

export const CompanyLegend = (props: IOwnProps): JSX.Element => {
  return (
    <ul style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
      <li style={{ display: "inline-block", marginRight: "10px" }}>
        <svg
          width={14}
          height={14}
          viewBox={"0 0 32 32"}
          version={"1.1"}
          style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}
        >
          <path stroke={"none"} fill={transportBlue} d={"M0,4h32v24h-32z"} />
        </svg>
        <span>{props.blueValue ?? "WI Terminal"}</span>
      </li>
      <li style={{ display: "inline-block", marginRight: "10px" }}>
        <svg
          width={14}
          height={14}
          viewBox={"0 0 32 32"}
          version={"1.1"}
          style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}
        >
          <path stroke={"none"} fill={logisticsGreen} d={"M0,4h32v24h-32z"} />
        </svg>
        <span>{props.greenValue ?? "Logistics"}</span>
      </li>
      {props.showOrange &&
      <li style={{ display: "inline-block", marginRight: "10px" }}>
        <svg
          width={14}
          height={14}
          viewBox={"0 0 32 32"}
          version={"1.1"}
          style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}
        >
          <path stroke={"none"} fill={caTerminalOrange} d={"M0,4h32v24h-32z"} />
        </svg>
        <span>CA Terminal</span>
      </li>}
    </ul>
  );
}