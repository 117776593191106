import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  Employee,
  Region,
  SalesHistoryReportParameters
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  SalesHistoryReportParametersValidationSchema
} from "$State/ReportFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  searchContainer: string;
} = require("./SalesHistory.scss");

interface IOwnProps {
  criteria: SalesHistoryReportParameters;
  salesReps: Employee[];
  regions: Region[];
  onSubmit: (criteria: SalesHistoryReportParameters) => void;
}

interface IOwnState {
  criteria: SalesHistoryReportParameters;
  errors: ValidationError | null;
}

export class SalesHistorySearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    criteria: {},
    errors: null
  };

  componentDidMount() {
    this.setState({ criteria: this.props.criteria });
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.criteria !== prev.criteria) {
      this.setState({ criteria: this.props.criteria });
    }
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onRegionChange(event: SelectChangeEvent<number | string>) {
    const regionId = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        customerRegionId: regionId === 0 ? undefined : regionId
      }
    }));
  }

  @bind
  private _onSaleRepChange(event: SelectChangeEvent<number | string>) {
    const salesRepId = event.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        salesAgentId: salesRepId === 0 ? undefined : salesRepId
      }
    }));
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(SalesHistoryReportParametersValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(this.state.criteria);
  }

  render() {
    const {
      regions,
      salesReps
    } = this.props;

    const {
      criteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<SalesHistoryReportParameters>(errors);

    return (
      <SearchControlsContainer
        className={styles.searchContainer}
        onSubmit={this._onSearchClick}
      >
        <DateRangePicker
          startDate={criteria.startDate}
          startError={validationParser.validationMessage("startDate")}
          endDate={criteria.endDate}
          endError={validationParser.validationMessage("endDate")}
          onChange={this._onDateRangeChange}
          horizontal
        />
        <FormControl style={{ flex: "1 1", maxWidth: "230px" }}>
          <InputLabel>Region</InputLabel>
          <Select
            value={criteria.customerRegionId ?? ""}
            onChange={this._onRegionChange}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {regions.map((region, idx) =>
              <MenuItem value={region.id} key={idx}>
                {region.regionName}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1", maxWidth: "230px" }}>
          <InputLabel shrink>Sales Representative</InputLabel>
          <Select
            value={criteria.salesAgentId ?? ""}
            onChange={this._onSaleRepChange}
            displayEmpty
          >
            <MenuItem value={""}>
              All
            </MenuItem>
            {salesReps.map((e, idx) =>
              <MenuItem value={e.id} key={idx}>
                {`${e.firstName} ${e.lastName}`}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </SearchControlsContainer>
    );
  }
}