import {
  React
} from "$Imports/Imports";

import {
  SalesOpportunitiesView
} from "./SalesOpportunitiesView";

interface IOwnProps { }

export class SalesOpportunitiesViewPage extends React.Component<IOwnProps> {
  render() {
    return (
      <SalesOpportunitiesView />
    );
  }
}