import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker
} from "$Imports/CommonComponents";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  BookedSalesParametersVM
} from "$Generated/api";

import {
  BookedSalesReportFilterValidationSchema
} from "$State/ReportFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  Search
} from "$Imports/MaterialUIIcons";

interface IOwnProps {
  onChange: (startDate?: Date, endDate?: Date) => void;
  onClear: () => void;
  filtersReady: boolean;
}

interface IOwnState {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  errors: ValidationError | null;
  updateEnabled: boolean;
}

export class BookedSalesDateRangePicker extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    startDate: new Date(),
    endDate: new Date(),
    errors: null,
    updateEnabled: true
  };

  @bind
  private _onClear() {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      updateEnabled: true
    });

    this.props.onClear();
  }

  @bind
  private _onChange(startDate: Date | null, endDate: Date | null) {
    this.setState({
      startDate: startDate ? moment(startDate).startOf('day').toDate() : undefined,
      endDate: endDate ? moment(endDate).endOf('day').toDate() : undefined,
      updateEnabled: true
    });
  }

  @bind
  private async _onUpdate() {
    const {
      startDate,
      endDate
    } = this.state;

    const errors = await validateSchema(BookedSalesReportFilterValidationSchema, { startDate, endDate }) 

    this.setState({
      errors: errors,
      updateEnabled: false
    });

    if (!errors) {
      this.props.onChange(startDate, endDate);
    }
  }

  render() {
    const {
      filtersReady
    } = this.props;

    const {
      startDate,
      endDate,
      errors,
      updateEnabled
    } = this.state;

    const validationParser = new ValidationErrorParser<BookedSalesParametersVM>(errors);
    const startError = validationParser.validationMessage("startDate");
    const endError = validationParser.validationMessage("endDate");

    return (
      <>
        <div style={{
          border: "1px solid #d7d7d7",
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          margin: "0.25rem 0 0.75rem 0",
          padding: "0.25rem"
        }}>
          <DateRangePicker
            startDate={startDate}
            startError={startError}
            endDate={endDate}
            endError={endError}
            onChange={this._onChange}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            color="primary"
            onClick={this._onUpdate}
            disabled={!(updateEnabled && filtersReady)}
            className="iconAsButton"
          >
            <Search />
          </Button>
          <Button
            color="primary"
            onClick={this._onClear}
          >
            Reset
          </Button>
        </div>
      </>
    );
  }
}
