import {
  React
} from "$Imports/Imports";

import {
  IGraphData
} from "./SalesOpportunitiesView";

interface ISalesOpportunitiesTooltipProps {
  payload?: IGraphData | undefined;
  isActive?: boolean | undefined;
}

const styles: {
  tooltip: string;
} = require("./SalesOpportunities.scss");

export class SalesOpportunitiesTooltip extends React.PureComponent<ISalesOpportunitiesTooltipProps> {

  render() {
    const {
      payload,
      isActive
    } = this.props;

    return isActive ?
      (
        <div className={styles.tooltip}>
          <b>Logistics opportunities</b>: {payload?.KL ?? 0}
          <br/>
          <b>Transport opportunities</b>: {payload?.KT ?? 0}
        </div>
      )
      : null;
  }
}