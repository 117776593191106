import {
  React,
  numeral,
  _
} from "$Imports/Imports";

import {
  DeliveredFreightReportView
} from "$Generated/api";

import {
  CURRENCY_NO_DECIMAL_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  CompanyLegend
} from "$Imports/CommonComponents";

import {
  renderDeliveredFreightMetricCells
} from "./DeliveredFreightMetricCell";

import {
  GraphLabel
} from "$Components/common/Recharts/GraphLabel";

import {
  getContainerMinHeight
} from "$Utilities/barChartUtil";

interface IDeliveredFreightGraphProps {
  filterBy: string;
  showLegend?: boolean;
  reportData: DeliveredFreightReportView[];
  height: number;
}

export class DeliveredFreightGraph extends React.Component<IDeliveredFreightGraphProps> {

  render() {
    const {
      filterBy,
      showLegend,
      reportData,
      height
    } = this.props;

    // lodash orderBy returns a new array
    const graphData = _.orderBy(reportData, "revenue").reverse();
    const minHeight = getContainerMinHeight(graphData.length > 0);

    return (
      <ResponsiveContainer height={height} minHeight={minHeight} width="99%">      
        <BarChart
          data={graphData}
          layout="vertical"
        >
          <XAxis
            type="number"
            xAxisId={1}
          />
          <YAxis
            dataKey={filterBy}
            type="category"
            yAxisId={1}
            width={90}
          />
          <Bar
            dataKey="revenue"
            xAxisId={1}
            yAxisId={1}
          >
            {renderDeliveredFreightMetricCells(graphData)}
            <LabelList dataKey="revenue" content={(props)=>( 
                <GraphLabel
                  {...props}
                  formatValue={(value) =>(numeral(value).format(CURRENCY_NO_DECIMAL_FORMAT))}
                />
              )} 
            />
          </Bar>

          {showLegend && 
            <Legend content={<CompanyLegend blueValue="Transport" />} />}
          <CartesianGrid horizontal={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
