import { 
    CapacityReport, 
    CapacityReportApiFactory 
} from "$Generated/api";
import { 
    FreezerService, 
    IAjaxState, 
    managedAjaxUtil,
    _
} from "$Imports/Imports";

const InjectedPropName = "capacityReportService";

interface IFillFreightState {
    oppurtunities?: number;
    conversions?: number;
    conversionRate?: number;
}

interface ICapacityReportState {
    capacityReportResults: IAjaxState<CapacityReport>;
    fillFreightState: IFillFreightState;
}

class CapacityReportFreezerService extends FreezerService<ICapacityReportState, typeof InjectedPropName> {
    public constructor() {
        super({
            capacityReportResults: managedAjaxUtil.createInitialState(),
            fillFreightState: {
                oppurtunities: undefined,
                conversions: undefined,
                conversionRate: undefined,
            }
        }, InjectedPropName);
    }

    public async fetchReport(startDate: Date, endDate: Date, showZonesWithNoTrips: boolean) {
        await managedAjaxUtil.fetchResults({
            freezer: this.freezer,
            ajaxStateProperty: "capacityReportResults",
            params: {
                body: {
                    startDate,
                    endDate,
                    showZonesWithNoTrips
                }
            },
            onExecute: (apiOptions, params, options) => {
                const factory = CapacityReportApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return factory.apiV1ReportsCapacityPost(params);
              }
        })
    }

    public updateFillFreightState(newState: Partial<IFillFreightState>) {
        this.freezer.get().fillFreightState.set(newState);
    }

    public calculateFillFreight() {
        const {
            oppurtunities, 
            conversions
        } = this.freezer.get().fillFreightState;
        if (oppurtunities !== undefined && 
            conversions !== undefined &&
            oppurtunities >= 1 && 
            conversions >= 0) {
            this.updateFillFreightState({
                conversionRate: _.round(conversions / oppurtunities * 100, 2)
            });
        } else {
            this.updateFillFreightState({
                conversionRate: undefined
            });   
        }
    }
}

const CapacityReportService = new CapacityReportFreezerService();
type ICapacityReportServiceInjectedProps = ReturnType<CapacityReportFreezerService["getPropsForInjection"]>;

export {
    CapacityReportService,
    ICapacityReportServiceInjectedProps
};
