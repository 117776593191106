import {
  IDatapoint,
  ReportCard,
  TerminalSummary,
  EmptyMilesCard,
  FillFreightCard
} from "$Components/CapacityReport";

import {
  CardLinedHeader,
  DateRangePicker
} from "$Components/common";

import {
  CapacityReport,
  CapacityReportTerminal
} from "$Generated/api";

import { SearchControlsContainer } from "$Imports/CommonComponents";

import {
  Luxon,
  React,
  _
} from "$Imports/Imports";

import {
  Checkbox,
  FormControlLabel,
  LinearProgress
} from "$Imports/MaterialUIComponents";

import {
  CapacityReportService,
  ICapacityReportServiceInjectedProps
} from "$State/CapacityReportFreezerService";

const styles: {
  mainContainer: string;
  reportFilterContainer: string;
  sectionRow: string;
  reportFilterSummariesRow: string;
  mainCard: string;
  checkbox: string;
} = require("./CapacityReportPage.scss");

interface ICapacityReportPageState {
  startDate: Date | null;
  startError?: string;
  endDate: Date | null;
  endError?: string;
  showZonesWithNoTrips: boolean;
}

interface ICapacityReportBaseProps {

}

type ICapacityReportProps = ICapacityReportBaseProps & ICapacityReportServiceInjectedProps;

export class _CapacityReportPage extends React.Component<ICapacityReportProps, ICapacityReportPageState> {
  constructor(props: ICapacityReportProps) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      showZonesWithNoTrips: false,
    }
  }

  private onDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
    const startErrors: string[] = [];
    const endErrors: string[] = [];

    if (startDate !== null) {
      const start = Luxon.DateTime.fromISO(startDate.toISOString()).startOf("day");

      if (!start.isValid) {
        startErrors.push("Start Date is invalid");
      }

      const now = Luxon.DateTime.now().endOf("day");
      if (start > now) {
        startErrors.push("Start Date is in the future");
      }
    }
    if (endDate !== null) {
      const end = Luxon.DateTime.fromISO(endDate.toISOString()).endOf("day");

      if (!end.isValid) {
        endErrors.push("End Date is invalid");
      }

      const now = Luxon.DateTime.now().endOf("day");
      if (end > now) {
        endErrors.push("End Date is in the future");
      }
    }
    if (startDate !== null && endDate !== null) {
      const start = Luxon.DateTime.fromISO(startDate.toISOString()).startOf("day");
      const end = Luxon.DateTime.fromISO(endDate.toISOString()).endOf("day");
      let duration = end.diff(start, ["years", "months", "days", "hours"]);

      if (start > end) {
        startErrors.push("Start Date is after End Date");
        endErrors.push("End Date is before Start Date");
        duration = start.diff(end);
      }

      const maxDuration = Luxon.Duration.fromObject({ years: 1 });
      if (duration > maxDuration) {
        startErrors.push("Start Date and End Date are more than a year apart");
        endErrors.push("End Date and Start Date are more than a year apart");
      }
    }

    let startError: string | undefined = undefined;
    if (startErrors.length > 0) {
      startError = startErrors.join(", ");
    }
    let endError: string | undefined = undefined;
    if (endErrors.length > 0) {
      endError = endErrors.join(", ");
    }


    const newState = {
      ...this.state,
      startDate: startDate,
      startError: startError,
      endDate: endDate,
      endError: endError,
    };

    this.setState(newState);
  }

  private onCheckboxChange = (checked: boolean) => {
    const newState = {
      ...this.state,
      showZonesWithNoTrips: checked,
    };

    this.setState(newState);
  }

  private onButtonClicked = () => {
    const {
      startDate,
      endDate,
      showZonesWithNoTrips,
    } = this.state;
    if (startDate !== null && endDate !== null) {
      this.props.capacityReportService.fetchReport(
        Luxon.DateTime.fromISO(startDate.toISOString()).startOf("day").toJSDate(),
        Luxon.DateTime.fromISO(endDate.toISOString()).endOf("day").toJSDate(),
        showZonesWithNoTrips
      );
    }
  }

  public render(): JSX.Element {
    const {
      capacityReportResults
    } = this.props.capacityReportService.getState();
    const reportData: CapacityReport | undefined = capacityReportResults.data ?? undefined;

    let jvlInbound: CapacityReportTerminal | undefined = undefined;
    let jvlOutbound: CapacityReportTerminal | undefined = undefined;
    let sfsInbound: CapacityReportTerminal | undefined = undefined;
    let sfsOutbound: CapacityReportTerminal | undefined = undefined;

    const jvlTerminalSummaryData: IDatapoint[] = [];
    const sfsTerminalSummaryData: IDatapoint[] = [];

    if (reportData !== undefined) {
      jvlInbound = _.find(reportData.terminals, { "terminal": "JANESVILLE", "direction": "Inbound" });
      jvlOutbound = _.find(reportData.terminals, { "terminal": "JANESVILLE", "direction": "Outbound" });
      sfsInbound = _.find(reportData.terminals, { "terminal": "ORANGE", "direction": "Inbound" });
      sfsOutbound = _.find(reportData.terminals, { "terminal": "ORANGE", "direction": "Outbound" });

      if (jvlOutbound) {
        jvlTerminalSummaryData.push({
          label: "Outbound RPM",
          value: _.meanBy(jvlOutbound.rows, "rpm")
        });
      }
      if (jvlInbound) {
        jvlTerminalSummaryData.push({
          label: "Inbound RPM",
          value: _.meanBy(jvlInbound.rows, "rpm")
        });
      }
      jvlTerminalSummaryData.push({
        label: "Combined RPM",
        value: _.meanBy(jvlTerminalSummaryData, "value")
      })

      if (sfsOutbound) {
        sfsTerminalSummaryData.push({
          label: "Outbound RPM",
          value: _.meanBy(sfsOutbound.rows, "rpm")
        });
      }
      if (sfsInbound) {
        sfsTerminalSummaryData.push({
          label: "Inbound RPM",
          value: _.meanBy(sfsInbound.rows, "rpm")
        });
      }
      sfsTerminalSummaryData.push({
        label: "Combined RPM",
        value: _.meanBy(sfsTerminalSummaryData, "value")
      })
    }

    const {
      startDate,
      startError,
      endDate,
      endError,
    }: ICapacityReportPageState = this.state;

    return (
      <>
        <div className={styles.mainContainer}>
          <CardLinedHeader titleText="Capacity Report" className={styles.mainCard}>
            <SearchControlsContainer
              className={styles.reportFilterContainer}
              onSubmit={this.onButtonClicked}
              disabled={startDate === null || startError !== undefined || endDate === null || endError !== undefined || capacityReportResults.isFetching}
            >
              <DateRangePicker
                onChange={this.onDateRangeChange}
                disableFuture
                startDate={startDate}
                startError={startError}
                endDate={endDate}
                endError={endError}
                horizontal
              />

              <FormControlLabel
                className={styles.checkbox}
                control={
                  <Checkbox
                    onChange={
                      (e) => this.onCheckboxChange(e.target.checked)
                    } />
                }
                label="Show zones with no trips?"
              />
            </SearchControlsContainer>

            {capacityReportResults.isFetching && <LinearProgress />}

            {capacityReportResults.hasFetched && <>
              <div className={styles.reportFilterSummariesRow}>
                <TerminalSummary
                  title="JVL Terminal Summary"
                  datapoints={jvlTerminalSummaryData}
                  costPerMile={reportData?.jvlCostPerMile}
                />

                <TerminalSummary
                  title="SFS Terminal Summary"
                  datapoints={sfsTerminalSummaryData}
                  costPerMile={reportData?.sfsCostPerMile}
                />
              </div>
            </>}
          </CardLinedHeader>

          {capacityReportResults.hasFetched && <>
            <ReportCard
              titleText="JVL Terminal Outbound Capacity and RPM"
              flavor="outbound"
              data={jvlOutbound}
              costPerMile={reportData?.jvlCostPerMile}
            />

            <ReportCard
              titleText="JVL Terminal Inbound Capacity and RPM"
              flavor="inbound"
              data={jvlInbound}
              costPerMile={reportData?.jvlCostPerMile}
            />

            <ReportCard
              titleText="SFS Terminal Outbound Capacity and RPM"
              flavor="outbound"
              data={sfsOutbound}
              costPerMile={reportData?.sfsCostPerMile}
            />

            <ReportCard
              titleText="SFS Terminal Inbound Capacity and RPM"
              flavor="inbound"
              data={sfsInbound}
              costPerMile={reportData?.sfsCostPerMile}
            />

            <div className={styles.sectionRow}>
              <EmptyMilesCard data={reportData?.emptyLegs} />

              <FillFreightCard />
            </div>
          </>}
        </div>
      </>
    );
  }
}

export const CapacityReportPage = CapacityReportService.inject(
  _CapacityReportPage
);