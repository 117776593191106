import {
  FreezerService,
  bind,
  managedAjaxUtil,
  IAjaxState,
  _
} from "$Imports/Imports";

import { 
  ApplicationSetting,
  ApplicationSettingsApiFactory 
} from "$Generated/api";


import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";
import { ErrorService } from "./ErrorFreezerService";

interface IApplicationSettingsServiceState {
  applicationSettingsFetchResults: IAjaxState<Array<ApplicationSetting>>,
  updateApplicationSettingResults: IAjaxState<ApplicationSetting>,
  isNumberSettingModalOpen: boolean,
  editNumberSetting: ApplicationSetting | undefined
}

const InjectedPropName = "ApplicationSettingsService";

const initialState = {
  applicationSettingsFetchResults: managedAjaxUtil.createInitialState(),
  updateApplicationSettingResults: managedAjaxUtil.createInitialState(),
  isNumberSettingModalOpen: false
} as IApplicationSettingsServiceState;

class ApplicationSettingsFreezerService extends FreezerService<IApplicationSettingsServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);
    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public openApplicationSettingEditModal(applicationSetting: ApplicationSetting) {
    this.freezer.get().set({
      isNumberSettingModalOpen: true,
      editNumberSetting: _.cloneDeep(applicationSetting)
    })
  }

  public updateNumberSetting(applicationSetting: Partial<ApplicationSetting>) {
    this.freezer.get().editNumberSetting?.set(applicationSetting);
  }

  public async saveNumberSetting() {
    const updatedSetting = this.freezer.get().editNumberSetting?.toJS();

    await this.updateApplicationSetting(updatedSetting);

    this.freezer.get().set({
      isNumberSettingModalOpen: false,
      editNumberSetting: undefined
    });
  }

  public async fetchCapacityReportSettings(forceUpdate: boolean = false) {

    const applicationSettingsFetchResults = this.freezer.get().applicationSettingsFetchResults;

    if (applicationSettingsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "applicationSettingsFetchResults",
      params: {

      },
      onExecute: (apiOptions, params, options) => {
        const factory = ApplicationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ApplicationSettingsGetCapacityReportSettingsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage('Unable to fetch application settings');
      }
    });
  }

  public async updateApplicationSetting(applicationSetting?: ApplicationSetting) {

    if (!applicationSetting) {
      return; 
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateApplicationSettingResults",
      params: {
        body: applicationSetting
      },
      onExecute: (apiOptions, params, options) => {
        const factory = ApplicationSettingsApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1ApplicationSettingsUpsertAppSettingPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage('Unable to update application setting');
      }
    });
  }
}

export const ApplicationSettingsService = new ApplicationSettingsFreezerService();
export type IApplicationSettingsServiceInjectedProps = ReturnType<ApplicationSettingsFreezerService["getPropsForInjection"]>;