import {
  React,
  bind,
  _,
  moment,
  numeral
} from "$Imports/Imports";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil"

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

import {
  CardActions,
  GridColDef,
  DataGridPro,
  GridRenderCellParams,
  Stack,
  GridValueFormatterParams,
  GridApiPro,
  Button
} from "$Imports/MaterialUIComponents";

import {
  Download
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  CardLinedHeader,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import { DeliveredFreightSearchCriteria } from "$Generated/api";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  ICompanyServiceInjectedProps,
  CompanyService
} from "$State/CompanyFreezerService";

import {
  DeliveredFreightSearchForm
} from "./DeliveredFreightSearchForm";

import {
  PagingControls
} from "$Shared/components/PagingControls";

import {
  DeliveredFreightGraph
} from "./DeliveredFreightGraph";
import { getBarHeight } from "$Utilities/barChartUtil";

const styles: {
  mainContainer: string,
  graphCard: string
} = require("./DeliveredFreight.scss");

interface IDeliveredFreightViewState { }

interface IDeliveredFreightViewBaseProps { }

type IDeliveredFreightViewProps = IDeliveredFreightViewBaseProps
  & IReportServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICompanyServiceInjectedProps;

const companyKeyHeight = 21;

class _DeliveredFreightView extends React.Component<IDeliveredFreightViewProps, IDeliveredFreightViewState> {

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Freight Bill #",
      field: "freightBillNumber",
      flex: 1,
    },
    {
      headerName: "Delivery Date",
      field: "deliveryDate",
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) => !!params.value ? moment(params.value).local().format(DATE_ONLY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => !!params.value &&
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />,
        flex: 2,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "";
      },
      flex: 1,
    },
    {
      headerName: "Sales Rep",
      field: "salesRep",
      flex: 4,
    },
    {
      headerName: "Company",
      field: "company",
      flex: 2,
    }
  ];

  componentDidMount() {
    this.props.companyService.fetchData();
  }

  @bind
  private _onSearchClick(criteria: DeliveredFreightSearchCriteria) {
    this.props.reportService.fetchDeliveredFreightReport(criteria);
  }

  @bind
  private _onPageChanged(pageNum: number) {    
    this.props.reportService.searchPage(pageNum);
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      deliveredFreightSearchCriteria,
      deliveredFreightReportFetchResults,
      deliveredFreightSalesRepData,
      deliveredFreightCompanyData
    } = this.props.reportService.getState();
    const reportData = deliveredFreightReportFetchResults.data?.reportData ?? [];

    let currentPage = 0;
    if (deliveredFreightReportFetchResults.hasFetched && deliveredFreightReportFetchResults.data?.totalRecords) {
      let currentStartIndex = (deliveredFreightSearchCriteria?.startIndex ?? 0);
      let pageSize = deliveredFreightSearchCriteria?.pageSize ?? 0;

      currentPage = currentStartIndex / pageSize + 1;
    }

    const {
      companyFetchResults
    } = CompanyService.getState();
    const companies = companyFetchResults.data ?? [];

    let rowIdCounter = 0;

    const maxRowsCount = deliveredFreightSalesRepData && deliveredFreightCompanyData 
      ? deliveredFreightSalesRepData?.length > deliveredFreightCompanyData?.length 
        ? deliveredFreightSalesRepData.length : deliveredFreightCompanyData.length 
      : 0;
    const graphHeight = getBarHeight(maxRowsCount);

    return (
      <div className={styles.mainContainer}>
        <CardLinedHeader
          titleText="Delivered Freight"
          style={{ flex: "1" }}
          titleComponents={
            <Button
              className="cardHeaderButton"
              onClick={() => OnExportCsvClick('Delivered_Freight_Report_', this._gridApiRef)}
              title="Download"
              disabled={reportData.length === 0}
            >
              <Download /><b> Download</b>
            </Button>
          }
        >
          <CardActions>
            <DeliveredFreightSearchForm
              criteria={deliveredFreightSearchCriteria}
              companies={companies}
              onSubmit={this._onSearchClick}
            />
          </CardActions>
          <AjaxActionIndicator state={[deliveredFreightReportFetchResults]} />
          <DataGridPro
            columns={this.gridColumns}
            rows={reportData}
            density="compact"
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "deliveryDate", sort: "asc" }]
              }
            }}
            sortingOrder={["asc", "desc"]}
            components={{
              NoRowsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {!deliveredFreightReportFetchResults.hasFetched
                    ? "Enter search criteria to view delivered freight bills"
                    : "No delivered freight data for selected criteria or all delivered freight has been filtered out"
                  }
                </Stack>,
              NoResultsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No delivered freight data for selected criteria or all delivered freight has been filtered out
                </Stack>
            }}
            apiRef={this._gridApiRef}
            disableSelectionOnClick
            hideFooter
            autoHeight
          />
          <PagingControls
            rowCount={deliveredFreightReportFetchResults.data?.totalRecords ?? 0}
            pageSize={deliveredFreightSearchCriteria?.pageSize ?? 0}
            currentPage={currentPage}
            setPage={this._onPageChanged}
          />
        </CardLinedHeader>
        <div style={{ display: "flex" }}>
            <CardLinedHeader
              titleText={"By Sales Rep"}
              className={styles.graphCard}
            >
              <DeliveredFreightGraph
                filterBy={"salesRep"}
                showLegend
                height={graphHeight}
                reportData={deliveredFreightSalesRepData} />
            </CardLinedHeader>
            <CardLinedHeader
              titleText={"By Company"}
              className={styles.graphCard}
            >
              <DeliveredFreightGraph
                filterBy={"company"}
                height={graphHeight - companyKeyHeight}
                reportData={deliveredFreightCompanyData} />
            </CardLinedHeader>
          </div>
      </div>
    );
  }
}

export const DeliveredFreightView = ReportService.inject(
  EmployeeService.inject(
    CompanyService.inject(
      _DeliveredFreightView
    )
  )
);
