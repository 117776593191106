import {
  React,
  _
} from "$Imports/Imports";

import {
  PieChart,
  Pie,
  Cell,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  Opportunity
} from "$Generated/api";

interface IGraphStatusProps {
  graphData: Opportunity[]
}

export class GraphStatus extends React.Component<IGraphStatusProps> {
  render() {
    const { graphData } = this.props;

    let ingredients: {status: string, count: number}[] = [];
    _.forIn(_.countBy(graphData, "status"), (value, key) => {
      ingredients.push({
        status: key as string,
        count: value as number
      });
    });

    return (
      <ResponsiveContainer height={500} width="99%">
        <PieChart>
          <Pie
            data={ingredients}
            nameKey={"status"}
            dataKey={"count"}
          >
            <Cell
              name={"Discovery"}
              key={"Discovery"}
              fill={"#67A9CF"}
            />
            <Cell
              name={"Interested"}
              key={"Interested"}
              fill={"#1C9099"}
            />
            <Cell
              name={"Converted"}
              key={"Converted"}
              fill={"#016C59"}
            />
            <Cell
              name={"Abandoned"}
              key={"Abandoned"}
              fill={"#BDC9E1"}
            />
            <LabelList
              dataKey="count"
              position="inside"
            />
          </Pie>
          <Legend
            formatter={(value, entry) => {
              return <span style={{ color: "black" }}>{value}</span>
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}