import {
  React
} from "$Imports/Imports";

import {
  QuoteConversionView
} from "./QuoteConversionView";

interface IQuoteConversionViewPageProps { }

export class QuoteConversionViewPage extends React.Component<IQuoteConversionViewPageProps> {
  render() {
    return (
      <QuoteConversionView />
    )
  }
}