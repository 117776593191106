// extracted by mini-css-extract-plugin
var _1 = "SalesOpportunities__cell__RWn1J";
var _2 = "SalesOpportunities__filterContainer__Es5ih";
var _3 = "SalesOpportunities__filterSectionHeader__mSAvr";
var _4 = "SalesOpportunities__logo__CxOrS";
var _5 = "SalesOpportunities__mainContainer__w_Uwc";
var _6 = "SalesOpportunities__reportContainer__xO5hU";
var _7 = "SalesOpportunities__searchContainer__WWBlR";
var _8 = "SalesOpportunities__tooltip__UPWHo";
export { _1 as "cell", _2 as "filterContainer", _3 as "filterSectionHeader", _4 as "logo", _5 as "mainContainer", _6 as "reportContainer", _7 as "searchContainer", _8 as "tooltip" }
