import {
  React
} from "$Imports/Imports";

import {
  Card,
  Grid,
  GridSize
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  title: string;
  value: string;
  xs?: boolean | GridSize;
}

const styles: {
  container: string;
  title: string;
  value: string;
} = require("./ReportMetricCell.scss");

class _ReportMetricCell extends React.PureComponent<IOwnProps> {
  render() {
    const {
      title,
      value,
      xs
    } = this.props

    return (
      <Grid item xs={xs}>
        <Card className={styles.container}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.value}>
            {value}
          </div>
        </Card>
      </Grid>
    );
  }
}

export const ReportMetricCell = _ReportMetricCell;