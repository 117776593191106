import {
  React,
  _
} from "$Imports/Imports";

import {
  Cell
} from "$Imports/Recharts";

import {
  BookedSalesReportView
} from "$Generated/api";

import {
  caTerminalOrange,
  logisticsGreen,
  transportBlue
} from "$Themes/index";

export const renderSalesMetricCells = (data?: BookedSalesReportView[]): JSX.Element[] => {

  const bookedSalesData = data|| [];

  return _.map(bookedSalesData, (salesData: BookedSalesReportView, bidx: number) => {
    let fillColor = transportBlue;

    if (salesData.companyName === "KAISER LOGISTICS INC") {
      fillColor = logisticsGreen;
    }
    else if (salesData.companyName === "KAISER TRANSPORT INC" && salesData.terminalName === "ORANGE") {
      fillColor = caTerminalOrange;
    }
    return <Cell key={bidx} fill={fillColor} />;
  });
}