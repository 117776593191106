import {
    React,
    bind
  } from "$Imports/Imports";
  
  import {
    ApplicationSetting
  } from "$Generated/api";
  
  import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    FormControlLabel,
    TextField
  } from "$Imports/MaterialUIComponents";

  import {
    AdvanceTextField
  } from "$Imports/CommonComponents";

  interface INumberSettingEditModalProps {
    isModalOpen?: boolean;
    title: string;
    value: string | undefined;
    onChangeSetting: (applicationSetting: Partial<ApplicationSetting>) => void;
    onSaveSetting: () => void;
  }

  interface INumberSettingEditModalState {
    inputErrors: false;
  }

  export class NumberSettingEditModal extends React.PureComponent<INumberSettingEditModalProps> {

    state: INumberSettingEditModalState = {
      inputErrors: false
    }

    @bind
    private _onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      const regexp = /^\d*\.?\d+$/;
      var input = e.target.value;
      if (input === undefined || regexp.test(input)) {
        this.setState({ inputErrors: false});
        this.props.onChangeSetting({ settingsValue: e.target.value === "" ? undefined : e.target.value });
      } else {
        this.setState({ inputErrors: true});
      }
    }

    @bind
    private _onSave() {
      this.props.onSaveSetting();
    }

    render() {
        const {
          isModalOpen,
          title,
          value
        } = this.props;
    
        return (
          <Dialog open={isModalOpen ?? false}>
          <DialogTitle>
            {title}
          </DialogTitle>
          <DialogContent>
            <AdvanceTextField 
              label={title}
              name={"numberInput"}
              value={value}
              onChange={this._onChange}
              type="number"
              error={this.state.inputErrors}
              helperText={this.state.inputErrors ? "Input must be a positive number" : ""}
              InputProps={{
                  inputProps: { min: 0}
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this._onSave}
              disabled={this.state.inputErrors}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
        )
      }
  }