// Get the favicon for the application
const getFavicon = (): Element => {
    const favicon = document.querySelector("link[rel='shortcut icon']");

    if (null === favicon) {
        return createFavicon();
    }

    return favicon;
}
  
// Create a favicon for the application
const createFavicon = (): Element => {
    const header = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");

    link.setAttribute("rel", "shortcut icon");
    header.appendChild(link);

    return link;
}

// Set the favicon for the application
export const setFaviconUrl = (iconUrl: string): void => {
    const link = getFavicon();
    link.setAttribute("href", iconUrl);
}