import {
  React,
  bind,
  withAuth,
  AuthContextProps,
  _
} from "$Imports/Imports";

import {
  AppBar,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Menu,
  MenuItem,
  Avatar
} from "$Imports/MaterialUIComponents";

import {
  Apps,
  Equalizer
} from "$Imports/MaterialUIIcons";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles"

import {
  ThemeConsumer,
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService,
} from "$State/NavigationFreezerService";

import {
  IAboutServiceInjectedProps,
  AboutService
} from "$State/AboutFreezerService";

import {
  mainNavigation
} from "$Utilities/navigation";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  AboutModal,
  UserAccessControl
} from "$Imports/CommonComponents";

import { 
  openTabForUrl
} from "$Utilities/windowUtil";

interface IApplicationBarBaseProps {
}

interface IApplicationBarState {
  anchorEl: Element | null;
  menuOpen: boolean;
  appsAnchorEl: Element | null;
  appsMenuOpen: boolean;
}
type IApplicationBarProps = IApplicationBarBaseProps
  & INavigationServiceInjectedProps
  & IAboutServiceInjectedProps
  & AuthContextProps;

const styles: {
  applicationBarContainer: string;
  applicationBarItems: string;
  applicationBarTitle: string;
  logo: string;
  applicationBarTab: string;
  applicationBarTabTabs: string;
  usernameDisplay: string;
  appsIcon: string;
  appsMenu: string;
  appsMenuItem: string;
} = require("./MainLayout.scss");

import * as salesPortalIcon from "../../images/sales-portal-favicon.ico";

class _ApplicationBar extends React.Component<IApplicationBarProps, IApplicationBarState> {

  state: IApplicationBarState = {
    anchorEl: null,
    menuOpen: false,
    appsAnchorEl: null,
    appsMenuOpen: false
  };

  async componentDidMount() {
    this.props.aboutService.fetchData();
  }


  @bind
  private _onChange(event: React.ChangeEvent<{}>, value: any) {
    this.props.navigationService.navigateTo(value);
  }

  @bind
  private async logout() {   
    await SharedSecurityContext.logout()
  }

  @bind
  private openAbout() {
    this.closeMenu();
    this.props.aboutService.openModal();
  }

  @bind
  private closeAbout() {
    this.props.aboutService.closeModal();
  }

  @bind
  private openMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: true
    });
  }

  @bind
  private closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  @bind
  private openAppsMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      appsAnchorEl: event.currentTarget,
      appsMenuOpen: true
    });
  }

  @bind
  private closeAppsMenu() {
    this.setState({
      appsMenuOpen: false
    });
  }

  @bind
  private navigateSalesPortal() {
    const aboutData = this.props.aboutService.getState().aboutFetchResults?.data;
    openTabForUrl(aboutData?.salesPortalUrl ?? "");
    this.setState({
      appsMenuOpen: false
    });
  }


  @bind
  private navigateReports() {
    const aboutData = this.props.aboutService.getState().aboutFetchResults?.data;
    openTabForUrl(aboutData?.reportingUrl ?? "/reports");
    this.setState({
      appsMenuOpen: false
    });
  }

  render() {
    let navDetails = this.props.navigationService.getParentPath(window.location.pathname);
    let mainNav = _.filter(mainNavigation, (n) => !n.externalLink && n.isRoot);
    const user = SharedSecurityContext.getUser();
    this.props.navigationService.updateTitle();

    const {
      modalOpen: aboutModalOpen,
      aboutFetchResults
    } = this.props.aboutService.getState();

    return (          
      <ThemeConsumer>{(context) => {
        const appBarTheme = createTheme(context.themeConfig.themeOptions, context.themeConfig.appBarNavigationMenu);
        return (
          <AppBar
            position="relative"
          >
            <Toolbar>
              <div style={{"margin":"0 10px", "fontSize":"1.25rem", "fontWeight":"bold"}}>Kaiser Reports</div>
              <ThemeProvider
                    theme={appBarTheme}
                  >
                <Tabs
                  value={navDetails === null ? '/reports' : navDetails.url}
                  onChange={this._onChange}
                  className={styles.applicationBarTab}
                >
                  {_.map(mainNav, (n, nIdx) =>{ 
                      return (
                      <Tab
                        className={styles.applicationBarTabTabs}
                        key={nIdx}
                        value={n.url}
                        label={n.rootUrlLabel}
                      />
                    )})
                  }
                </Tabs>
              </ThemeProvider>
              <div>
                    <IconButton
                      aria-label="quick links to Kaiser web applications"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={this.openAppsMenu}
                      color="inherit"
                    >
                      <Apps className={styles.appsIcon} />
                    </IconButton>
                    <Menu
                      anchorEl={this.state.appsAnchorEl}
                      open={this.state.appsMenuOpen}
                      onClose={this.closeAppsMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className={styles.appsMenu}>
                        <UserAccessControl roles={["administrator", "manager", "salesrep", "carrierrep"]}>
                          <MenuItem className={styles.appsMenuItem} onClick={this.navigateSalesPortal}>
                            <IconButton><img src={salesPortalIcon} className={styles.appsIcon} /></IconButton>
                            <span>Sales Portal</span>
                          </MenuItem>
                        </UserAccessControl>
                        <UserAccessControl roles={["administrator", "manager", "salesrep", "reporter"]}>
                          <MenuItem className={styles.appsMenuItem} onClick={this.navigateReports}>
                            <IconButton><Equalizer className={styles.appsIcon} /></IconButton>
                            <span>Reports</span>
                          </MenuItem>
                        </UserAccessControl>
                      </div>
                    </Menu>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.openMenu}
                  color="inherit"
                >
                  <Avatar>{`${user?.profile?.given_name?.charAt(0)}${user?.profile?.family_name?.charAt(0)}`}</Avatar>
                  <span className={styles.usernameDisplay}>{user?.profile?.name}</span>
                </IconButton>
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={this.state.menuOpen}
                  onClose={this.closeMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={this.closeMenu}>Profile</MenuItem>
                  <MenuItem onClick={this.openAbout}>About</MenuItem>
                  <MenuItem onClick={this.logout}>Logout</MenuItem>
                </Menu>
              </div>
            </Toolbar>
            <AboutModal
              isOpen={aboutModalOpen}
              aboutVM={aboutFetchResults.data}
              onClose={this.closeAbout}
            />
          </AppBar>
        );
      }}
      </ThemeConsumer>
    );
  }
}

export const ApplicationBar = withAuth(
  NavigationService.inject(
    AboutService.inject(
      _ApplicationBar
    )
  )
);

