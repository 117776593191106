import { 
    CardLinedHeader 
} from "$Components/common";
import { 
    CapacityReportEmptyLeg 
} from "$Generated/api";
import { 
    React, 
    _ 
} from "$Imports/Imports";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow, 
    TableSortLabel 
} from "$Imports/MaterialUIComponents";

const styles: {
    bordered: string;
    cellContent: string;
    sectionRow: string;
    summaryContent: string;
    summaryValue: string;
    sectionHeader: string;
} = require("./EmptyMilesCard.scss");

interface IEmptyMilesCardProps {
    data?: Array<CapacityReportEmptyLeg>;
}

interface IColumnDef {
    id: string;
    label: string;
    format: (value: string | number | Date | undefined) => JSX.Element;
}

type DataKey = keyof CapacityReportEmptyLeg;

type Order = "asc" | "desc" | undefined;

interface IEmptyMilesCardState {
    orderBy: string | null;
    order: Order;
}

export const EmptyMilesCard: React.FunctionComponent<IEmptyMilesCardProps> = (props: IEmptyMilesCardProps) => {
    const defaultFormat = (value: string | number | Date | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } 
        return <>{value.toLocaleString()}</>;
    }

    const idNumberFormat = (value: string | number | Date | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } else if (typeof value === "number") {
            return <>{value}</>;
        }
        return <>{value.toLocaleString()}</>;
    }

    const dateFormat = (value: string | number | Date | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        }
        var date = new Date(value);
        return <>{date.toLocaleDateString()}</>;
    }

    const columns: IColumnDef[] = [
        { 
            id: "tripNumber", 
            label: "Trip #",
            format: idNumberFormat,
        },
        { 
            id: "tripDate", 
            label: "Date",
            format: dateFormat,
        },
        { 
            id: "legSequenceNumber", 
            label: "Leg #",
            format: idNumberFormat,
        },
        { 
            id: "legOriginZoneDescription", 
            label: "Leg Origin",
            format: defaultFormat,
        },
        { 
            id: "legDestinationZoneDescription", 
            label: "Leg Destination",
            format: defaultFormat,
        },
        { 
            id: "legMiles", 
            label: "Empty Miles",
            format: defaultFormat,
        },
        { 
            id: "driverName", 
            label: "Driver",
            format: defaultFormat,
        },
    ];

    const dataRows: Array<CapacityReportEmptyLeg> = props.data ?? [];
    
    const [state, setState] = React.useState<IEmptyMilesCardState>({
        orderBy: null,
        order: undefined,
    });
    
    const updateSort = (columnId: string) => {
        const curState = state as IEmptyMilesCardState;
        let newState = { ...curState };
        if (curState.orderBy === columnId) {
            if (curState.order === "asc") {
                newState.order = "desc";
            } else {
                newState.order = "asc";
            }
        } else {
            newState = {
                orderBy: columnId,
                order: "asc",
            }
        }
        setState(newState);
    }

    const sortedData: Array<CapacityReportEmptyLeg> = React.useMemo(() => {
        if (state.orderBy === null || state.order === undefined) {
            return _.orderBy(dataRows, ["tripDate", "tripNumber"], ["asc", "asc"]);
        } else {
            return _.orderBy(dataRows, [state.orderBy], [state.order]);
        }
    }, [dataRows, state.orderBy, state.order])

    const emptyMiles: number = React.useMemo(() => { 
        return _.sumBy(dataRows, "legMiles"); 
    }, [dataRows]);

    return (
        <>
            <CardLinedHeader titleText="Empty Miles">
                <div className={styles.sectionRow}>
                    <CardLinedHeader>
                        <div className={styles.summaryContent}>
                            <div>Empty Miles</div>
                            <span className={styles.summaryValue}>
                                {emptyMiles.toLocaleString()}
                            </span>
                        </div>
                    </CardLinedHeader>
                    <CardLinedHeader>
                        <div className={styles.summaryContent}>
                            <div>Trips with 250+ Empty Miles</div>
                            <span className={styles.summaryValue}>
                                {dataRows.length.toLocaleString()}
                            </span>
                        </div>
                    </CardLinedHeader>
                </div>

                <div className={styles.sectionHeader}>
                    Details for Trips with 250+ Empty Miles
                </div>
                <Table className={styles.bordered}>
                    <TableHead className={styles.bordered}>
                        <TableRow>
                            {columns.map((column: IColumnDef) => 
                                <TableCell key={column.id}>
                                    <TableSortLabel
                                        active={state.orderBy === column.id}
                                        direction={state.orderBy === column.id ? state.order : "asc"}
                                        onClick={(e) => updateSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row: CapacityReportEmptyLeg) => 
                            <TableRow key={`${row.tripNumber}-${row.legSequenceNumber}`}>
                                {columns.map((column: IColumnDef) => 
                                    <TableCell key={`${row.tripNumber}-${row.legSequenceNumber}-${column.id}`}>
                                        {column.format(row[column.id as DataKey])}
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardLinedHeader>
        </>
    );
}