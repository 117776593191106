import {
  React,
  _
} from "$Imports/Imports";

import {
  Tooltip
} from "$Imports/MaterialUIComponents";

const styles: {
  truncateCell: string;
} = require("./TextCellTruncated.scss");

interface ITruncatedTextDisplayProps {
  // passing a large complicated JSX.Element to this is untested behavior
  text: string | JSX.Element;
  // override for the tooltip (for instance, the cell contents are a link but you don't want a link in the hover text)
  tooltip?: string | JSX.Element;
}

export class TextCellTruncated extends React.PureComponent<ITruncatedTextDisplayProps> {
  render() {
    const {
      text,
      tooltip
    } = this.props;

    return (
      <Tooltip title={tooltip ?? text} placement="left">
        <div className={styles.truncateCell}>{text}</div>
      </Tooltip>
    );
  }
}