import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
} from "$Imports/Imports";

import {
  Region,
  RegionApiFactory
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

interface IRegionState {
  regionFetchResults: IAjaxState<Region[]>;
}

const InjectedPropName = "regionService";

const initialState = {
  regionFetchResults: managedAjaxUtil.createInitialState(),
} as IRegionState;

class RegionFreezerService extends FreezerService<IRegionState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchStates(forceUpdate: boolean = false) {
    const { regionFetchResults: stateFetchResults } = this.freezer.get();

    if (stateFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "regionFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = RegionApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1RegionGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch regions.");
      }
    });
  }
}

export const StateService = new RegionFreezerService();
export type IStateServiceInjectedProps = ReturnType<RegionFreezerService["getPropsForInjection"]>;
