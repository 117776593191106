import {
  React
} from "$Imports/Imports";

import {
  CircularProgress
} from "$Imports/MaterialUIComponents";


const styles: {
  progressDiv: string
} = require("./ProgressPage.scss");

interface IProgressPageBaseProps {

}

export class ProgressPage extends React.PureComponent<IProgressPageBaseProps> {

  render() {
    return (
      <div className={styles.progressDiv}>
        <h2>{`Please wait...`}</h2>
        <div style={{textAlign:"center"}}>
          <CircularProgress size={50} />
        </div>
      </div>
    )
  }
}