import {
  React,
  bind,
  _,
  numeral
} from "$Imports/Imports";

import {
  CardActions,
  GridColDef,
  DataGridPro,
  Stack,
  GridRenderCellParams,
  GridRowParams,
  GridPinnedRowsProp,
  Button,
  GridValueFormatterParams,
  GridApiPro
} from "$Imports/MaterialUIComponents";

import {
  Download
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  ConversionSearchCriteria,
  QuoteConversionReportRow
} from "$Generated/api";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  ICompanyServiceInjectedProps,
  CompanyService
} from "$State/CompanyFreezerService";

import {
  QuoteConversionSearchForm
} from "./QuoteConversionSearchForm";

import {
  CURRENCY_FORMAT,
  PERCENTAGE_TWO_DECIMALS
} from "$Shared/utilities/formatUtil";

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

const styles: {
  mainContainer: string,
  headerRow: string,
  totalTable: string,
  totalRow: string,
  reportRow: string
} = require("./QuoteConversion.scss");

interface IQuoteConversionViewState { }

interface IQuoteConversionViewBaseProps { }

type IQuoteConversionViewProps = IQuoteConversionViewBaseProps
  & IReportServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICompanyServiceInjectedProps;

class _QuoteConversionView extends React.Component<IQuoteConversionViewProps, IQuoteConversionViewState> {

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Sales Representative",
      field: "salesRep",
      flex: 4,
    },
    {
      headerName: "Accepted Quotes",
      field: "acceptedQuotes",
      flex: 4,
    },
    {
      headerName: "Total Quotes",
      field: "totalQuotes",
      flex: 4,
    },
    {
      headerName: "Conversion Rate",
      field: "conversionRate",
      valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(PERCENTAGE_TWO_DECIMALS) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} formatString={PERCENTAGE_TWO_DECIMALS} /> : "",
      flex: 4,
    },
    {
      headerName: "Booked Amount",
      field: "bookedAmount",
      valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "",
      flex: 4,
    },
    {
      headerName: "Avg Amount per Converted FB",
      field: "avgAmountPerQuote",
      valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "",
      flex: 4,
    }
  ];

  componentDidMount() {
    this.props.companyService.fetchData();
  }

  @bind
  private _onSearchClick(criteria: ConversionSearchCriteria) {
    this.props.reportService.fetchQuoteConversionReport(criteria);
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      quoteConversionParameters,
      quoteConversionReportFetchResults
    } = this.props.reportService.getState();
    const reportData = quoteConversionReportFetchResults.data ?? [];

    const {
      companyFetchResults
    } = CompanyService.getState();
    const companies = companyFetchResults.data ?? [];

    let rowIdCounter = 0;

    const acceptedCount = _.sumBy(reportData, "acceptedQuotes");
    const totalCount = _.sumBy(reportData, "totalQuotes");
    const conversionRate = totalCount == 0 ? 0 : acceptedCount / totalCount;
    const bookedAmount = _.sumBy(reportData, "bookedAmount");
    const average = acceptedCount == 0 ? 0 : bookedAmount / acceptedCount;
    const pinnedRow: GridPinnedRowsProp = {
      bottom: reportData.length > 0 ? [{
        salesRep: "Totals",
        acceptedQuotes: acceptedCount ?? "",
        totalQuotes: totalCount ?? "",
        conversionRate: conversionRate ?? "",
        bookedAmount: bookedAmount ?? "",
        avgAmountPerQuote: average ?? ""
      }] : []
    };

    return (
      <div className={styles.mainContainer}>
        <CardLinedHeader
          titleText="Quote Conversion"
          style={{ flex: "1" }}
          titleComponents={
            <Button
              className="cardHeaderButton"
              onClick={() => OnExportCsvClick('Quote_Conversion_Report_', this._gridApiRef)}
              title="Download"
              disabled={reportData.length === 0}
            >
              <Download /><b> Download</b>
            </Button>
          }
        >
          <CardActions>
            <QuoteConversionSearchForm
              criteria={quoteConversionParameters}
              companies={companies}
              onSubmit={this._onSearchClick}
            />
          </CardActions>
          <AjaxActionIndicator state={[quoteConversionReportFetchResults]} />
          <DataGridPro
            columns={this.gridColumns}
            rows={reportData}
            pinnedRows={reportData.length > 0 ? pinnedRow : undefined}
            classes={{
              columnHeaders: styles.headerRow
            }}
            experimentalFeatures={{ rowPinning: true }}
            density="compact"
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
            components={{
              NoRowsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {!quoteConversionReportFetchResults.hasFetched
                    ? "Enter search criteria to view quote conversion metrics"
                    : "No quote conversion data for selected criteria"
                  }
                </Stack>,
              NoResultsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No quote conversion data for selected criteria
                </Stack>
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "conversionRate", sort: "asc" }]
              }
            }}
            sortingOrder={["asc", "desc"]}
            getRowClassName={(params: GridRowParams<QuoteConversionReportRow>) => {
              return params.row.salesRep === "Totals" ? styles.totalRow : "";
            }}
            apiRef={this._gridApiRef}
            hideFooter
            disableSelectionOnClick
            autoHeight
          />
        </CardLinedHeader>
      </div>
    );
  }
}

export const QuoteConversionView = ReportService.inject(
  EmployeeService.inject(
    CompanyService.inject(
      _QuoteConversionView
    )
  )
);