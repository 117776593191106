import {
  React,
  bind,
  _,
} from "$Imports/Imports";

import {
  Opportunity,
  OpportunitySearchCriteria,
  Customer,
  Company,
  EntityLink
} from "$Generated/api";

import {
  CardActions,
  GridColDef,
  DataGridPro,
  GridRenderCellParams,
  GridValueGetterParams,
  IconButton,
  GridValueFormatterParams,
  GridSortModel
} from "$Imports/MaterialUIComponents";

import {
  ContactPage
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  CompanyIcon,
  CustomerLink,
  TextCellTruncated,
  DisplayFormattedNumber,
  DisplayFormattedDatetime,
  AddCustomerContactInfoModal
} from "$Imports/CommonComponents";

import {
  ReportService,
  IReportServiceInjectedProps,
  ISalesOpportunitiesFilters
} from "$State/ReportFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  ICompanyServiceInjectedProps,
  CompanyService
} from "$State/CompanyFreezerService";

import {
  CURRENCY_NO_DECIMAL_FORMAT,
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  SalesOpportunitiesSearchForm
} from "./SalesOpportunitiesSearchForm";

import {
  SalesOpportunitiesFilters
} from "./SalesOpportunitiesFilters";

import {
  GraphStatus
} from "./GraphStatus";

import {
  GraphSalesRep
} from "./GraphSalesRep";

import {
  GraphLeadSource
} from "./GraphLeadSource";

const styles: {
  mainContainer: string,
  filterContainer: string,
  reportContainer: string
} = require("./SalesOpportunities.scss");

export interface IGraphData {
  id: number | undefined;
  name: string | undefined;
  KT: number;
  KL: number;
}

interface ISalesOpportunitiesViewState {
  isCustomerNoteModalOpen: boolean;
  noteCustomer?: Customer;
  // controlled sort because initial sort state does not change on re-render with a new report
  gridSortModel: GridSortModel;
}

interface ISalesOpportunitiesViewBaseProps { }

type ISalesOpportunitiesViewProps = ISalesOpportunitiesViewBaseProps
  & IReportServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICompanyServiceInjectedProps;

class _SalesOpportunitiesView extends React.Component<ISalesOpportunitiesViewProps, ISalesOpportunitiesViewState> {

  state: ISalesOpportunitiesViewState = {
    isCustomerNoteModalOpen: false,
    gridSortModel: []
  };

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "",
      field: "company",
      disableColumnMenu: true,
      sortable: false,
      width: 28,
      renderCell: (params: GridRenderCellParams<Company | undefined, Opportunity>) => {
        return <CompanyIcon companyKey={params.row.company?.companyKey ?? ""} />
      }
    },
    {
      headerName: "ID",
      field: "opportunityId",
      flex: 1,
      disableColumnMenu: true
    },
    {
      headerName: "Customer Name",
      field: "customer",
      flex: 4,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams<Customer | undefined>) => {
        return params.value;
      },
      renderCell: (params: GridRenderCellParams<Customer | undefined>) => {
        return (
          <CustomerLink
            customerId={params.value?.id}
            customerName={params.value?.customerName}
          />
        );
      }
    },
    {
      headerName: "",
      field: "customerContact",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      minWidth: 24,
      width: 24,
      renderCell: (params: GridRenderCellParams<undefined, Opportunity>) => {
        return (
          <IconButton onClick={() => this._openCustomerNoteModal(params.row.customer)}>
            <ContactPage />
          </IconButton>
        )
      }
    },
    {
      headerName: "Sales Rep",
      field: "createdBy",
      valueGetter: (params: GridValueGetterParams<EntityLink | undefined>) => params.value?.linkName,
      flex: 4,
      disableColumnMenu: true
    },
    {
      headerName: "Opportunity Description",
      field: "description",
      renderCell: (params: GridRenderCellParams<string | undefined, Opportunity>) => {
        let noteText = params.value ?? "";
        return (
          <TextCellTruncated text={noteText} />
        );
      },
      disableColumnMenu: true,
      flex: 4,
      hideSortIcons: true,
      sortable: false
    },
    {
      headerName: "Status",
      field: "status",
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Confidence %",
      field: "confidence",
      valueFormatter: (params: GridValueFormatterParams<number | undefined>) => !!params.value ? `${params.value}%` : "",
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Target Revenue",
      field: "targetRevenue",
      renderCell: (params: GridRenderCellParams<number | undefined>) =>
        <DisplayFormattedNumber value={params.value} emptyDisplay="-" formatString={CURRENCY_NO_DECIMAL_FORMAT} />,
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Lead Source",
      field: "leadSource",
      valueGetter: (params: GridValueGetterParams<EntityLink | undefined>) => params.value?.linkName,
      flex: 3,
      disableColumnMenu: true
    },
    {
      headerName: "Lead Details",
      field: "leadDetails",
      renderCell: (params: GridRenderCellParams<string | undefined>) => {
        return (
          <TextCellTruncated text={params.value ?? ""} />
        );
      },
      flex: 3,
      disableColumnMenu: true
    },
    {
      headerName: "Creation Date",
      field: "createdOn",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => params.value &&
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />,
      flex: 3,
      disableColumnMenu: true
    },
    {
      headerName: "Close Date",
      field: "closeDate",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => params.value &&
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />,
      flex: 3,
      disableColumnMenu: true
    }
  ];

  componentDidMount() {
    this.props.employeeService.fetchSalesReps();
    this.props.companyService.fetchData();
    this.props.regionService.fetchStates();
  }

  @bind
  private _onSearchClick(criteria: OpportunitySearchCriteria) {
    // also resets sidebar filters based on new data set
    this.props.reportService.fetchSalesOpportunitiesReport(criteria);

    // reset sort model
    this._onSortModelChange([{
      field: criteria.dateType === "CreationDate" ? "createdOn" : "closeDate",
      sort: "asc"
    }]);
  }

  @bind
  private _onSortModelChange(newSortModel: GridSortModel) {
    this.setState({
      gridSortModel: newSortModel
    });
  }

  @bind
  private _openCustomerNoteModal(selectedCustomer: Customer | undefined) {
    this.setState({
      isCustomerNoteModalOpen: true,
      noteCustomer: selectedCustomer
    });
  }

  @bind
  private _closeCustomerNoteModal() {

    this.setState({
      isCustomerNoteModalOpen: false,
      noteCustomer: undefined
    });
  }

  @bind
  private _companyFilterChange(companyKey: string) {
    this.props.reportService.updateSalesOpportunitiesCompanyFilter(companyKey);
  }

  @bind
  private _checkboxFilterChange(filterType: keyof ISalesOpportunitiesFilters, id: number | undefined, checked: boolean) {
    this.props.reportService.updateSalesOpportunitiesCheckboxFilter(filterType, id, checked);
  }

  @bind
  private _filterReportData(): Opportunity[] {
    const {
      opportunityReportFetchResults,
      opportunityFilters,
      opportunityParameters
    } = this.props.reportService.getState();

    let customerFilterChecks: number[] = [];
    let customerFilterUnchecks: number[] = [];
    _.forEach(opportunityFilters.customers, (c) => {
      if (c.id) {
        c.isChecked ? customerFilterChecks.push(c.id) : customerFilterUnchecks.push(c.id);
      }
    });

    let salesRepFilterChecks: number[] = [];
    let salesRepFilterUnchecks: number[] = [];
    if (!opportunityParameters.createdById) {
      _.forEach(opportunityFilters.salesReps, (s) => {
        if (s.id) {
          s.isChecked ? salesRepFilterChecks.push(s.id) : salesRepFilterUnchecks.push(s.id);
        }
      });
    }

    let leadSourceFilterChecks: number[] = [];
    let leadSourceFilterUnchecks: number[] = [];
    _.forEach(opportunityFilters.leadSources, (l) => {
      if (l.id) {
        l.isChecked ? leadSourceFilterChecks.push(l.id) : leadSourceFilterUnchecks.push(l.id);
      }
    });

    let reportData = opportunityReportFetchResults.data ?? [];
    reportData = _.filter(reportData, (opp) => {
      return (
        (opportunityFilters.companyKey === "" || opp.company?.companyKey === opportunityFilters.companyKey)
        &&
        (
          _.some(customerFilterChecks, (c) => opp.customer?.id === c)
          ||
          _.some(salesRepFilterChecks, (s) => opp.createdBy?.linkId === s)
          ||
          _.some(leadSourceFilterChecks, (l) => opp.leadSource?.linkId === l)
        )
        &&
        !(
          _.some(customerFilterUnchecks, (c) => opp.customer?.id === c)
          ||
          _.some(salesRepFilterUnchecks, (s) => opp.createdBy?.linkId === s)
          ||
          _.some(leadSourceFilterUnchecks, (l) => opp.leadSource?.linkId === l)
        )
      );
    })

    return reportData;
  }

  render() {
    const {
      isCustomerNoteModalOpen,
      noteCustomer,
      gridSortModel
    } = this.state;

    const {
      opportunityParameters,
      opportunityReportFetchResults,
      opportunityFilters
    } = this.props.reportService.getState();

    const {
      salesRepFetchResults
    } = this.props.employeeService.getState();
    const salesReps = salesRepFetchResults.data ?? [];

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    const {
      companyFetchResults
    } = CompanyService.getState();
    const companies = companyFetchResults.data ?? [];

    const filteredRows = this._filterReportData();

    return (
      <>
        <div className={styles.mainContainer}>
          <div className={styles.filterContainer}>
            <SalesOpportunitiesFilters
              hasFetched={opportunityReportFetchResults.hasFetched}
              companies={companies}
              filters={opportunityFilters}
              searchCriteria={opportunityParameters}
              companyFilterChange={this._companyFilterChange}
              checkboxFilterChange={this._checkboxFilterChange}
            />
          </div>

          <div className={styles.reportContainer}>
            <CardLinedHeader titleText="Sales Opportunities" style={{ flex: "1" }}>
              <CardActions>
                <SalesOpportunitiesSearchForm
                  criteria={opportunityParameters}
                  salesReps={salesReps}
                  regions={regionData}
                  onSubmit={this._onSearchClick}
                />
              </CardActions>
              <AjaxActionIndicator state={[opportunityReportFetchResults]} />
              <div>
                <DataGridPro
                  columns={this.gridColumns}
                  rows={filteredRows}
                  density="compact"
                  sortModel={gridSortModel}
                  onSortModelChange={this._onSortModelChange}
                  disableMultipleSelection
                  hideFooter
                  autoHeight
                />
              </div>
            </CardLinedHeader>

            <div style={{ display: "flex" }}>
              <CardLinedHeader
                titleText={"Opportunities by Status"}
                style={{ flex: "1 0 412px" }}
              >
                <GraphStatus
                  graphData={filteredRows}
                />
              </CardLinedHeader>

              <CardLinedHeader
                titleText={"Opportunities by Sales Representatives"}
                style={{ flex: "1 0 412px" }}
              >
                <GraphSalesRep
                  graphData={filteredRows}
                />
              </CardLinedHeader>

              <CardLinedHeader
                titleText={"Opportunities by Lead Source"}
                style={{ flex: "1 0 412px" }}
              >
                <GraphLeadSource
                  graphData={filteredRows}
                />
              </CardLinedHeader>
            </div>
          </div>
        </div>
        <AddCustomerContactInfoModal
          isOpen={isCustomerNoteModalOpen}
          customer={noteCustomer}
          onClose={this._closeCustomerNoteModal}
        />
      </>
    );
  }
}

export const SalesOpportunitiesView = ReportService.inject(
  StateService.inject(
    EmployeeService.inject(
      CompanyService.inject(
        _SalesOpportunitiesView
      )
    )
  )
);