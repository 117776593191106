import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Customer
} from "$Generated/api";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Link
} from "$Imports/MaterialUIComponents";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface IAddCustomerContactInfoModalProps {
  isOpen: boolean;
  customer: Customer | undefined;
  onClose: () => void;
}

export class AddCustomerContactInfoModal extends React.PureComponent<IAddCustomerContactInfoModalProps> {

  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      customer
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{customer?.customerName} Contact Information</DialogTitle>
        <DialogContent>
          <div>
            <div>
              <div style={{ fontWeight: "bold" }}>Address</div>
              <div>{`${customer?.address1 ?? ""} ${customer?.address2 ?? ""}`}</div>
              <div>{`${customer?.city ?? ""}, ${customer?.region?.regionAbbreviation ?? ""}, ${getFormattedZipPostalCode(customer) ?? ""}`}</div>
            </div>
            <div style={{ margin: "10px 0" }}>
              <div style={{ fontWeight: "bold" }}>Contact Details</div>
              <div>{customer?.contactName ?? ""}</div>
              <div><u>Phone Number:</u> {customer?.phoneNumber ?? ""}</div>
              <div><u>Cell Number:</u> {customer?.cellNumber ?? ""}</div>
              <div><u>Website:</u> {customer?.website &&
                <Link
                  color="secondary"
                  href={customer?.website}
                  target="_blank"
                >
                  {customer?.website}
                </Link>}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onClose}>
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}