import {
  moment
} from "$Imports/Imports";

interface DatRate {
  "rate"?: number;
  "timeFrameDays"?: number;
  "modifiedOn"?: Date;
  "isCachedZoneRate"?: boolean;
  "message"?: string;
}

interface AddressObject {
  "regionId"?: number;
  "zipPostalCode"?: string;
  "region"?: {
    "countryId"?: number;
  };
}

type SimplifiedQuoteQuoteTypeEnum = "Full" | "Quick" | "Contract";
type SimplifiedCustomersQuoteQuoteKindEnum = "Quote" | "CustomerQuote";

export function isNullOrUndefined(value: any): boolean {
  return (value === null || value === undefined);
}

export function getDatRateMessage(datRate: DatRate | null | undefined): string {
  var message = "";

  if (datRate) {
    if (datRate.message) {
      message = datRate.message;
    } else if (datRate.isCachedZoneRate) {
      message = `Using previously pull DAT rate on ${moment(datRate.modifiedOn).local().format("MM/DD/YYYY")}.`;
    }
    else if (datRate.timeFrameDays && datRate.timeFrameDays > 3) {
      message = `DAT rate not available. DAT rate is ${datRate.timeFrameDays}-day average`;
    }
    else if (datRate.rate == 0 || isNullOrUndefined(datRate.rate)) {
      message = "DAT rate not available. Minimum rate applied.";
    }
  }

  return message;
}

export function getDisplayedQuoteNumber(quoteNumber: number | string, quoteType: SimplifiedQuoteQuoteTypeEnum | undefined, quoteKind: SimplifiedCustomersQuoteQuoteKindEnum | undefined) {
  let prefix = "";

  if (quoteType === "Full") {
    prefix = 'Q';
  } else if (quoteType === "Quick") {
    prefix = 'KQ';
  }

  if (quoteKind === "CustomerQuote") {
    prefix = 'EQ';
  }

  return `${prefix}${quoteNumber}`;
}

export function getFormattedZipPostalCode(data: string | AddressObject | undefined): string | undefined {
  let isCanadian;
  if (typeof(data) === "object") {
    isCanadian = data?.region?.countryId === 2;
    return isCanadian ? data?.zipPostalCode?.replace(/\s/g,'').match(/.{1,3}/g)?.join(" ") : data?.zipPostalCode;
  } else {
    isCanadian = data && isNaN(Number(data?.charAt(0)));
    return isCanadian ? data?.match(/.{1,3}/g)?.join(" ") : data;
  }
}

export function getTrimmedZipPostalCode(value: string | undefined): string | undefined {
  return value?.replace(/\s/g,'').toUpperCase();
}

export function qualifyZipPostalCodeInput(input: string, isCanadianZone: boolean = true, isThreeDigitZip: boolean = false) {
  let zip = input;
  const maxZipLength = isThreeDigitZip ? 3 : isCanadianZone ? 7 : 5;

  if (isCanadianZone) {
    // Remove non-alpha numeric characters and trim to 7 (or 3) digits
    zip = zip.replace(/[^a-zA-Z0-9\s]/g, '').slice(0, maxZipLength).toUpperCase();
  } else {
    // Remove non-numeric characters and trim to 5 (or 3) digits
    zip = zip.replace(/[^0-9]/g, '').slice(0, maxZipLength);
  }

  return zip;
}