export function getBarHeight(dataCount: number): number {
    let height = 49; // 49 mimics the dataGrid cell height

    switch (dataCount) {
        case 1:
            return height * 3;
        case 2:
            return dataCount * height * 1.75;
        case 3:
            return dataCount * height * 1.5;
        case 4:
            return dataCount * height * 1.25;
        default:
            return dataCount * height;
    }
}

export function getContainerMinHeight(hasData: boolean): number {
    let tooltipHeight = 340;
    return hasData ? tooltipHeight : 0;
}