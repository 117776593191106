
import {
  React,
  withAuth,
  AuthContextProps,
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents"

import {
  NavigationService,
  INavigationServiceInjectedProps
} from "$State/NavigationFreezerService"

import {
  _
} from "$Imports/Imports";

import { 
  SharedSecurityContext 
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

const styles: {
  mainContainer: string,
  appTitle: string,
  card: string,
  message: string
} = require("./NoCompanyAccessView.scss");

interface INoReportingAccessViewBaseProps {

}

type INoReportingAccessViewProps = INoReportingAccessViewBaseProps & INavigationServiceInjectedProps & AuthContextProps;

class _NoReportingAccessView extends React.Component<INoReportingAccessViewProps> {

  private async _onLogout() {   
    await SharedSecurityContext.logout();
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.appTitle}>KAISER REPORTING PORTAL</div>

        <div className={styles.message}>
          This user does not currently have access to the Reporting Portal. 
          Please assign access via KeyCloak and login again.
        </div>

        <Button 
          onClick={this._onLogout}
          variant="contained"
          color="secondary"
        >
            Logout
        </Button>

      </div>
    );
  }
}

export const NoCompanyAccessView = withAuth(
  NavigationService.inject(
    _NoReportingAccessView,
  )
);
