import {
  React
} from "$Imports/Imports";

import {
  DeliveredFreightView
} from "./DeliveredFreightView";

interface IDeliveredFreightViewPageProps { }

export class DeliveredFreightViewPage extends React.Component<IDeliveredFreightViewPageProps> {
  render() {
    return (
      <DeliveredFreightView />
    )
  }
}