import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  DATE_ONLY_FORMAT,
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil"

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

import {
  CardActions,
  GridColDef,
  DataGridPro,
  GridRenderCellParams,
  GridValueGetterParams,
  Stack,
  GridValueFormatterParams,
  GridApiPro,
  Button
} from "$Imports/MaterialUIComponents";

import {
  Download
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  CardLinedHeader,
  QuoteLink,
  CustomerLink,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  EntityLink,
  QuoteApprovalReasonApprovalNeededReasonIdEnum,
  QuoteReviewsReportRow,
  QuoteReviewsReportRowApprovalNeededTriggerEnum,
  ReviewSearchCriteria
} from "$Generated/api";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  ICompanyServiceInjectedProps,
  CompanyService
} from "$State/CompanyFreezerService";

import {
  QuoteReviewsSearchForm
} from "./QuoteReviewsSearchForm";

import {
  approvalReasonTextMap
} from "$Utilities/enumUtil";

const styles: {
  mainContainer: string,
  filterContainer: string
} = require("./QuoteReviews.scss");

interface IQuoteReviewsViewState { }

interface IQuoteReviewsViewBaseProps { }

type IQuoteReviewsViewProps = IQuoteReviewsViewBaseProps
  & IReportServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICompanyServiceInjectedProps;

class _QuoteReviewsView extends React.Component<IQuoteReviewsViewProps, IQuoteReviewsViewState> {

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Quote #",
      field: "quoteNumber",
      width: 70,
      valueFormatter: (params: GridValueFormatterParams<string | undefined>) => `Q${params.value}`,
      renderCell: (params: GridRenderCellParams<string | undefined, QuoteReviewsReportRow>) => {
        return (
          <QuoteLink
            quoteId={params.row.quoteId}
            quoteNumber={`Q${params.value}`}
            isCustomerQuote={false}
            newWindow={true}
          />
        )
      },
    },
    {
      headerName: "Quote Date",
      field: "quoteDate",
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) => !!params.value ? moment(params.value).local().format(DATE_ONLY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => !!params.value &&
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_ONLY_FORMAT}
        />,
      width: 95,
    },
    {
      headerName: "Caller",
      field: "caller",
      flex: 4,
      valueFormatter: (params: GridValueFormatterParams<string>) => params.value,
      valueGetter: (params: GridValueGetterParams<EntityLink | undefined>) => params.value?.linkName ?? "",
      renderCell: (params: GridRenderCellParams<string, QuoteReviewsReportRow>) =>
        <CustomerLink
          customerId={params.row.caller?.linkId}
          customerName={params.value}
          newWindow={true}
        />
    },
    {
      headerName: "Sales Rep",
      field: "salesRep",
      flex: 4,
    },
    {
      headerName: "Company",
      field: "company",
      width: 85,
    },
    {
      headerName: "Review Decision",
      field: "reviewDecision",
      minWidth: 83,
    },
    {
      headerName: "Reviewer",
      field: "reviewer",
      flex: 3,
    },
    {
      headerName: "Approval Needed Trigger",
      field: "approvalNeededTrigger",
      valueFormatter: (params: GridValueFormatterParams<QuoteApprovalReasonApprovalNeededReasonIdEnum | undefined>) => approvalReasonTextMap[params.value as QuoteApprovalReasonApprovalNeededReasonIdEnum ?? ""],
      minWidth: 150,
    },
    {
      headerName: "Denial Reason",
      field: "denialReason",
      valueFormatter: (params: GridValueFormatterParams<QuoteReviewsReportRowApprovalNeededTriggerEnum | undefined>) => params.value ?? "",
      renderCell: (params: GridRenderCellParams<QuoteReviewsReportRowApprovalNeededTriggerEnum | undefined>) => <TextCellTruncated text={params.value ?? ""} />,
      flex: 4,
    },
    {
      headerName: "Review Date",
      field: "reviewDate",
      valueFormatter: (params: GridValueFormatterParams<Date | undefined>) => !!params.value ? moment(params.value).local().format(DATE_WITH_TIME_MERIDIAN_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => params.value &&
        <DisplayFormattedDatetime
          value={params.value}
          formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
        />,
      width: 155
    }
  ];

  componentDidMount() {
    this.props.employeeService.fetchSalesReps();
    this.props.companyService.fetchData();
  }

  @bind
  private _onSearchClick(criteria: ReviewSearchCriteria) {
    this.props.reportService.fetchQuoteReviewsReport(criteria);
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      quoteReviewsParameters,
      quoteReviewsReportFetchResults
    } = this.props.reportService.getState();
    const reportData = quoteReviewsReportFetchResults.data ?? [];

    const {
      salesRepFetchResults
    } = this.props.employeeService.getState();
    const salesReps = salesRepFetchResults.data ?? [];

    const {
      companyFetchResults
    } = CompanyService.getState();
    const companies = companyFetchResults.data ?? [];

    var rowIdCounter = 0;

    return (
      <div className={styles.mainContainer}>
        <CardLinedHeader
          titleText="Quote Reviews"
          style={{ flex: "1" }}
          titleComponents={
            <Button
              className="cardHeaderButton"
              onClick={() => OnExportCsvClick('Quote_Reviews_Report_', this._gridApiRef)}
              title="Download"
              disabled={reportData.length === 0}
            >
              <Download /><b> Download</b>
            </Button>
          }
        >
          <CardActions>
            <QuoteReviewsSearchForm
              criteria={quoteReviewsParameters}
              salesReps={salesReps}
              companies={companies}
              onSubmit={this._onSearchClick}
            />
          </CardActions>
          <AjaxActionIndicator state={[quoteReviewsReportFetchResults]} />
          <DataGridPro
            columns={this.gridColumns}
            rows={reportData}
            density="compact"
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "reviewDate", sort: "asc" }]
              }
            }}
            sortingOrder={["asc", "desc"]}
            components={{
              NoRowsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {!quoteReviewsReportFetchResults.hasFetched
                    ? "Enter search criteria to view approved or denied quotes"
                    : "No quote reviews data for selected criteria or all quote reviews have been filtered out"
                  }
                </Stack>,
              NoResultsOverlay: () =>
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No quote reviews data for selected criteria or all quote reviews have been filtered out
                </Stack>
            }}
            apiRef={this._gridApiRef}
            disableSelectionOnClick
            hideFooter
            autoHeight
          />
        </CardLinedHeader>
      </div>
    );
  }
}

export const QuoteReviewsView = ReportService.inject(
  EmployeeService.inject(
    CompanyService.inject(
      _QuoteReviewsView
    )
  )
);