import {
  React,
  numeral,
  _
} from "$Imports/Imports";

import {
  BookedSalesReportView
} from "$Generated/api";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  CompanyLegend
} from "$Imports/CommonComponents";

import {
  GraphLabel
} from "$Components/common/Recharts/GraphLabel";

import {
  renderSalesMetricCells
} from "./BookedSalesMetricCell";

import {
  BookedSalesTooltip
} from "./BookedSalesTooltip";

import {
  getBarHeight,
  getContainerMinHeight
} from "$Utilities/barChartUtil";

interface IGraphWeightProps {
  bookedSalesData: BookedSalesReportView[];
}

export class GraphWeight extends React.Component<IGraphWeightProps> {

  render() {
    const {
      bookedSalesData
    } = this.props;

    // lodash orderBy returns a new array
    const weightGraphData = _.orderBy(bookedSalesData, "totalWeight").reverse();
    const height = getBarHeight(weightGraphData.length);
    const minHeight = getContainerMinHeight(weightGraphData.length > 0);

    return (
      <ResponsiveContainer height={height} minHeight={minHeight} width="99%">      
        <BarChart
          data={weightGraphData}
          layout="vertical"
        >
          <XAxis
            type="number"
            xAxisId={1}
          />
          <YAxis
            dataKey="username"
            type="category"
            yAxisId={1}
            width={90}
          />
          <Bar
            dataKey="totalWeight"
            xAxisId={1}
            yAxisId={1}
          > 
            {renderSalesMetricCells(weightGraphData)}
            <LabelList dataKey="totalWeight" content={(props)=>( 
                <GraphLabel
                  {...props}
                  formatValue={(value) =>(numeral(value).divide(1000).format("0,0") + "k lbs")}
                />
              )} 
            />
          </Bar>
          <Tooltip
            cursor={{ stroke: "#5a5c63", strokeWidth: "1", fillOpacity: "0" }}
            content={(props: any) => {
              return (
                <BookedSalesTooltip
                  payload={props.payload?.[0]?.payload}
                  isActive={props.active}
                />
              );
            }}
          />
          <Legend content={<CompanyLegend showOrange />} />
          <CartesianGrid horizontal={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
