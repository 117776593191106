import {
  _
} from "$Imports/Imports";

import {
  Cell
} from "$Imports/Recharts";

import {
  DeliveredFreightReportView
} from "$Generated/api";

import {
  logisticsGreen,
  transportBlue
} from "$Themes/index";

export const renderDeliveredFreightMetricCells = (data?: DeliveredFreightReportView[]): JSX.Element[] => {

  const deliveredFreightData = data|| [];

  return _.map(deliveredFreightData, (reportData: DeliveredFreightReportView, bidx: number) => {
    let fillColor = transportBlue;

    if (reportData.company === "KAISER LOGISTICS INC") {
      fillColor = logisticsGreen;
    }
    return <Cell key={bidx} fill={fillColor} />;
  });
}