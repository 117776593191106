import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  LaneRevenueParametersVM,
  Region,
  Company
} from "$Generated/api";

import {
  LaneRevenueReportCriteriaValidationSchema
} from "$State/ReportFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IOwnProps {
  criteria: LaneRevenueParametersVM;
  regions: Region[];
  companies: Company[];
  onSubmit: (criteria: LaneRevenueParametersVM) => void;
}

interface IOwnState {
  criteria: LaneRevenueParametersVM;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./LaneRevenueSearchForm.scss");

class _LaneRevenueSearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    criteria: {},
    errors: null
  };

  componentDidMount() {
    this.setState({ criteria: this.props.criteria });
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.criteria !== prev.criteria) {
      this.setState({ criteria: this.props.criteria });
    }
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onProvinceChange(type: "originProvince" | "destinationProvince", event: SelectChangeEvent) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        [type]: event.target.value
      }
    }));
  }
  
  @bind
  private _onCompanyChange(event: SelectChangeEvent<number | string>) {
    const companyId = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        companyId: companyId === 0 ? undefined : companyId
      }
    }));
  }

  @bind
  private async _onSubmit() {
    const errors = await validateSchema(LaneRevenueReportCriteriaValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(this.state.criteria);
  }

  render() {
    const { 
      regions,
      companies
    } = this.props;

    const {
      criteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<LaneRevenueParametersVM>(errors);

    const originHasError = !validationParser.isValid("originProvince");
    const destinationHasError = !validationParser.isValid("destinationProvince");

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
      >
        <div style={{ flex: "0 0 14.5rem" }}>
          <FormControl fullWidth>
            <InputLabel shrink>Origin State</InputLabel>
            <Select
              value={criteria.originProvince ?? ""}
              onChange={(event) => this._onProvinceChange("originProvince", event)}
              error={originHasError}
              displayEmpty
            >
              <MenuItem value={""}>All States</MenuItem>
              {regions.map((x, idx) => (
                <MenuItem value={x.regionAbbreviation} key={idx}>
                  {x.regionName}
                </MenuItem>
              ))}
            </Select>
            {originHasError && <FormHelperText error={originHasError}>{validationParser.validationMessage("originProvince")}</FormHelperText>}
          </FormControl>
        </div>

        <div style={{ flex: "0 0 14.5rem" }}>
          <FormControl fullWidth>
            <InputLabel shrink>Destination State</InputLabel>
            <Select
              value={criteria.destinationProvince ?? ""}
              onChange={(event) => this._onProvinceChange("destinationProvince", event)}
              error={destinationHasError}
              displayEmpty
            >
              <MenuItem value={""}>All States</MenuItem>
              {regions.map((x, idx) => (
                <MenuItem value={x.regionAbbreviation} key={idx}>
                  {x.regionName}
                </MenuItem>
              ))}
            </Select>
            {destinationHasError && <FormHelperText error={destinationHasError}>{validationParser.validationMessage("destinationProvince")}</FormHelperText>}
          </FormControl>
        </div>

        <div style={{ flex: "0 0 14.5rem" }}>
          <FormControl fullWidth>
            <InputLabel shrink>Company</InputLabel>
            <Select
              value={criteria.companyId ?? 0}
              name="companyId"
              onChange={(event) => this._onCompanyChange(event as React.ChangeEvent<HTMLInputElement>)}
              displayEmpty
            >
              <MenuItem value={0}>All</MenuItem>
              {companies.map((s, idx) => (
                <MenuItem value={s.tmcompanyId} key={idx}>{s.companyName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <DateRangePicker
          startDate={criteria.startDate}
          startError={validationParser.validationMessage("startDate")}
          endDate={criteria.endDate}
          endError={validationParser.validationMessage("endDate")}
          onChange={this._onDateRangeChange}
          horizontal
        />
      </SearchControlsContainer>
    );
  }
}

export const LaneRevenueSearchForm = _LaneRevenueSearchForm;