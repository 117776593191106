// #region Imports
import {
  React,
  _,
  getLogger,
  hasAuthParams,
  useAuth
} from "$Imports/Imports";

import {
  YaharaThemeProvider
} from "$Providers/index";

import {
  ThemeProvider,
  createTheme,
  Theme,
  StyledEngineProvider
} from "$Imports/MaterialUIStyles";

import {
  SharedSecurityContext
} from '$Shared/utilities/Security/ApplicationSecuritySettings';

import {
  ProgressPage
} from "$Imports/CommonComponents";

import {
  IRedirectState
} from "$Shared/utilities/Security/IRedirectState";

import { SitePubSubManager } from "$Utilities/pubSubUtil";

import {
  MainLayout,
  Routing,
  ApplicationBar,
  SideNavigation,
} from "$Components/root-components";

import { defaultTheme } from "$Themes/index";

import {
  AdapterMoment,
  CssBaseline,
  LocalizationProvider,
} from "$Imports/MaterialUIComponents";

import {
  ErrorStackPanel
} from "$Components/root-components/ErrorStackPanel";

import {
  TimeoutWarning
} from "$Components/root-components/TimeoutWarning";

import {
  setFaviconUrl
} from "$Utilities/faviconUtil";

// #endregion Imports

// Include the global styles
require("./css/global.scss");

const logger = getLogger('Application');
const logoFavicon = require("./images/barchart.ico") as string;

const _Application: React.FunctionComponent = (props) => {
  logger.info("Rendering application");

  const auth = useAuth();

  React.useEffect(() => {
    auth.startSilentRenew();

    return () => auth.stopSilentRenew();
  }, [auth, auth.startSilentRenew])

  React.useEffect(() => {
    return auth.events.addUserSignedIn(() => {
      SitePubSubManager.publish("application:login", "");
    });
  }, [auth.events, auth.signinRedirect])

  React.useEffect(() => {
    return auth.events.addUserSignedOut(() => {
      SitePubSubManager.publish("application:logout", "");
    });
  }, [auth.events, auth.removeUser])

  React.useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      auth.signoutRedirect();
    });
  }, [auth.events])

  // automatically sign-in
  React.useEffect(() => {
      if (!hasAuthParams() &&
          !SharedSecurityContext.getIsLogoutInProgress() &&
          !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {

          // re-authenticate with the intended destination
        auth.signinRedirect({
          state: {
            destinationUrl: location.pathname
          } as IRedirectState
        });
      }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  React.useEffect(() => {
    setFaviconUrl(logoFavicon);
  }, []);

  const materialUITheme: Theme = createTheme(defaultTheme.themeOptions);
  
  return (
    <StyledEngineProvider injectFirst> 
      <ThemeProvider
        theme={materialUITheme}
      >
        <CssBaseline />
        {auth.isAuthenticated ?
          <YaharaThemeProvider
            themeConfig={defaultTheme}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimeoutWarning />
              <ErrorStackPanel />
              <MainLayout
                applicationBar={(
                  <ApplicationBar />
                )}
                sideNavigation={(
                  <SideNavigation />
                )}
              >
                <Routing />
              </MainLayout>
            </LocalizationProvider>
          </YaharaThemeProvider> :
        <ProgressPage />}
      </ThemeProvider>
    </StyledEngineProvider>
  );  
}

export const Application = _Application