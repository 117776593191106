import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Company, OpportunitySearchCriteria
} from "$Generated/api";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  ISalesOpportunitiesFilters
} from "$State/ReportFreezerService";

const styles: {
  filterSectionHeader: string
} = require("./SalesOpportunities.scss");

interface ISalesOpportunitiesFiltersProps {
  hasFetched: boolean;
  companies: Company[];
  filters: ISalesOpportunitiesFilters;
  searchCriteria: OpportunitySearchCriteria;
  companyFilterChange: (companyKey: string) => void;
  checkboxFilterChange: (filterType: keyof ISalesOpportunitiesFilters, id: number | undefined, checked: boolean) => void;
}

interface ISalesOpportunitiesFiltersState {

}

export class SalesOpportunitiesFilters extends React.PureComponent<ISalesOpportunitiesFiltersProps, ISalesOpportunitiesFiltersState> {

  @bind
  private _mapCompanyFilters() {
    const {
      companies,
      filters
    } = this.props;

    return (
      <RadioGroup name="filterCompany" value={filters.companyKey} onChange={this._companyFilterChange}>
        <FormControlLabel
          value={""}
          control={<Radio />}
          label={"Both"}
        />
        {_.map(companies, (c, idx) => { return (
          <FormControlLabel
            value={c.companyKey}
            control={<Radio />} 
            label={`Kaiser ${c.companyName}`}
            key={idx}
          />
        )})}
      </RadioGroup>
    );
  }

  @bind
  private _mapCustomerFilters() {
    const {
      filters
    } = this.props;

    return <>{filters.customers?.map((row, idx) => {
      return (
        <FormControlLabel
          label={row.displayName ?? ""}
          key={idx}
          control={<Checkbox
            size="small"
            checked={row.isChecked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._checkboxFilterChange("customers", row.id, checked)}
          />}
        />
      );
    })}</>;
  }

  @bind
  private _mapSalesRepFilters() {
    const {
      filters
    } = this.props;

    return <>{filters.salesReps?.map((row, idx) => {
      return (
        <FormControlLabel
          label={row.displayName ?? ""}
          key={idx}
          control={<Checkbox
            size="small"
            checked={row.isChecked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._checkboxFilterChange("salesReps", row.id, checked)}
          />}
        />
      );
    })}</>
  }

  @bind
  private _mapLeadSourceFilters() {
    const {
      filters
    } = this.props;

    return <>{filters.leadSources?.map((row, idx) => {
      return (
        <FormControlLabel
          label={row.displayName ?? ""}
          key={idx}
          control={<Checkbox
            size="small"
            checked={row.isChecked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._checkboxFilterChange("leadSources", row.id, checked)}
          />}
        />
      );
    })}</>
  }

  @bind
  private _companyFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.companyFilterChange(event.target.value);
  }

  @bind
  private _checkboxFilterChange(filterType: keyof ISalesOpportunitiesFilters, id: number | undefined, checked: boolean) {
    this.props.checkboxFilterChange(filterType, id, checked);
  }

  render() {
    const {
      searchCriteria,
      hasFetched
    } = this.props;

    const companyFilters = this._mapCompanyFilters();
    const customerFilters = this._mapCustomerFilters();
    const salesRepFilters = !searchCriteria.createdById ? this._mapSalesRepFilters() : undefined;
    const leadSourceFilters = this._mapLeadSourceFilters();

    return (
      <CardLinedHeader titleText="Filters">
        <div className={styles.filterSectionHeader}>Company</div>
        {hasFetched && <FormGroup>{companyFilters}</FormGroup>}

        <div className={styles.filterSectionHeader}>Customer</div>
        <FormGroup>{customerFilters}</FormGroup>

        {
          !searchCriteria.createdById ?
          <>
            <div className={styles.filterSectionHeader}>Sales Representative</div>
            <FormGroup>{salesRepFilters}</FormGroup>
          </>
          : undefined
        }

        <div className={styles.filterSectionHeader}>Lead Source</div>
        <FormGroup>{leadSourceFilters}</FormGroup>
      </CardLinedHeader>
    )
  }
}
