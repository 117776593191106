import {
    SummaryCard
} from "$Components/CapacityReport";
import {
    React
} from "$Imports/Imports";

const styles: {
    sectionHeader: string;
    section: string;
    sectionRow: string;
} = require("./TerminalSummary.scss");

export interface IDatapoint {
    label: string;
    value: number;
}

interface ITerminalSummaryProps {
    title: string;
    datapoints: IDatapoint[];
    costPerMile?: number;
}

export const TerminalSummary: React.FunctionComponent<ITerminalSummaryProps> = (props: ITerminalSummaryProps) => {
    return  (
        <>
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    {props.title}
                </div>

                <div className={styles.sectionRow}>
                    {props.datapoints.map((datapoint: IDatapoint) => (
                        <SummaryCard
                            key={datapoint.label}
                            title={datapoint.label}
                            value={datapoint.value}
                            costPerMile={props.costPerMile}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}