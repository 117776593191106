import {
  React,
  _,
  memoizeOne,
  bind
} from "$Imports/Imports";

import {
  Table,
  TableFooter,
  TableHead
} from "$Imports/MaterialUIComponents";

import {
  DataTableHeader
} from "./DataTableHeader";

import {
  DataTableRows
} from "./DataTableRows";

import {
  directionType
} from "./IDataTableColumn";

import {
  IDataTableColumn,
  sortFunctionType
} from "./IDataTableColumn";

const styles: {
  wrapper: string;
} = require("./DataTable.scss");

interface IDataTableProps<T = unknown> {
  data: T[];
  columns: Array<IDataTableColumn<T>>;
  takeRows?: number;
  skipRows?: number;
  defaultSortColumnName?: string;
  defaultSortDirection?: directionType;
  tableFooterComponent?: JSX.Element;
  tableHeaderComponent?: JSX.Element;
  stickyHeader?: boolean;
  hover?: boolean;
  onRowClick?: (data: T) => void;
  onRowDoubleClick?: (data: T) => void;
  tableActionEnterKey?: () => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
  onSortChange?: (event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) => void;
  suppressSorting?: boolean;
  tableContextProps?: TableContextProps;
  useKeyboardNavigation?: boolean;
  tableRef?: React.Ref<HTMLDivElement>;
}

interface IDataTableState {
  sortDirection: directionType;
  sortColumnName?: string;
  propSortDirection?: directionType;
  propSortColumnName?: string;
}

interface TableContextProps {
  hiddenColumns?: string[];
  disableAction?: boolean;
  rowStyle?: (data: any) => React.CSSProperties;
  rowClass?: string | undefined; // this could be turned into a function like rowStyle if the class needs to depend on the row data
  setScroll?: (el: HTMLTableRowElement | null, containerRef: React.RefObject<HTMLDivElement> | undefined) => void;
  selectedRow?: any;
  containerDivRef?: React.RefObject<HTMLDivElement>;
}

const TableContext = React.createContext<TableContextProps | undefined>(undefined);
export const TableContextConsumer = TableContext.Consumer;

export class DataTable<T = unknown> extends React.PureComponent<
  IDataTableProps<T>,
  IDataTableState
> {

  state: IDataTableState = {
    sortDirection: "asc",
  };

  static getDerivedStateFromProps(props: IDataTableProps<unknown>, state: IDataTableState): Partial<IDataTableState> | null {

    // Update the state of the lastValue does not match the prop.
    if (state === null ||
      props.defaultSortColumnName !== state.propSortColumnName ||
      props.defaultSortDirection !== state.propSortDirection) {
      return {
        sortColumnName: props.defaultSortColumnName ? props.defaultSortColumnName : state.sortColumnName,
        sortDirection: props.defaultSortDirection ? props.defaultSortDirection : state.sortDirection,
        propSortColumnName: props.defaultSortColumnName,
        propSortDirection: props.defaultSortDirection
      };
    }

    // Do nothing if the value does not change.
    return null;
  }

  @bind
  private _onSortColumn(event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortdirection: directionType) {
    const newSortDirection = sortdirection === "asc" ? "desc" : "asc";

    this.setState({
      sortDirection: newSortDirection,
      sortColumnName,
    });

    if (this.props.onSortChange) {
      this.props.onSortChange(event, sortColumnName, newSortDirection);
    }
  }

  private _sortData(data: T[], columns: Array<IDataTableColumn<T>>, sortColumnName: string | undefined, sortDirection: directionType): T[] {
    let sortFunction: sortFunctionType<T> = (d) => d;

    if (sortDirection === null || sortColumnName === undefined) {
      return data;
    }

    const column = _.find(columns, (c) => c.columnName === sortColumnName);

    if (column && column.sortMethod !== undefined) {
      sortFunction = column.sortMethod;
    }

    let sortedData = data;

    if (sortFunction) {
      sortedData = _.sortBy(sortedData, sortFunction);
    }

    if (sortDirection === "desc") {
      sortedData = _.reverse(sortedData);
    }

    return sortedData;
  }

  private _pageData(data: T[], take?: number, skip?: number): T[] {
    if (take !== undefined && skip !== undefined) {
      return _.slice(data, skip, skip + take);
    }

    return data;
  }

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) {
    if (this.props.onRowClick) {
      this.props.onRowClick(data);
    }
  }

  @bind
  private _onRowDoubleClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) {
    if (this.props.onRowDoubleClick) {
      this.props.onRowDoubleClick(data);
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  @bind
  private _keyboardNavigation(event: React.KeyboardEvent<HTMLElement>) {
    const { data, columns, skipRows, takeRows, useKeyboardNavigation, tableContextProps, suppressSorting } = this.props;
    const { sortDirection, sortColumnName } = this.state;

    if (!useKeyboardNavigation) {
      return;
    }

    if (this.props.onRowClick && (event.key == "ArrowUp" || event.key == "ArrowDown")) {
      const sortedData = suppressSorting ? data : this._sortData_memoized(data, columns, sortColumnName, sortDirection);
      const pagedData = this._pageData_memoized(sortedData, takeRows, skipRows);

      var idx = _.findIndex(pagedData, x => _.isEqual(x, tableContextProps?.selectedRow));

      if (idx > -1) {
        if (event.key == "ArrowUp" && idx > 0) {
          this.props.onRowClick(pagedData[Math.max(0, idx - 1)]);
          event.preventDefault();
        }
        else if (event.key == "ArrowDown" && idx < pagedData.length - 1) {
          this.props.onRowClick(pagedData[Math.min(pagedData.length, idx + 1)]);
          event.preventDefault();
        }
      } else {
        this.props.onRowClick(pagedData[0]);
        event.preventDefault();
      }
    }
    else if (this.props.tableActionEnterKey && event.key == "Enter") {
      this.props.tableActionEnterKey();
    }
  }

  private readonly _sortData_memoized = memoizeOne(this._sortData);
  private readonly _pageData_memoized = memoizeOne(this._pageData);

  render(): JSX.Element {
    const {
      data,
      columns,
      skipRows,
      takeRows,
      tableFooterComponent,
      tableHeaderComponent,
      tableRef,
      stickyHeader,
      hover,
      useKeyboardNavigation,
      suppressSorting
    } = this.props;
    const { sortDirection, sortColumnName } = this.state;

    const sortedData = suppressSorting ? data : this._sortData_memoized(data, columns, sortColumnName, sortDirection);
    const pagedData = this._pageData_memoized(sortedData, takeRows, skipRows);

    return (
      <div className={styles.wrapper} tabIndex={useKeyboardNavigation ? 0 : undefined} onKeyDown={this._keyboardNavigation} ref={tableRef}>
        <TableContext.Provider value={this.props.tableContextProps}>
          <Table stickyHeader={stickyHeader}>
            <TableHead>
              {tableHeaderComponent}
            </TableHead>
            <DataTableHeader
              onSortColumnClick={this._onSortColumn}
              sortDirection={sortDirection}
              columns={columns}
              sortColumnName={sortColumnName}
              suppressSorting={suppressSorting}
            />
            <DataTableRows
              data={pagedData}
              columns={columns}
              onRowClick={this._onRowClick}
              onRowDoubleClick={this._onRowDoubleClick}
              onCellClick={this._onCellClick}
              hover={hover}
            />
            <TableFooter>
              {tableFooterComponent}
            </TableFooter>
          </Table>
        </TableContext.Provider>
      </div>
    );
  }
}
