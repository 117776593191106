import {
  React,
  _
} from "$Imports/Imports";

import {
  Props
} from "$Imports/Recharts";

interface IOwnProps extends Props {
  barPadding?: number;
  formatValue?: (value?: string | number) => string;
  insideColor?: string;
  outsideColor?: string;
}

const GraphLabel = (props: IOwnProps) => {
  const [textDim, setTextDim] = React.useState<{
    width: number,
    height: number,
    viewPortWidth: number,
    viewPortHeight: number
  }>({
    width: 0,
    height: 0,
    viewPortWidth: 0,
    viewPortHeight: 0
  });

  const textRef = React.useRef<SVGTextElement>(null);

  React.useEffect(() => {
    if (textRef.current) {
      setTextDim({
        width: textRef.current.getBoundingClientRect().width,
        height: textRef.current.getBoundingClientRect().height,
        viewPortWidth: textRef.current.viewportElement?.clientWidth || 0,
        viewPortHeight: textRef.current.viewportElement?.clientHeight || 0
      });
    }
  }, [props]);

  const dataHeight = _.toNumber(props.height || 0);
  const dataWidth = _.toNumber(props.width || 0);
  const xPos = _.toNumber(props.x || 0)
  const yPos = _.toNumber(props.y || 0);
  const barPadding = _.toNumber(props.barPadding || 0);

  let insideBar: boolean = false;

  // Calculate y position.
  const heightOffSet = dataHeight / 2;
  const calcY = yPos + heightOffSet;

  // Calculate total horizontal width.
  const totalHorizontalWidth = dataWidth + xPos + textDim.width;

  // Calculate the display value.
  const displayValue = props.formatValue ? props.formatValue(props.value) : props.value;

  // Determine if the label should be inside or outside the bar.
  if (totalHorizontalWidth > textDim.viewPortWidth) {
    insideBar = true;
  }

  // Calculate the x position.
  const calcX = xPos + dataWidth + (insideBar ? -barPadding : barPadding);

  return (
    <g>
      <text
        transform={`translate(${calcX},${calcY})`}
        textAnchor={insideBar ? "end" : "start"}
        dominantBaseline="middle"
        fill={insideBar ? props.insideColor : props.outsideColor}
        ref={textRef}>
        {displayValue}
      </text>
    </g>
  );
}

GraphLabel.defaultProps = {
  barPadding: 2,
  insideColor: "#fff",
  outsideColor: "#000",
};

export {
  GraphLabel
}