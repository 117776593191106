import { defaultTheme } from "./defaultTheme";

const caTerminalOrange = "#f59a23";
const waTerminalPurple = "#8c3fc5";

import {
  blueTheme,
  transportBlue,
  highlightBlue
} from "./blueTheme";
import {
  greenTheme,
  logisticsGreen,
  highlightGreen
} from "./greenTheme";

export {
  defaultTheme,
  blueTheme,
  transportBlue,
  highlightBlue,
  greenTheme,
  logisticsGreen,
  highlightGreen,
  caTerminalOrange,
  waTerminalPurple
};
