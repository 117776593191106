import {
  React
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  AboutService,
  IAboutServiceInjectedProps
} from "$State/AboutFreezerService";

import {
  openCustomerQuotePreview,
  navigateToSalesPortal,
  openTabForUrl
} from "$Utilities/windowUtil";

interface IQuoteLinkBaseProps {
  quoteId?: number;
  quoteNumber?: string;
  isCustomerQuote?: boolean;
  newWindow?: boolean;
}

type IQuoteLinkProps = IQuoteLinkBaseProps
  & IAboutServiceInjectedProps;

class _QuoteLink extends React.PureComponent<IQuoteLinkProps> {
  private _navigateTo(event: React.MouseEvent, url: string): void {
    if (this.props.isCustomerQuote && this.props.quoteId) {
      openCustomerQuotePreview(this.props.quoteId);
    }
    else {
      this.props.newWindow ? openTabForUrl(url) : navigateToSalesPortal(url);
    }

    // prevent "href" from navigating + reloading app in current window
    event.preventDefault();
  }

  render() {
    const {
      quoteId,
      quoteNumber,
      isCustomerQuote
    } = this.props;

    const url = isCustomerQuote ? `customerQuote/${quoteId}` : `/salesportal/quote/${quoteId}/true`;
    
    const salesPortalUrl = this.props.aboutService.getState().aboutFetchResults?.data?.salesPortalUrl ?? "";
    const combinedUrl = salesPortalUrl + url;

    return (
      <Link
        href={combinedUrl}
        color="secondary"
        onClick={(event) => this._navigateTo(event, combinedUrl)}
      >
        {quoteNumber}
      </Link>
    );
  }
}

export const QuoteLink = AboutService.inject(_QuoteLink);