import { 
    CardLinedHeader 
} from "$Components/common";
import { 
    React 
} from "$Imports/Imports";
import { 
    CheckCircle, 
    Error 
} from "$Imports/MaterialUIIcons";

const styles: {
    content: string;
    value: string;
} = require("./SummaryCard.scss");

interface ISummaryCardProps {
    title: string;
    value: number;
    costPerMile?: number;
}

export const SummaryCard: React.FunctionComponent<ISummaryCardProps> = (props: ISummaryCardProps) => {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    
    return (
        <>
            <CardLinedHeader>
                <div className={styles.content}>
                    <div>{props.title}</div>
                    <span className={styles.value}>
                        { props.value > (props.costPerMile ?? 0) && <CheckCircle color="success" /> }
                        { props.value < (props.costPerMile ?? 0) && <Error color="error" /> }
                        {Number.isNaN(props.value) ? "-" : currencyFormatter.format(props.value)}
                    </span>
                </div>
            </CardLinedHeader>
        </>
    );
}