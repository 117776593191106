import {
  React
} from "$Imports/Imports";

import {
  BookedSalesView
} from "./BookedSalesView";

interface IBookedSalesViewPageProps { }

export class BookedSalesViewPage extends React.Component<IBookedSalesViewPageProps> {
  render() {
    return (
      <BookedSalesView />
    )
  }
}