import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from "$Imports/MaterialUIComponents";

import {
  ReviewSearchCriteria,
  Employee,
  Company,
  ReviewSearchCriteriaDateTypeEnum,
  ReviewSearchCriteriaReviewTypeEnum
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";
import { QuoteReviewsReportCriteriaValidationSchema } from "$State/ReportFreezerService";

const styles: {
  searchContainer: string;
} = require("./QuoteReviews.scss");

interface IOwnProps {
  criteria: ReviewSearchCriteria;
  salesReps: Employee[];
  companies: Company[];
  onSubmit: (criteria: ReviewSearchCriteria) => void;
}

interface IOwnState {
  criteria: ReviewSearchCriteria;
  errors: ValidationError | null;
}

export class QuoteReviewsSearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    criteria: {
      dateType: "QuoteDate"
    },
    errors: null
  };

  componentDidMount() {
    this.setState({ criteria: this.props.criteria });
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.criteria !== prev.criteria) {
      this.setState({ criteria: this.props.criteria });
    }
  }

  @bind
  private _onDateTypeChange(event: SelectChangeEvent) {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        dateType: event.target.value as ReviewSearchCriteriaDateTypeEnum
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onChange(event: SelectChangeEvent<number | string>) {
    const value = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        [event.target.name]: value === 0 ? undefined : value
      }
    }));
  }
  @bind
  private _onCompanyChange(event: SelectChangeEvent<number | string>) {
    const companyId = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        companyId: companyId === 0 ? undefined : companyId
      }
    }));
  }

  @bind
  private _onReviewTypeChange(event: SelectChangeEvent<string | undefined>) {
    const reviewType = event?.target.value as ReviewSearchCriteriaReviewTypeEnum;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        reviewType: reviewType
      }
    }));
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(QuoteReviewsReportCriteriaValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(this.state.criteria);
  }

  render() {
    const {
      salesReps,
      companies
    } = this.props;
    const reviewers = salesReps?.filter(s => s.isManager || s.isAdmin) ?? [];

    const {
      criteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<ReviewSearchCriteria>(errors);

    return (
      <SearchControlsContainer
        className={styles.searchContainer}
        onSubmit={this._onSearchClick}
      >
        <DateRangePicker
          startDate={criteria.startDate}
          startError={validationParser.validationMessage("startDate")}
          endDate={criteria.endDate}
          endError={validationParser.validationMessage("endDate")}
          onChange={this._onDateRangeChange}
          horizontal
        />
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel>Date Type</InputLabel>
          <Select
            onChange={this._onDateTypeChange}
            value={criteria.dateType}
          >
            <MenuItem value={"QuoteDate"}>Quote Date</MenuItem>
            <MenuItem value={"ReviewDate"}>Review Date</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel shrink>Company</InputLabel>
          <Select
            value={criteria.companyId ?? 0}
            name="companyId"
            onChange={(event) => this._onCompanyChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={0}>All</MenuItem>
            {companies.map((s, idx) => (
              <MenuItem value={s.id} key={idx}>{s.companyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel shrink>Review Type</InputLabel>
          <Select
            value={criteria.reviewType}
            name="reviewType"
            onChange={(event) => this._onReviewTypeChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={undefined}>All</MenuItem>
            <MenuItem value={"ApprovalsOnly"}>Approvals Only</MenuItem>
            <MenuItem value={"DenialsOnly"}>Denials Only</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel>Reviewer</InputLabel>
          <Select
            value={criteria.reviewedByIds ?? []}
            multiple
            name="reviewedByIds"
            onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
            renderValue={(selected) => {
              if (selected.length === reviewers.length) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as number[], (s, idx) => {
                  const reviewer = _.find(reviewers, r => r.id === s);
                  return (
                    <span key={idx}>
                      {reviewer ? `${reviewer.firstName} ${reviewer.lastName}` : ""}
                      <>{idx !== (selected as number[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            {
              reviewers.map((reviewer) => {
                return (
                  <MenuItem key={reviewer.id} value={reviewer.id}>
                    <Checkbox checked={_.findIndex(criteria.reviewedByIds, r => r === reviewer.id) > -1} />
                    <ListItemText primary={`${reviewer.firstName} ${reviewer.lastName}`} />
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <FormControl style={{ flex: "1 1" }}>
          <InputLabel>Sales Representative</InputLabel>
          <Select
            value={criteria.createdByIds ?? []}
            multiple
            name="createdByIds"
            onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
            renderValue={(selected) => {
              if (selected.length === salesReps.length) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as number[], (s, idx) => {
                  const salesRep = _.find(salesReps, r => r.id === s);
                  return (
                    <span key={idx}>
                      {salesRep ? `${salesRep.firstName} ${salesRep.lastName}` : ""}
                      <>{idx !== (selected as number[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            {
              salesReps.map((salesRep) => {
                return (
                  <MenuItem key={salesRep.id} value={salesRep.id}>
                    <Checkbox checked={_.findIndex(criteria.createdByIds, r => r === salesRep.id) > -1} />
                    <ListItemText primary={`${salesRep.firstName} ${salesRep.lastName}`} />
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </SearchControlsContainer>
    );
  }
}