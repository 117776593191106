import {
  React
} from "$Imports/Imports";

import {
  FormGroup
} from "$Imports/MaterialUIComponents";

interface IBookedSalesFilterSectionProps {
  title: string;
  filters: any;
  onUpdateFilters: (shouldReset: boolean) => void;
}

const styles: {
  flexContainer: string,
  filterSectionHeader: string,
  filterSectionActions: string,
  actionsDivider: string
} = require('./BookedSalesFilterSection.scss');

export class BookedSalesFilterSection extends React.PureComponent<IBookedSalesFilterSectionProps> {

  render() {
    const {
      title,
      filters,
      onUpdateFilters
    } = this.props;

    return (
      <>
        <div className={styles.flexContainer}>
          <div className={styles.filterSectionHeader}>{title}</div>
          <div className={styles.flexContainer}>
            <span
              onClick={() => onUpdateFilters(true)}
              className={styles.filterSectionActions}
            >
              All
            </span>
            <span className={styles.actionsDivider}>|</span>
            <span
              onClick={() => onUpdateFilters(false)}
              className={styles.filterSectionActions}
            >
              None
            </span>
          </div>
        </div>
        <FormGroup>
          {filters}
        </FormGroup>
      </>
    );
  }
}