import {
    React,
    moment
} from "$Imports/Imports";

import {
    GridApiPro
} from "$Imports/MaterialUIComponents";

  // set the fileName and export .csv file for DataGridPro
export const OnExportCsvClick = (filePrefix: string, gridApiRef: React.MutableRefObject<GridApiPro>): void => {
    const fileName = filePrefix + moment().format('YYYYMMDD');

    gridApiRef.current.exportDataAsCsv({ fileName: fileName });
}