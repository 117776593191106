import * as _ from "lodash";
import { FetchAPI } from "./../generated/api";

import {
  Freezer,
  ILogger,
  DefaultLogger,
  ConsoleLogHandler
} from "$Shared/imports/Yahara";

import {
  IAjaxState,
  IError,
  IErrorMessage,
  IFetcher
} from "$Shared/utilities/managedAjaxUtil";

interface IApiOptions {
  baseUrl: string;
  wrappedFetch: IFetcher | FetchAPI;
}

interface IFetchOptions<D, P extends object, F = unknown, E = IError> {
  freezer: Freezer.Types.IFreezer<F>;
  ajaxStateProperty?: keyof Freezer.Types.IFrozen<F>;
  getAjaxState?: (options: IFetchOptions<D, P, F, E>) => IAjaxState<D, E>;
  setAjaxState?: (options: IFetchOptions<D, P, F, E>, newStatus: IAjaxState<D, E>) => void;
  clearDataOnFetch?: boolean;
  onExecute: (apiOptions: IApiOptions, params: P, options?: any) => Promise<D>;
  onOk?: (data: D) => D | null | void;
  onFetching?: () => void;
  onError?: (err: IErrorMessage<E>, errorString?: string) => void | string | null;
  errorString?: string;
  params?: P;
}

type onBeforeFetchEventType = () => void;
type onAfterErrorEventType<E = any> = ((error: IErrorMessage<E>, errorMessage?: string) => void);

class ApiFetchManager {
  private startingRequestId: number = -1;
  private readonly logger: ILogger = new DefaultLogger("console-logger", new ConsoleLogHandler());

  private readonly _afterOnError: onAfterErrorEventType[] = [];
  private readonly _beforeOnFetch: onBeforeFetchEventType[] = [];

  public readonly apiOptions: IApiOptions = {
    baseUrl: "",
    wrappedFetch: fetch,
  };

  private getStartingRequestId (): number {
    this.startingRequestId += 1;
    return this.startingRequestId;
  };

  public setBaseUrl (baseUrl: string) {
    this.apiOptions.baseUrl = baseUrl === "/" ? "" : baseUrl;
  };

  public setFetch(fetch: IFetcher | FetchAPI) {
    this.apiOptions.wrappedFetch = fetch;
  };

  public createInitialState<T>(startRequestId: number | null = null): IAjaxState<T> {
    const newId = startRequestId === null ? this.getStartingRequestId() : startRequestId;

    return {
      isFetching: false,
      error: null,
      errorMessage: null,
      hasFetched: false,
      requestId: newId,
      data: null,
      state: "initial",
    };
  };

  public clear<T, E = any>(state: IAjaxState<T, E>) {
    state.isFetching = false;
    state.error = null;
    state.errorMessage = null;
    state.hasFetched = false;
    state.data = null;
    state.state = "cleared";
    return state;
  };

  public clearError<T, E = any>(state: IAjaxState<T, E>) {
    state.isFetching = false;
    state.error = null;
    state.errorMessage = null;
    state.state = "cleared";
    return state;
  };

  public addAfterOnError<E = any>(event: onAfterErrorEventType<E>) {
    this._afterOnError.push(event);
  };

  public addBeforeOnFetch(event: onBeforeFetchEventType){
    this._beforeOnFetch.push(event);
  };

  private getAjaxState<D, P extends Record<string, unknown>, F = unknown, E = any>(options: IFetchOptions<D, P, F, E>): IAjaxState<D, E> {
    if (options.ajaxStateProperty) {
      return (options.freezer.get()[options.ajaxStateProperty] as unknown) as IAjaxState<D, E>;
    }

    if (options.getAjaxState) {
      return options.getAjaxState(options);
    }

    throw new Error("ajaxStateProperty or getAjaxState is required.");
  };

  private setAjaxState<D, P extends Record<string, unknown>, F = unknown, E = any>(options: IFetchOptions<D, P, F, E>, newState: IAjaxState<D, E>): void{
    if (
      (options.ajaxStateProperty === undefined || options.ajaxStateProperty === null) &&
      (options.setAjaxState === undefined || options.setAjaxState === null)
    ) {
      throw new Error("ajaxStateProperty or setAjaxState is required.");
    }

    if (options.ajaxStateProperty) {
      ((options.freezer.get()[options.ajaxStateProperty] as unknown) as Freezer.Types.IFrozenObject<IAjaxState<D, E>>).set(newState);
    }

    if (options.setAjaxState) {
      options.setAjaxState(options, newState);
    }
  };

  public async fetchResults <P extends Record<string, unknown>, D, F = any, E = any>(options: IFetchOptions<D, P, F, E>): Promise<void | D> {

    if (options.freezer === null) {
      throw new Error("Freezer is null");
    }

    const initialState = this.getAjaxState(options);
    const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;
    const requestId = (initialState.requestId || 1) + 1;

    this.setAjaxState(options, {
      isFetching: true,
      error: null,
      hasFetched: false,
      requestId,
      data: clearData ? null : initialState.data,
      state: "fetching",
    });

    if (options.onFetching) {
      options.onFetching();
    }

    let params: P = {
    } as P;

    if (options.params !== undefined) {
      params = options.params;
    }

    //Define freezer results we don't want to perform an auth validation for
    const nochecks = ["configResults"];
    if(_.find(nochecks, x => x === options.ajaxStateProperty) === undefined) {
      await Promise.all(_.map(this._beforeOnFetch, async (e) => {
        await e();
      }));
    }

    await options.onExecute(this.apiOptions, params, options)
      .then((data: D) => this.dataFunc(data, options, requestId))
      .catch(async (err: Record<string, unknown>) => this.errorFunc(err, options, requestId));
  };

  private dataFunc<D, P extends Record<string, unknown>, F = any, E = any>(data: D, options: IFetchOptions<D, P, F, E>, requestId: number){

    if (options.freezer === null) {
      throw new Error("Freezer is null");
    }

    const currentState = this.getAjaxState(options);

    if (requestId === currentState.requestId) {

      if (options.onOk) {
      // Massage data
        data = options.onOk(data) || data;
      }

      // Set OK state
      this.setAjaxState(options, {
        isFetching: false,
        error: null,
        hasFetched: true,
        requestId,
        data,
        state: "ok",
      });

    } else {
      this.logger.warn(`Request #${requestId} stale ok result`);
    }

    return data;
  };

  private async errorFunc<D, P extends Record<string, unknown>, F = any, E = any>(err: any, options: IFetchOptions<D, P, F, E>, requestId: number) {
    if (options.freezer === null) {
      throw new Error("Freezer is null");
    }

    const currentState = this.getAjaxState(options);
    const clearData: boolean = options.clearDataOnFetch === null || options.clearDataOnFetch === undefined ? false : options.clearDataOnFetch;

    this.logger.error(`Error has occurred in request #${requestId} for state property ${options.ajaxStateProperty}`);

    if (requestId === currentState.requestId) {

      let errorString = options.errorString;

      let error: E | null = null;

      try {
        error = await err.json() as E;
      }
      catch {
        error = null;
      }

      const errorMessage: IErrorMessage<E> = {
        statusCode: err.status,
        statusText: err.statusText,
        body: error,
      };

      if (options.onError) {
        const onErrorResults = options.onError(errorMessage, errorString);
        errorString = onErrorResults || errorString;
      }

      // Raise the event of after error.
      _.forEach(this._afterOnError, (aoe) => aoe(errorMessage, errorString));

      this.setAjaxState(options, {
        isFetching: false,
        error: err.body,
        errorMessage: errorString || "A server error has occurred.",
        hasFetched: false,
        requestId,
        data: clearData ? null : currentState.data,
        state: "error",
      });

    } else {
      this.logger.warn(`Request #${requestId} stale error result`);
    }

    throw err;
  };
}

export {
  IAjaxState
};

export const managedAjaxUtil = new ApiFetchManager();
