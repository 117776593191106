import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  managedAjaxUtil,
  _
} from "$Imports/Imports";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

type wrapFetchWithAuthType = ((input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>)

export const wrapFetchWithAuth = (): wrapFetchWithAuthType => {
  // User to flag of the existing the fetch function has be wrapped to include
  // the authentication token.
  const WRAP_FETCH_FLAG: string = "__fetch_is_wrapped_for_windows_auth__";
  if (!(WRAP_FETCH_FLAG in window)) {

    // Store a local instance of the current fetch function.
    let oldFetch = window.fetch;

    // for Edge, bind the old fetch to the window object to avoid "Invalid Calling Object"
    // errors.
    oldFetch = oldFetch.bind(window);

    // Create a new fetch function wrapping the old function.
    // The new function will include the JWT token within the header.
    const newFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
      // don't add extra headers on fetches to openid endpoints
      // oidc-client-ts handles it
      if (input?.toString()?.includes("/openid-connect/")) {
        return oldFetch(input, init);
      }

      const config: RequestInit = init || {};
      config.headers = config.headers || {};
      config.credentials = "include";

      const currentUser = SharedSecurityContext.getUser();


      if (currentUser !== null && config.headers) {
        const header = new Headers(config.headers);

        header.append("Authorization", `Bearer ${currentUser.access_token}`);

        config.headers = header;
      }
      SitePubSubManager.publish("application:fetch", "");

      const response = await oldFetch(input, config);

      return response;
    };

    window.fetch = newFetch;
  }

  (window as any)[WRAP_FETCH_FLAG] = true;

  return window.fetch;
}

// Get the base url from the base element.
export const getBaseUrl = (): {
  href: string;
  dataBaseApi: string;
} => {
  const baseElement = document.getElementsByTagName("base")[0];

  return {
    href: baseElement.href ?? "",
    dataBaseApi: baseElement?.getAttribute("data-api-base") ?? ""
  }
}

// Setup the base url for the ajax manager
export const setupBaseUrl = (fetch: wrapFetchWithAuthType): string => {
  const baseUrl = getBaseUrl();

  managedAjaxUtil.setFetch(fetch);
  managedAjaxUtil.setBaseUrl(_.trimEnd("\/", baseUrl.href));

  return baseUrl.href;
}