import {
  React,
  bind
} from "$Imports/Imports";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  FirstPage,
  LastPage,
  SkipNext,
  SkipPrevious
} from "$Imports/MaterialUIIcons";

const styles: {
  wrapper: string;
  pageCount: string;
} = require("./PagingControls.scss");

interface IPagingControlsProps {
  rowCount: number;
  pageSize: number;
  currentPage: number;
  setPage: (pageNum: number) => void;
}

export class PagingControls extends React.PureComponent<IPagingControlsProps> {
  @bind
  private _firstPage() {
    this.props.setPage(1);
  }

  @bind
  private _prevPage() {
    const {
      currentPage
    } = this.props;

    this.props.setPage(currentPage - 1);
  }

  @bind
  private _nextPage() {
    const {
      currentPage
    } = this.props;

    this.props.setPage(currentPage + 1);
  }

  @bind
  private _lastPage() {
    const {
      rowCount,
      pageSize
    } = this.props;

    var pageCount = Math.ceil(rowCount / pageSize);
    this.props.setPage(pageCount);
  }

  render() {
    const {
      rowCount,
      pageSize,
      currentPage
    } = this.props;

    var pageCount = Math.ceil(rowCount / pageSize);
    var hasNext = currentPage < pageCount;
    var hasPrev = currentPage > 1;

    return (
      <div className={styles.wrapper}>
        <div className={styles.pageCount}>
          Page {currentPage} of {pageCount}
        </div>
        <IconButton
          disabled={!hasPrev}
          onClick={this._firstPage}
        >
          <FirstPage />
        </IconButton>
        <IconButton
          disabled={!hasPrev}
          onClick={this._prevPage}
        >
          <SkipPrevious />
        </IconButton>
        <IconButton
          disabled={!hasNext}
          onClick={this._nextPage}
        >
          <SkipNext />
        </IconButton>
        <IconButton
          disabled={!hasNext}
          onClick={this._lastPage}
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }
}