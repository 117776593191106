import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

import {
  defaultTheme
} from "./defaultTheme";

export const transportBlue = "#0071EB";
export const highlightBlue = "#DDEDFF";

const blueThemeDefinition = {
  themeOptions: {
    palette: {
      secondary: {
        main: transportBlue,
      }
    },
    overrides: {
      MuiTableCell: {
        head: {
          color: transportBlue
        }
      }
    }
  },
  sideNavigationMenu: {
    root: {
      "&.Mui-selected": {
        color: transportBlue,
      }
    }
  },
  appBarNavigationMenu: {
    root: {
      "&.Mui-selected": {
        color: transportBlue,
      }
    }
  }
};

// _.merge mutates the base object so you have to give it an empty object
export const blueTheme: IThemeConfiguration = _.merge({}, defaultTheme, blueThemeDefinition);
