import {
  ThemeProvider,
  createTheme,
  Theme,
  ThemeOptions,
  StyledEngineProvider
} from "@mui/material/styles"

import {
  SvgIconProps
} from "@mui/material/SvgIcon";

type ButtonColorThemeType = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
type ButtonVariantType = 'text' | 'contained' | "outlined";
type DialogMaxWidthType = 'xs' | 'sm' | 'lg';

export {
  ThemeProvider,
  createTheme,
  Theme,
  ThemeOptions,
  StyledEngineProvider,

  SvgIconProps,

  ButtonColorThemeType,
  ButtonVariantType,
  DialogMaxWidthType
};
