import {
  React,
  useEffect
} from "$Imports/Imports";

import {
  KeyboardDatePicker,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

const styles: {
  container: string;
  horizontal: string;
  dateInput: string;
} = require("./DateRangePicker.scss");

interface IDateRangePickerProps {
  startDate?: Date | null;
  startError?: string | null;
  endDate?: Date | null;
  endError?: string | null;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  disableFuture?: boolean;
  horizontal?: boolean;
}

interface IDateRangePickerState {
  startDate: Date | null;
  endDate: Date | null;
}

type DateType = "start" | "end";

export const DateRangePicker: React.FunctionComponent<IDateRangePickerProps> = (props: IDateRangePickerProps) => {
  const [state, setState] = React.useState<IDateRangePickerState>({
    startDate: props.startDate ?? null,
    endDate: props.endDate ?? null,
  });

  useEffect(() => {
    setState({
      startDate: props.startDate ?? null,
      endDate: props.endDate ?? null,
    });
  }, [props.startDate, props.endDate]);

  const onDateChange = (date: Date | null, dateType: DateType) => {
    let { startDate, endDate } = (state as IDateRangePickerState);

    if (dateType === "start") {
      startDate = date;
    } else {
      endDate = date;
    }

    setState({
      startDate: startDate,
      endDate: endDate,
    })
    props.onChange(startDate, endDate);
  }

  const containerStyle = `${styles.container} ${(props.horizontal ? styles.horizontal : "")}`;
  const { startError, endError } = props;

  return (
    <div className={containerStyle}>
      <KeyboardDatePicker
        className={styles.dateInput}
        label={"From"}
        value={state.startDate}
        onChange={(date: Date | null) => onDateChange(date, "start")}
        inputFormat="MM/DD/YYYY"
        renderInput={(props: TextFieldProps) => (
          <TextField
            {...props}
            error={!!startError}
            helperText={startError}
          />
        )}
        disableFuture={props.disableFuture ?? false}
      />
      <KeyboardDatePicker
        className={styles.dateInput}
        label={"To"}
        value={state.endDate}
        onChange={(date: Date | null) => onDateChange(date, "end")}
        inputFormat="MM/DD/YYYY"
        renderInput={(props: TextFieldProps) => (
          <TextField
            {...props}
            error={!!endError}
            helperText={endError}
          />
        )}
        disableFuture={props.disableFuture ?? false}
      />
    </div>
  );
}
