import {
  React,
  bind
} from "$Imports/Imports";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

import {
  AboutVM
} from "$Generated/api";

const styles: {
  versionTable: string
} = require("./AboutModal.scss");

interface IAboutModalProps {
  isOpen: boolean;
  aboutVM: AboutVM | null | undefined;
  onClose: () => void;
}

export class AboutModal extends React.PureComponent<IAboutModalProps> {

  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      aboutVM
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          About
        </DialogTitle>
        <DialogContent>
          <table className={styles.versionTable}>
            <tbody>
              <tr>
                <td>Product Name</td>
                <td>{aboutVM?.productName ?? ""}</td>
              </tr>
              <tr>
                <td>Product Version</td>
                <td>{aboutVM?.productVersion ?? ""}</td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}