import {
    FreezerService,
    bind
} from "$Imports/Imports";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

const InjectedPropName = "errorService";

type ErrorPriority = "high" | "normal" | "successful";

interface ErrorMessage {
  message: string;
  priority: ErrorPriority;
  actionName?: string;
  action?: () => void;
}

interface IErrorFreezerState {
    errorList: ErrorMessage[];
}

const initialState = {
  errorList: []
} as IErrorFreezerState;

class ErrorFreezerService extends FreezerService<IErrorFreezerState, typeof InjectedPropName> {
    constructor() {
        super(initialState, InjectedPropName);

        SitePubSubManager.subscribe("application:logout", this.clearFreezer);
    }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

    public pushErrorMessage(message: string, priority: ErrorPriority = "normal", actionName?: string, action?: () => void) {
        this.freezer.get().errorList.unshift({
          message,
          priority,
          actionName,
          action
        });
    }

    public hasErrorMessages(): boolean {
        return this.freezer.get().errorList.length !== 0;
    }

    public popLastItem(): ErrorMessage | undefined {
        const lengthOfList = this.freezer.get().errorList.length;
        let message: ErrorMessage | undefined;

        if (lengthOfList !== 0) {
            message = this.freezer.get().errorList[lengthOfList - 1].toJS();
            this.freezer.get().errorList.pop();
        }

        return message;
    }
}

export const ErrorService = new ErrorFreezerService();
export type IErrorServiceInjectedProps = ReturnType<ErrorFreezerService["getPropsForInjection"]>;
