import {
  React,
  _
} from "$Imports/Imports";

import {
  CompanyLegend
} from "$Imports/CommonComponents";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from "$Imports/Recharts";

import {
  Opportunity
} from "$Generated/api";

import {
  logisticsGreen,
  transportBlue
} from "$Themes/index";

import {
  IGraphData
} from "./SalesOpportunitiesView";

import {
  SalesOpportunitiesTooltip
} from "./SalesOpportunitiesTooltip";

interface IGraphLeadSourceProps {
  graphData: Opportunity[]
}

export class GraphLeadSource extends React.Component<IGraphLeadSourceProps> {
  render() {
    const { graphData } = this.props;

    let bar: IGraphData[] = [];
    _.forEach(_.groupBy(graphData, g => g.leadSource?.linkId), (grp) => {
      const companyCount = _.countBy(grp, g => g.company?.companyKey);
      // put a space in for recharts
      const lsName = grp[0].leadSource?.linkName === "Local/Equipment" ? "Local/ Equipment" : grp[0].leadSource?.linkName;
      bar.push({
        id: grp[0].leadSource?.linkId,
        name: lsName,
        KT: companyCount["KT"],
        KL: companyCount["KL"]
      });
    });

    bar = _.orderBy(bar, (b) => (b.KT ?? 0) + (b.KL ?? 0), "desc");

    return (
      <ResponsiveContainer height={500} width="99%">
        <BarChart
          data={bar}
          layout="vertical"
        >
          <XAxis
            type="number"
            xAxisId={1}
            allowDecimals={false}
          />
          <YAxis
            dataKey="name"
            type="category"
            yAxisId={1}
            width={90}
          />
          <CartesianGrid horizontal={false} />
          <Bar
            dataKey="KL"
            stackId="salesRep"
            fill={logisticsGreen}
            xAxisId={1}
            yAxisId={1}
          >
            <LabelList
              dataKey="KL"
              position="insideRight"
              fill="#ffffff"
            />
          </Bar>
          <Bar
            dataKey="KT"
            stackId="salesRep"
            fill={transportBlue}
            xAxisId={1}
            yAxisId={1}
          >
            <LabelList
              dataKey="KT"
              position="insideRight"
              fill="#ffffff"
            />
          </Bar>
          <Tooltip
            cursor={{ stroke: "#5a5c63", strokeWidth: "1", fillOpacity: "0" }}
            content={(props) => {
              return (
                <SalesOpportunitiesTooltip
                  payload={props.payload?.[0]?.payload}
                  isActive={props.active}
                />
              );
            }}
          />
          <Legend content={(props) => (<CompanyLegend />)} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}