import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  CompanyApiFactory,
  Company
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface ICompanyFreezerServiceState {
  companyFetchResults: IAjaxState<Company[]>;
}

const InjectedPropName = "companyService";

const initialState = {
  companyFetchResults: managedAjaxUtil.createInitialState()
} as ICompanyFreezerServiceState;

class CompanyFreezerService extends FreezerService<ICompanyFreezerServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchData() {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "companyFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CompanyApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CompanyGet(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch companies.");
      }
    });
  }
}

export const CompanyService = new CompanyFreezerService();
export type ICompanyServiceInjectedProps = ReturnType<CompanyFreezerService["getPropsForInjection"]>;
