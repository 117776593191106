import {
  yup,
  ValidationError,
  ValidateOptions
} from "../imports/Yup";

import {
  SharedSecurityContext
} from "./Security/ApplicationSecuritySettings";

export async function validateSchema<T>(schema: yup.BaseSchema<T>, criteria: T, options?: ValidateOptions): Promise<ValidationError | null> {
  try {
    await schema.validate(
      criteria,
      options ?? { abortEarly: false }
    );
  }
  catch (ex) {
    if (ex instanceof ValidationError) {
      if (SharedSecurityContext.hasRole(["yahara:dev"])) {
        console.log(ex.inner);
      }

      return ex;
    }
    else {
      throw ex;
    }
  }

  return null;
}
