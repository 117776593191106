import {
  React
} from "$Imports/Imports";

import {
  LaneRevenueView
} from "./LaneRevenueView";

interface IOwnProps { }

export class LaneRevenueViewPage extends React.Component<IOwnProps> {
  render() {
    return (
      <LaneRevenueView />
    );
  }
}