import {
  React,
  bind
} from "$Imports/Imports";

import {
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  BookedSalesFilterSection
} from "./BookedSalesFilterSection";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

interface IBookedSalesFilterBaseProps {

}

type IBookedSalesFilterProps = IBookedSalesFilterBaseProps
  & IReportServiceInjectedProps
  ;

class _BookedSalesFilters extends React.Component<IBookedSalesFilterProps> {

  @bind
  private _mapCompanyFilters(): JSX.Element | undefined {
    const {
      bookedSalesFiltersFetchResults
    } = this.props.reportService.getState();

    const filterData = bookedSalesFiltersFetchResults.data;

    if (!filterData) {
      return undefined;
    }

    return <>{filterData.companyInfos?.map((c, idx) => {
      if (c.isEnabled) {
        return (
          <FormControlLabel
            label={c.name || ""}
            key={idx}
            control={<Checkbox
              checked={c.isChecked || false}
              size="small"
              onChange={!c.isEnabled ? undefined : (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onCompanyCheckChange(c.id, checked)}
              disabled={!c.isEnabled}
            />}
          />
        )
      }
    })}</>;
  }

  @bind
  private _mapTerminalFilters(): JSX.Element | undefined {
    const {
      bookedSalesFiltersFetchResults
    } = this.props.reportService.getState();

    const filterData = bookedSalesFiltersFetchResults.data;

    if (!filterData) {
      return undefined;
    }

    return <>{filterData.terminals?.map((t, idx) => {
      if (t.isEnabled) {
        return (
          <FormControlLabel
            label={t.desc || ""}
            key={idx}
            control={<Checkbox
              checked={t.isChecked || false}
              size="small"
              onChange={!t.isEnabled ? undefined : (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onTerminalCheckChange(t.id, checked)}
              disabled={!t.isEnabled}
            />}
          />
        )
      }
      })}</>;
  }

  @bind
  private _mapSalesRepFilters(): JSX.Element | undefined {
    const {
      bookedSalesFiltersFetchResults
    } = this.props.reportService.getState();

    const filterData = bookedSalesFiltersFetchResults.data;

    if (!filterData) {
      return undefined;
    }

    return <>{filterData.salesAgents?.map((s, idx) => {
      if (s.isEnabled) {
        return (
          <FormControlLabel
            label={s.salesAgent || ""}
            key={idx}
            control={<Checkbox
              checked={s.isChecked || false}
              size="small"
              onChange={!s.isEnabled ? undefined : (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onSalesRepCheckChange(s.salesAgent, checked)}
              disabled={!s.isEnabled}
            />}
          />
        )
      }
      })}</>;
  }

  @bind
  private _onCompanyCheckChange(companyId: number | undefined, checked: boolean) {
    if (companyId) {
      this.props.reportService.onCompanyFilterChange(companyId, checked);
    }
  }

  @bind
  private _onTerminalCheckChange(terminalId: number | undefined, checked: boolean) {
    if (terminalId) {
      this.props.reportService.onTerminalFilterChange(terminalId, checked);
    }
  }

  @bind
  private _onSalesRepCheckChange(salesAgent: string | undefined, checked: boolean) {
    if (salesAgent) {
      this.props.reportService.onSalesRepFilterChange(salesAgent, checked);
    }
  }

  @bind
  private _updateCheckboxFilterSection(filterType: string, shouldReset: boolean) {
    this.props.reportService.updateCheckboxFilterSection(filterType, shouldReset);
  }

  render() {
    const companyFilters = this._mapCompanyFilters();
    const terminalFilters = this._mapTerminalFilters();
    const salesRepFilters = this._mapSalesRepFilters();

    return (
      <>
        <BookedSalesFilterSection
          title="Company"
          filters={companyFilters}
          onUpdateFilters={(shouldReset) => this._updateCheckboxFilterSection("companyInfos", shouldReset)}
        />
        <BookedSalesFilterSection
          title="Terminal"
          filters={terminalFilters}
          onUpdateFilters={(shouldReset) => this._updateCheckboxFilterSection("terminals", shouldReset)}
        />
        <BookedSalesFilterSection
          title="Sales Rep"
          filters={salesRepFilters}
          onUpdateFilters={(shouldReset) => this._updateCheckboxFilterSection("salesAgents", shouldReset)}
        />
      </>
    );
  }
}

export const BookedSalesFilters = ReportService.inject(
  _BookedSalesFilters
);