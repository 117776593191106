import * as React from "react";
import * as ReactDOM from "react-dom";
import { renderToStaticMarkup } from "react-dom/server";

import { bind } from "bind-decorator";

import Freezer = require("freezer-js");
import * as numeral from "numeral";
import * as _ from "lodash";
import * as moment from "moment";

import
  NumberFormat, {
  NumberFormatProps,
  NumberFormatValues
} from "react-number-format";

import {
  User,
  UserManager,
  WebStorageStateStore
} from "oidc-client-ts";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { withRouter } from "react-router";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  Prompt
} from "react-router-dom";

import {
  AuthProvider,
  AuthContextProps,
  withAuth,
  useAuth,
  hasAuthParams
} from "react-oidc-context";

import {
  LicenseInfo
} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "cfcf3208643f7a79ea4554bccc0be38eT1JERVI6NDAyMjgsRVhQSVJZPTE2Nzk1OTcwMTMwMDAsS0VZVkVSU0lPTj0x"
);

export {
  // base + react
  React,
  ReactDOM,
  bind,
  _,
  moment,
  numeral,
  renderToStaticMarkup,
  Freezer,
  
  // oidc-client-ts
  User,
  UserManager,
  WebStorageStateStore,

  // react-number-format
  NumberFormat,
  NumberFormatProps,
  NumberFormatValues,

  // react
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,

  // react-oidc-context
  AuthProvider,
  AuthContextProps,
  withAuth,
  useAuth,
  hasAuthParams,

  // react-router
  withRouter,

  // react-router-dom
  Router,
  Route,
  Link,
  Redirect,
  Switch,
  Prompt
};
