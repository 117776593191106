export function openCustomerQuotePreview(quoteId: number): Window | null {
  const newWindow = window.open(`/customerQuote/${quoteId}`, "_blank", "width=1000,height=800");
  if (newWindow) {
    newWindow.opener = null;
  }

  return newWindow;
}

export function openTabForUrl(url: string) {
  window.open(url, "_blank")?.focus();
}

export function navigateToSalesPortal(url: string) {
  window.location.href = url;
}