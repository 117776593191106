import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

import {
  defaultTheme
} from "./defaultTheme";

export const logisticsGreen = "#008A00";
export const highlightGreen = "#E5FFE5";

const greenThemeDefinition = {
  themeOptions: {
    palette: {
      secondary: {
        main: logisticsGreen,
      }
    },
    overrides: {
      MuiTableCell: {
        head: {
          color: logisticsGreen
        }
      }
    }
  },
  sideNavigationMenu: {
    root: {
      "&.Mui-selected": {
        color: logisticsGreen,
      }
    }
  },
  appBarNavigationMenu: {
    root: {
      "&.Mui-selected": {
        color: logisticsGreen,
      }
    }
  }
};

// _.merge mutates the base object so you have to give it an empty object
export const greenTheme: IThemeConfiguration = _.merge({}, defaultTheme, greenThemeDefinition);
