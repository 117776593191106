import { 
    AdvanceTextField, 
    CardLinedHeader 
} from "$Components/common";
import { 
    React 
} from "$Imports/Imports";
import { 
    Button 
} from "$Imports/MaterialUIComponents";
import { 
    CapacityReportService, 
    ICapacityReportServiceInjectedProps 
} from "$State/CapacityReportFreezerService";

const styles: {
    summaryContent: string;
    summaryValue: string;
    sectionColumn: string;
} = require("./FillFreightCard.scss");

interface IFillFreightCardBaseProps {
}

interface IFillFreightCardState {
}

type IFillFreightCardProps = IFillFreightCardBaseProps & ICapacityReportServiceInjectedProps;

export class _FillFreightCard extends React.Component<IFillFreightCardProps, IFillFreightCardState> {
    constructor(props: IFillFreightCardProps) {
        super(props);
    }

    private oppurtunitiesOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const regexp = /^\d+$/;
        if (regexp.test(e.target.value) && Number(e.target.value) >= 1) {
            this.props.capacityReportService.updateFillFreightState({
                oppurtunities: +e.target.value
            });
        } else {
            this.props.capacityReportService.updateFillFreightState({
                oppurtunities: undefined
            });
        }
    }

    private conversionsOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const regexp = /^\d+$/;
        if (regexp.test(e.target.value) && Number(e.target.value) >= 1) {
            this.props.capacityReportService.updateFillFreightState({
                conversions: +e.target.value
            });
        } else {
            this.props.capacityReportService.updateFillFreightState({
                conversions: undefined
            });
        }
    }

    private buttonOnClick = () => {
        this.props.capacityReportService.calculateFillFreight();
    }

    public render(): JSX.Element {
        const { 
            oppurtunities, 
            conversions, 
            conversionRate
        } = this.props.capacityReportService.getState().fillFreightState;

        return (
            <>
                <CardLinedHeader titleText="Fill Freight">
                    <div className={styles.sectionColumn}>
                        <AdvanceTextField 
                            label={"Oppurtunities"}
                            name={"oppurtunities"}
                            type="number"
                            InputProps={{
                                inputProps: { min: 1}
                            }}
                            value={oppurtunities}
                            onChange={this.oppurtunitiesOnChange}
                        />
                        <AdvanceTextField
                            label={"Conversions"}
                            name={"conversions"}
                            type="number"
                            InputProps={{
                                inputProps: { min: 0}
                            }}
                            value={conversions}
                            onChange={this.conversionsOnChange}
                        />
                        <Button
                            variant="contained"
                            onClick={this.buttonOnClick}
                            disabled={oppurtunities === undefined || conversions === undefined}
                        >
                            Calculate
                        </Button>
                        <CardLinedHeader>
                            <div className={styles.summaryContent}>
                                <div>Conversion Rate</div>
                                <span className={styles.summaryValue}>
                                    { conversionRate === undefined ? "-" : `${conversionRate}%` }
                                </span>
                            </div>
                        </CardLinedHeader>
                    </div>
                </CardLinedHeader>
            </>
        );
    }
}

export const FillFreightCard = CapacityReportService.inject(
    _FillFreightCard
);