import {
  React
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  AboutService,
  IAboutServiceInjectedProps
} from "$State/AboutFreezerService";

import {
  navigateToSalesPortal,
  openTabForUrl
} from "$Utilities/windowUtil";

interface ICustomerLinkBaseProps {
  customerId?: number;
  customerName?: string;
  newWindow?: boolean;
}

type ICustomerLinkProps = ICustomerLinkBaseProps
  & IAboutServiceInjectedProps;

class _CustomerLink extends React.PureComponent<ICustomerLinkProps> {
  private _navigateTo(event: React.MouseEvent, url: string): void {
    this.props.newWindow ? openTabForUrl(url) : navigateToSalesPortal(url);

    // router performs navigation - prevent "href" from navigating + reloading app
    event.preventDefault();
  }

  render() {
    const {
      customerId,
      customerName
    } = this.props;

    const url = `/customer/${customerId}`;
    
    const salesPortalUrl = this.props.aboutService.getState().aboutFetchResults?.data?.salesPortalUrl ?? "";
    const combinedUrl = salesPortalUrl + url;

    return (
      <Link
        href={combinedUrl}
        color="secondary"
        onClick={(event) => this._navigateTo(event, combinedUrl)}
      >
        {customerName}
      </Link>
    );
  }
}

export const CustomerLink = AboutService.inject(_CustomerLink);