import {
  React
} from "$Imports/Imports";

import {
  QuoteReviewsView
} from "./QuoteReviewsView";

interface IQuoteReviewsViewPageProps { }

export class QuoteReviewsViewPage extends React.Component<IQuoteReviewsViewPageProps> {
  render() {
    return (
      <QuoteReviewsView />
    )
  }
}