import { 
  _,
  User,
  UserManager,
  WebStorageStateStore
} from '$Imports/Imports';

interface IAuthSettings {
  url: string,
  authority: string,
  client_id: string,
  baseUrl: string,
}

// Global Settings and User Manager

class ApplicationSecuritySettings {
  private gAuthSettings: IAuthSettings | undefined = undefined;
  private gUserManager: UserManager | undefined = undefined;

  private logoutInProgress: boolean = false;

  public setAuthSettings(settings: IAuthSettings) {
    if (this.gUserManager !== undefined) {
      throw new Error("User Manager already created. Unable to change authentication settings."); 
    }
  
    this.gAuthSettings = settings;
  }  
  
  public getUserManager(): UserManager {
    if ( this.gAuthSettings === undefined) {
      throw new Error("Missing authentication settings.");    
    }
  
    if ( this.gUserManager === undefined) {
      this.gUserManager = new UserManager({
        client_id: this.gAuthSettings.client_id,
        redirect_uri: this.gAuthSettings.baseUrl,
        authority: this.getAuthUrl(),
        accessTokenExpiringNotificationTimeInSeconds: 60,        
        post_logout_redirect_uri: this.gAuthSettings.baseUrl,
        // (default) sessionStorage prevents "open in new tab/window" from using existing user session
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        // get extended claims (like roles) from userinfo endpoint to reduce size of id token
        loadUserInfo: true     
      });
    }

    return this.gUserManager as UserManager;  
  }

  public async logout() {
    const userManager = this.getUserManager();
    const idToken = this.getUser()?.id_token;

    this.logoutInProgress = true;

    await userManager.removeUser();
    await userManager.signoutRedirect({
      id_token_hint: idToken
    });
  }

  public getAuthUrl() {
    return `${this.gAuthSettings?.url}realms/${this.gAuthSettings?.authority}`
  }

  public getUser(): User | null {    
    const oidcStorage = localStorage.getItem(`oidc.user:${this.getAuthUrl()}:${this.gAuthSettings?.client_id}`);
    if (!oidcStorage) {
        return null;
    }
  
    return User.fromStorageString(oidcStorage);
  }

  public hasRole(roles: string[]): boolean {

    const user = this.getUser();

    if(user === null) {
      false;
    }

    const roleValues: Array<string> = this.getRoles(user);

    if (roleValues) {
      var overlap = _.intersection(roles, roleValues);
      return overlap.length > 0;
    }

    return false;
  }

  public getIsLogoutInProgress(): boolean {
    return this.logoutInProgress;
  }

  public getUserId(): string | undefined {
    const user = this.getUser();

    return user?.profile?.sub as string | undefined;
  }
  
  public getRoles(user: User | null): string[] {
    if (!user) {
      user = this.getUser();
    }
    return _.castArray(user?.profile?.roles) as [] ?? [];
  }
}

// Create a singleton for securiry settings.
export const SharedSecurityContext = new ApplicationSecuritySettings();