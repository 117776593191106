import {
  React,
  bind,
  _
} from "$Imports/Imports";

import { 
  ApplicationSettingsCard 
} from "$Pages/ApplicationSettingsView/ApplicationSettingsCard";

import {
  ApplicationSettingsService,
  IApplicationSettingsServiceInjectedProps
} from "$State/ApplicationSettingsFreezerService";
 
interface ICapacityReportConfigViewPageBaseProps {

}

type ICapacityReportConfigViewPage = ICapacityReportConfigViewPageBaseProps
  & IApplicationSettingsServiceInjectedProps;

export class _CapacityReportConfigViewPage extends React.Component<ICapacityReportConfigViewPage> {

  async componentDidMount() {
    await this.props.ApplicationSettingsService.fetchCapacityReportSettings(true);
  }

  @bind
  private _onLoadSetting(forceUpdate: boolean = false) {
    this.props.ApplicationSettingsService.fetchCapacityReportSettings(forceUpdate);
  }

  render() {
    return (
      <ApplicationSettingsCard title={"Capacity Report Configuration"} onLoadSettings={this._onLoadSetting}/>
    );
  }
}

export const CapacityReportConfigViewPage = ApplicationSettingsService.inject(
  _CapacityReportConfigViewPage
);