
import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  IDataTableColumn,
  DataTable,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import { 
  ApplicationSetting 
} from "$Generated/api";

import {
  ApplicationSettingsService,
  IApplicationSettingsServiceInjectedProps
} from "$State/ApplicationSettingsFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  NumberSettingEditModal
} from "./ApplicationSettingsModals/NumberSettingEditModal";

import { 
  CardLinedHeader 
} from "$Components/common";

const styles: {
  mainContainer: string,
  mainCard: string
} = require("./../CapacityReport/CapacityReportPage.scss");
 
interface IApplicationSettingsCardBaseProps {
  title: string;
  onLoadSettings: (forceUpdate: boolean) => void;
}

type IApplicationSettingsCard = IApplicationSettingsCardBaseProps
  & IApplicationSettingsServiceInjectedProps;

class _ApplicationSettingsCard extends React.Component<IApplicationSettingsCard> {
  
  @bind
  private _onSettingEditClick(applicationSetting: ApplicationSetting) {
    this.props.ApplicationSettingsService.openApplicationSettingEditModal(applicationSetting);
  }

  @bind
  private _onNumberSettingChange(applicationSetting: Partial<ApplicationSetting>) {
    this.props.ApplicationSettingsService.updateNumberSetting(applicationSetting);
  }

  @bind
  private async _onSaveNumberSetting() {
    await this.props.ApplicationSettingsService.saveNumberSetting();
    this.props.onLoadSettings(true);
  }

  @bind 
  private _translateSettingsKey(settingKey?: string): string {
    switch(settingKey) {
      case("DefaultMarket"):
        return "Default Market";
      case("FilteredSites"):
        return "Filtered Sites";
      case("SendFuelSurchargeCharge"):
        return "Send Fuel Surcharge Charge";
      case("DebugDownloadEnabled"):
        return "Debug Download Enabled?";
      case("CapacityReportJvlCostPerMile"):
        return "JVL Cost Per Mile";
      case("CapacityReportSfsCostPerMile"):
        return "SFS Cost Per Mile";
      default: 
        return "";
    }
  }
  private readonly currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  private readonly columns: Array<IDataTableColumn<ApplicationSetting>> = [
    {
      columnName: "applicationSetting",
      columnFieldData: (d) => this._translateSettingsKey(d.settingsKey),
      headerValue: "Report Setting"
    },
    {
      columnName: "settingValue",
      columnFieldData: (d) => {
        if (d.settingsKey === "CapacityReportJvlCostPerMile" || d.settingsKey === "CapacityReportSfsCostPerMile") {
          return this.currencyFormatter.format((d.settingsValue ? +d.settingsValue : 0));
        }
        return d.settingsValue;
      },
      headerValue: "Setting Value"
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu 
          setting={d}
          onSettingEditClick={this._onSettingEditClick}
        />
      ),
      headerValue: ""
    }
  ]

  render() {
    const {
      title
    } = this.props;

    const {
      applicationSettingsFetchResults,
      updateApplicationSettingResults,
      isNumberSettingModalOpen,
      editNumberSetting
    } = this.props.ApplicationSettingsService.getState();

    const applicationSettings = applicationSettingsFetchResults.data ?? [];

    return (
      <div className={styles.mainContainer}>
        <CardLinedHeader className={styles.mainCard} titleText={title}>
          {/* <CardHeader /> */}
          <AjaxActionIndicator state={[applicationSettingsFetchResults, updateApplicationSettingResults]} />
          <DataTable
            columns={this.columns}
            data={applicationSettings}
          />
          <NumberSettingEditModal 
            isModalOpen={isNumberSettingModalOpen}
            title={this._translateSettingsKey(editNumberSetting?.settingsKey)}
            value={editNumberSetting?.settingsValue}
            onSaveSetting={this._onSaveNumberSetting}
            onChangeSetting={this._onNumberSettingChange}
          />
        </CardLinedHeader>
      </div>
    )
  }

}

export const ApplicationSettingsCard = ApplicationSettingsService.inject(_ApplicationSettingsCard);