import {
  React,
  bind,
  moment,
  _,
  numeral
} from "$Imports/Imports";

import {
  CURRENCY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT,
  NUMERIC_SEPARATED_FORMAT,
  NUMERIC_DECIMAL_SEPARATED_FORMAT as NUMERIC_ONE_DECIMAL_SEPARATED_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  OnExportCsvClick
} from "$Utilities/functionUtil";

import {
  BookedSalesFilters
} from "./BookedSalesFilters";

import {
  Button,
  DataGridPro,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  Stack,
  Tooltip,
  GridApiPro
} from "$Imports/MaterialUIComponents";

import {
  Download,
  Warning
} from "$Imports/MaterialUIIcons";

import {
  DisplayFormattedNumber,
  CardLinedHeader,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  BookedSalesReportView,
  TlorderSalesAgent,
  TmwinCompanyInfo,
  TmwinTerminal
} from "$Generated/api";

import {
  ReportService,
  IReportServiceInjectedProps
} from "$State/ReportFreezerService";

import {
  BookedSalesDateRangePicker
} from "./BookedSalesDateRangePicker";

import {
  GraphSales
} from "./GraphSales";

import {
  GraphWeight
} from "./GraphWeight";

import {
  GraphFeet
} from "./GraphFeet";

const styles: {
  mainContainer: string,
  filterContainer: string,
  bookedSalesContainer: string,
  headerRow: string,
  totalRow: string
} = require("./BookedSalesView.scss");

interface IBookedSalesViewState { }

interface IBookedSalesViewBaseProps { }

type IBookedSalesViewProps = IBookedSalesViewBaseProps
  & IReportServiceInjectedProps;

class _BookedSalesView extends React.Component<IBookedSalesViewProps, IBookedSalesViewState> {

  private readonly gridColumns: GridColDef[] = [
    {
      headerName: "Company",
      field: "companyName",
      flex: 2,
    },
    {
      headerName: "Terminal",
      field: "terminalName",
      valueGetter: (params: GridValueGetterParams<string | undefined>) => params.value ?? "",
    },
    {
      headerName: "Sales Rep",
      field: "username",
      valueGetter: (params: GridValueGetterParams<string | undefined>) => params.value ?? "",
      flex: 2
    },
    {
      headerName: "Sales",
      field: "charges",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "";
      },
    },
    {
      headerName: "Weight",
      field: "totalWeight",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(NUMERIC_SEPARATED_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return !!params.value ? <DisplayFormattedNumber value={params.value} formatString={NUMERIC_SEPARATED_FORMAT} /> : "";
      },
    },
    {
      headerName: "Rate/K",
      field: "rk",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_NO_DECIMAL_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined, BookedSalesReportView>) => {
        return params.row.companyName === "Totals" ? "" : !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_NO_DECIMAL_FORMAT} /> : "";
      },
    },
    {
      headerName: "Rate/K/Mile",
      field: "rkm",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined, BookedSalesReportView>) => {
        return params.row.companyName === "Totals" ? "" : !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "";
      },
    },
    {
      headerName: "Feet",
      renderHeader:	() => {
        return (
          <>
            Feet
            <Tooltip placement="right" title="Feet values do not account for stackable or side-by-side freight. As a result, the length may be overstated for freight bills containing commodities with these characteristics.">
              <span style={{ display: "flex" }}>
                <Warning style={{ marginLeft: "5px" }} />
              </span>
            </Tooltip>
          </>
        );
      },
      field: "totalRollupLength",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(NUMERIC_ONE_DECIMAL_SEPARATED_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return !!params.value ? <DisplayFormattedNumber value={params.value} formatString={NUMERIC_ONE_DECIMAL_SEPARATED_FORMAT} /> : "";
      },
    },
    {
      headerName: "Rate/Ft",
      field: "rf",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_NO_DECIMAL_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined, BookedSalesReportView>) => {
        return params.row.companyName === "Totals" ? "" : !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_NO_DECIMAL_FORMAT} /> : "";
      },
    },
    {
      headerName: "Rate/Ft/Mile",
      field: "rfm",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(CURRENCY_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return !!params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} /> : "";
      },
    },
    {
      headerName: "FB Miles",
      renderHeader:	() => {
        return (
          <>
            FB Miles
            <Tooltip placement="left" title="For multi-stop quotes, mileage values are pulled from the parent freight bill only.">
              <span style={{ display: "flex" }}>
                <Warning style={{ marginLeft: "5px" }} />
              </span>
            </Tooltip>
          </>
        );
      },
      field: "totalDistance",
      valueFormatter: ( params: GridValueFormatterParams<number | undefined> ) => !!params.value ? numeral(params.value).format(NUMERIC_SEPARATED_FORMAT) : "",
      renderCell: (params: GridRenderCellParams<number | undefined, BookedSalesReportView>) => {
        return params.row.companyName === "Totals" ? "" : !!params.value ? <DisplayFormattedNumber value={params.value} formatString={NUMERIC_SEPARATED_FORMAT} /> : "";
      },
    },
    {
      headerName: "FB Count",
      field: "bills",
    }
  ];

  componentDidMount() {
    this.props.reportService.fetchBookedSalesFilters();
  }

  @bind
  private _onDateChange(startDate?: Date, endDate?: Date) {
    this.props.reportService.updateBookedSalesReportFilter({
      startDate: startDate,
      endDate: endDate
    });

    this.props.reportService.fetchBookedSalesReport();
  }

  @bind
  private _getReportTitle(): string {
    const {
      bookedSalesReportFetchResults
    } = this.props.reportService.getState();

    let title = "Booked Sales";

    if (bookedSalesReportFetchResults.data?.fromDate) {
      title = `${title} from ${moment(bookedSalesReportFetchResults.data.fromDate).format("MM/DD/YYYY")}`;
    }
    if (bookedSalesReportFetchResults.data?.toDate) {
      title = `${title} to ${moment(bookedSalesReportFetchResults.data.toDate).format("MM/DD/YYYY")}`;
    }

    return title;
  }

  @bind
  private _filterReportData(): BookedSalesReportView[] {
    const {
      bookedSalesReportFetchResults,
      bookedSalesFiltersFetchResults
    } = this.props.reportService.getState();

    const filterData = bookedSalesFiltersFetchResults.data;

    // filter logic: rows that match ANY checked box in any group but NONE of unchecked boxes
    // is there a more concise way to do this? probably, yes. but I'm aiming for comprehensible.

    let companyFilterChecks: TmwinCompanyInfo[] = [];
    let companyFilterUnchecks: TmwinCompanyInfo[] = [];
    _.forEach(filterData?.companyInfos, (ci) => {
      if (ci.isEnabled && ci.isChecked) {
        companyFilterChecks.push(ci);
      }
      else if (ci.isEnabled && !ci.isChecked) {
        companyFilterUnchecks.push(ci);
      }
    });

    let terminalFilterChecks: TmwinTerminal[] = [];
    let terminalFilterUnchecks: TmwinTerminal[] = [];
    _.forEach(filterData?.terminals, (t) => {
      if (t.isEnabled && t.isChecked) {
        terminalFilterChecks.push(t);
      }
      else if (t.isEnabled && !t.isChecked) {
        terminalFilterUnchecks.push(t);
      }
    });

    let salesRepFilterChecks: TlorderSalesAgent[] = [];
    let salesRepFilterUnchecks: TlorderSalesAgent[] = [];
    _.forEach(filterData?.salesAgents, (s) => {
      if (s.isEnabled && s.isChecked) {
        salesRepFilterChecks.push(s);
      }
      else if (s.isEnabled && !s.isChecked) {
        salesRepFilterUnchecks.push(s);
      }
    })

    let bookedSalesData = bookedSalesReportFetchResults.data?.reportData ?? [];
    bookedSalesData = _.filter(bookedSalesData, (reportRow) => {
      return (
        _.some(companyFilterChecks, (ci) => ci.name === reportRow.companyName)
        ||
        _.some(terminalFilterChecks, (t) => t.desc === reportRow.terminalName)
        ||
        _.some(salesRepFilterChecks, (s) => s.salesAgent === reportRow.username)
      ) && !(
        _.some(companyFilterUnchecks, (ci) => ci.name === reportRow.companyName)
        ||
        _.some(terminalFilterUnchecks, (t) => t.desc === reportRow.terminalName)
        ||
        _.some(salesRepFilterUnchecks, (s) => s.salesAgent === reportRow.username)
      );
    });

    return bookedSalesData;
  }

  @bind
  private _clearReport() {
    this.props.reportService.clearBookedSalesReport();
    this.props.reportService.fetchBookedSalesFilters();
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  render() {
    const {
      bookedSalesReportFetchResults,
      bookedSalesFiltersFetchResults
    } = this.props.reportService.getState();

    const bookedSalesData = this._filterReportData();

    const cardTitle = this._getReportTitle();
    
    const totalSales = _.sumBy(bookedSalesData, "charges");
    const totalWeight = _.sumBy(bookedSalesData, "totalWeight");
    const totalFeet = _.sumBy(bookedSalesData, "totalRollupLength");
    const totalRFM = _.sumBy(bookedSalesData, "rfm");
    const totalBills = _.sumBy(bookedSalesData, "bills");
    const pinnedRow: GridPinnedRowsProp = {
      bottom: bookedSalesData.length > 0 ? [{
        companyName: "Totals",
        terminalName: "",
        username: "",
        charges: totalSales ?? "",
        totalWeight: totalWeight ?? "",
        rk: "",
        rkm: "",
        totalRollupLength: totalFeet ?? "",
        rf: "",
        rfm: totalRFM ?? "",
        totalDistance: "",
        bills: totalBills ?? ""
      }] : []
    };

    let rowIdCounter = 0;

    return (
      <div
        className={styles.mainContainer}
      >
        <div className={styles.filterContainer}>
          <CardLinedHeader
            titleText="Dates"
            style={{ flexGrow: 1, margin: "5px" }}
          >
            <AjaxActionIndicator state={[bookedSalesFiltersFetchResults]} />
            <BookedSalesDateRangePicker
              onChange={this._onDateChange}
              onClear={this._clearReport}
              filtersReady={bookedSalesFiltersFetchResults.hasFetched}
            />
          </CardLinedHeader>
          <CardLinedHeader
            titleText="Filters"
            style={{ flexGrow: 1 }}
          >
            <AjaxActionIndicator state={[bookedSalesFiltersFetchResults]} />
            <BookedSalesFilters />
          </CardLinedHeader>
        </div>

        <div className={styles.bookedSalesContainer}>
          <CardLinedHeader
            titleText={cardTitle}
            style={{ flexGrow: 1 }}
            titleComponents={
              <Button
                className="cardHeaderButton"
                onClick={() => OnExportCsvClick('Booked_Sales_Report_', this._gridApiRef)}
                title="Download"
                disabled={bookedSalesData.length === 0}
              >
                <Download /><b> Download</b>
              </Button>
            }
          >
            <AjaxActionIndicator state={[bookedSalesReportFetchResults]} />
            <DataGridPro
              columns={this.gridColumns}
              rows={bookedSalesData}
              pinnedRows={bookedSalesData.length > 0 ? pinnedRow : undefined}
              classes={{
                columnHeaders: styles.headerRow
              }}
              experimentalFeatures={{ rowPinning: true }}
              density="compact"
              getRowId={(row) => {
                rowIdCounter++;
                return rowIdCounter;
              }}
              components={{
                NoRowsOverlay: () =>
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {!bookedSalesReportFetchResults.hasFetched
                      ? "Enter search criteria to view booked sales data"
                      : "No booked sales data for selected criteria"
                    }
                  </Stack>,
                NoResultsOverlay: () =>
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No booked sales data for selected criteria
                  </Stack>
              }}
              getRowClassName={(params: GridRowParams<BookedSalesReportView>) => {
                return params.row.companyName === "Totals" ? styles.totalRow : "";
              }}
              apiRef={this._gridApiRef}
              hideFooter
              disableSelectionOnClick
              autoHeight
            />
          </CardLinedHeader>

          <div style={{ display: "flex" }}>
            <CardLinedHeader
              titleText={"Sales"}
              style={{ flex: "1 0 412px" }}
            >
              <GraphSales bookedSalesData={bookedSalesData} />
            </CardLinedHeader>

            <CardLinedHeader
              titleText={"Weight"}
              style={{ flex: "1 0 412px" }}
            >
              <GraphWeight bookedSalesData={bookedSalesData} />
            </CardLinedHeader>

            <CardLinedHeader
              titleText={"Feet"}
              style={{ flex: "1 0 412px" }}
            >
              <GraphFeet bookedSalesData={bookedSalesData} />
            </CardLinedHeader>
          </div>
        </div>
      </div>
    );
  }
}

export const BookedSalesView = ReportService.inject(
  _BookedSalesView
);