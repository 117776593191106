import {
  React
} from "$Imports/Imports";

import {
  SalesHistoryView
} from "./SalesHistoryView";

interface IOwnProps { }

export class SalesHistoryViewPage extends React.Component<IOwnProps> {
  render() {
    return (
      <SalesHistoryView companyId={undefined} />
    );
  }
}