import {
  React,
  bind
} from "$Imports/Imports";

import {
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  TextField
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  allOriginPostalCodes: string[];
  allDestinationPostalCodes: string[];
  onSubmit: (originPostalCodes: string[], destinationPostalCodes: string[]) => void;
}

interface IOwnState {
  selectedOriginPostalCodes: string[];
  selectedDestinationPostalCodes: string[];
}

type PostalCodeType = "selectedOriginPostalCodes" | "selectedDestinationPostalCodes";

const styles: {
  controlsContainer: string;
  chips: string;
  actions: string;
} = require("./LaneRevenueFilters.scss");

class _LaneRevenueFilters extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    selectedOriginPostalCodes: [],
    selectedDestinationPostalCodes: []
  };

  componentDidUpdate(prev: IOwnProps) {
    // ensure changes to available lists also trim the selected lists
    if (this.props.allOriginPostalCodes !== prev.allOriginPostalCodes) {
      this.setState((prevState) => ({
        selectedOriginPostalCodes: prevState.selectedOriginPostalCodes.filter((x) => this.props.allOriginPostalCodes.indexOf(x) > -1)
      }));
    }

    if (this.props.allDestinationPostalCodes !== prev.allDestinationPostalCodes) {
      this.setState((prevState) => ({
        selectedDestinationPostalCodes: prevState.selectedDestinationPostalCodes.filter((x) => this.props.allDestinationPostalCodes.indexOf(x) > -1)
      }));
    }
  }

  @bind _onChangeSelection(postalCodeType: PostalCodeType, event: React.SyntheticEvent, value: string[] | null, reason: AutocompleteChangeReason) {
    this.setState((prev) => ({
      ...prev,
      [postalCodeType]: value
    }));
  }

  @bind
  private _onRemoveSelection(postalCodeType: PostalCodeType, value: { }) {
    this.setState((prev) => ({
      ...prev,
      [postalCodeType]: prev[postalCodeType].filter(x => x !== value)
    }));
  }

  @bind
  private _onSubmit() {
    const {
      selectedOriginPostalCodes,
      selectedDestinationPostalCodes
    } = this.state;

    this.props.onSubmit(selectedOriginPostalCodes, selectedDestinationPostalCodes);
  }

  @bind
  private _onClear() {
    this.setState({
      selectedOriginPostalCodes: [],
      selectedDestinationPostalCodes: []
    });

    this.props.onSubmit([], []);
  }

  render() {
    const {
      allOriginPostalCodes,
      allDestinationPostalCodes
    } = this.props;

    const {
      selectedOriginPostalCodes,
      selectedDestinationPostalCodes
    } = this.state;

    return (
      <CardLinedHeader titleText="Filters">
        <div className={styles.controlsContainer}>
          <FormControl fullWidth>
            <Autocomplete
              options={allOriginPostalCodes}
              value={selectedOriginPostalCodes}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Origin Zip"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Search for zip code"                  
                />
              )}              
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Checkbox checked={selected} />
                  <ListItemText>{option}</ListItemText>
                </MenuItem>
              )}
              renderTags={() => undefined}
              onChange={(event, value, reason) => this._onChangeSelection("selectedOriginPostalCodes", event, value, reason)}
              disableClearable
              disableCloseOnSelect              
              multiple
            />

            {(selectedOriginPostalCodes.length > 0) &&
              <div className={styles.chips}>
                {selectedOriginPostalCodes.map((x, idx) => (
                  <Chip
                    key={idx}
                    label={x}
                    onDelete={() => this._onRemoveSelection("selectedOriginPostalCodes", x)}
                  />
                ))}
              </div>
            }
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              options={allDestinationPostalCodes}
              value={selectedDestinationPostalCodes}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}                
                  label="Destination Zip"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Search for zip code"                  
                />
              )}              
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props} key={option} value={option}>
                  <Checkbox checked={selected} />
                  <ListItemText>{option}</ListItemText>
                </MenuItem>
              )}
              renderTags={() => undefined}
              onChange={(event, value, reason) => this._onChangeSelection("selectedDestinationPostalCodes", event, value, reason)}
              disableClearable
              disableCloseOnSelect                            
              multiple
            />

            {(selectedDestinationPostalCodes.length > 0) &&
              <div className={styles.chips}>
                {selectedDestinationPostalCodes.map((x, idx) => (
                  <Chip
                    key={idx}
                    label={x}
                    onDelete={() => this._onRemoveSelection("selectedDestinationPostalCodes", x)}
                  />
                ))}
              </div>
            }
          </FormControl>

          <div className={styles.actions}>
            <Button
              color="primary"
              onClick={this._onSubmit}
              disabled={!allOriginPostalCodes.length && !allDestinationPostalCodes.length}
            >
              Update
            </Button>
            <Button
              onClick={this._onClear}
              disabled={!selectedOriginPostalCodes.length && !selectedDestinationPostalCodes.length}
            >
              Clear
            </Button>
          </div>
        </div>
      </CardLinedHeader>
    )
  }
}

export const LaneRevenueFilters = _LaneRevenueFilters;
