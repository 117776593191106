import {
    React
} from "$Imports/Imports";
import { 
    Card, 
    CardProps 
} from "$Imports/MaterialUIComponents";

const styles: {
    mainContainer: string;
    cardHeader: string;
} = require("./CardLinedHeader.scss");

interface ICardLinedHeaderBaseProps {
    titleText?: JSX.Element | string;
    titleComponents?: React.ReactNode;
}

type ICardLinedHeaderProps = ICardLinedHeaderBaseProps & CardProps;

export const CardLinedHeader: React.FunctionComponent<ICardLinedHeaderProps> = (props: ICardLinedHeaderProps) => {
    const {
        titleText,
        titleComponents,
        ...passThroughProps
    } = props;
    
    return (
        <>
            <Card className={styles.mainContainer} {...passThroughProps}>
                <div className={styles.cardHeader}>
                    {titleText}
                    {titleComponents}
                </div>
                {props.children}
            </Card>
        </>
    );
}