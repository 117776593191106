import {
  React,
} from "$Imports/Imports";

import {
  ThemeConsumer
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  NoCompanyAccessView
} from "./NoReportingAccessView";

import {
  _
} from "$Imports/Imports";

interface IMainLayoutBaseProps {
  applicationBar: JSX.Element;
  sideNavigation: JSX.Element;
}

type IMainLayoutProps = IMainLayoutBaseProps & INavigationServiceInjectedProps;

const styles: {
  applicationContainer: string;
  applicationBar: string;
  subContainer: string;
  sideNavigation: string;
  mainContent: string;
} = require("./MainLayout.scss");

class _MainLayout extends React.Component<IMainLayoutProps> {
  private async _onLogout() {   
    await SharedSecurityContext.logout();
  }

  render() {
    let sideNavigationItems = this.props.navigationService.getSideNavigationItems();
    const currentNaviationItem = this.props.navigationService.getNavigationDetail();

    return (
      <ThemeConsumer>
        {(context) => {
          const theme = context.themeConfig;
          const user = SharedSecurityContext.getUser();

          if (!user) {
            this._onLogout();
          }

          if (!!user && !SecurityContext.canAccessReportingPortal()) {
            return <NoCompanyAccessView />;
          }

          return (
              <div
                className={styles.applicationContainer}
              >
                {currentNaviationItem?.displayMainLayout && <div
                  className={styles.applicationBar}
                >
                  {this.props.applicationBar}
                </div>}
                <div
                  className={styles.subContainer}
                >
                  {currentNaviationItem?.displayMainLayout &&
                    <div
                      style={{
                        backgroundColor: theme.sideNavigationColor
                      }}
                      className={styles.sideNavigation}
                      hidden={!sideNavigationItems}
                    >
                      {this.props.sideNavigation}
                    </div>}
                  <div
                    className={styles.mainContent}
                  >
                    {this.props.children}
                  </div>
                </div>
              </div>
          );
        }}
      </ThemeConsumer>
    );
  }
}

export const MainLayout = NavigationService.inject(
  _MainLayout,
);

