// for use in numeral(...).format or <Display* /> components
export const CURRENCY_FORMAT = "$0,0.00";
export const CURRENCY_NO_DECIMAL_FORMAT = "$0,0";
export const NUMERIC_SEPARATED_FORMAT = "0,0";
export const NUMERIC_DECIMAL_SEPARATED_FORMAT = "0,0.0";
export const NUMERIC_TWO_DECIMAL_SEPARATED_FORMAT = "0,0.00";
export const DATE_ONLY_FORMAT = "MM/DD/YYYY";
export const DATE_WITH_TIME_MERIDIAN_FORMAT = "MM/DD/YYYY hh:mm A";
export const DATE_WITH_TIME_SECONDS_MERIDIAN_FORMAT = "MM/DD/YYYY hh:mm:ss A";
export const TIME_MERIDIAN_FORMAT = "hh:mm A";
export const TIME_WITH_SECONDS_FORMAT = "HH:mm:ss";
export const TIME_FORMAT = "HH:mm";
export const PERCENTAGE_TWO_DECIMALS = "0.00%";
export const PERCENTAGE_ONE_DECIMAL = "0.0%";