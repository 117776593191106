import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  Employee,
  EmployeeApiFactory,
  EmployeeSearchCriteria
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IEmployeeServiceState {
  salesRepFetchResults: IAjaxState<Employee[]>;
}

const InjectedPropName = "employeeService";

const initialState = {
  salesRepFetchResults: managedAjaxUtil.createInitialState(),
} as IEmployeeServiceState;

class EmployeeFreezerService extends FreezerService<IEmployeeServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchSalesReps(forceUpdate: boolean = false) {
    const { salesRepFetchResults } = this.freezer.get();

    if (salesRepFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return this.fetchEmployees("salesRepFetchResults", "sales representatives", { isSalesRep: true });
  }

  private async fetchEmployees(property: keyof IEmployeeServiceState, type: string, criteria: EmployeeSearchCriteria) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: property,
      params: {
        body: criteria
      },
      onExecute: (apiOptions, params, option) => {
        const factory = EmployeeApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.searchEmployees(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(`Failed to fetch ${type}`);
      }
    });
  }
}

export const EmployeeService = new EmployeeFreezerService();
export type IEmployeeServiceInjectedProps = ReturnType<EmployeeFreezerService["getPropsForInjection"]>;
