import { React, withRouter } from "$Imports/Imports";
import { NavigationService } from "$State/NavigationFreezerService";
import { RouteComponentProps } from "react-router";

class AppContainerComponent extends React.Component<RouteComponentProps> {

  render() {
    NavigationService.initHistory(this.props.history);

    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

const AppContainer = withRouter(AppContainerComponent);
export { AppContainer };
