import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  Company,
  DeliveredFreightSearchCriteria
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import { DeliveredFreightReportSearchCriteriaValidationSchema } from "$State/ReportFreezerService";

const styles: {
  searchContainer: string;
} = require("./DeliveredFreight.scss");

interface IOwnProps {
  criteria: DeliveredFreightSearchCriteria;
  companies: Company[];
  onSubmit: (criteria: DeliveredFreightSearchCriteria) => void;
}

interface IOwnState {
  criteria: DeliveredFreightSearchCriteria;
  errors: ValidationError | null;
}

export class DeliveredFreightSearchForm extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    criteria: {},
    errors: null
  };

  componentDidMount() {
    this.setState({ criteria: this.props.criteria });
  }

  componentDidUpdate(prev: IOwnProps) {
    if (this.props.criteria !== prev.criteria) {
      this.setState({ criteria: this.props.criteria });
    }
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }
  
  @bind
  private _onCompanyChange(event: SelectChangeEvent<number | string>) {
    const companyId = event?.target.value as number;
    this.setState((prev) => ({
      criteria: {
        ...prev.criteria,
        companyId: companyId === 0 ? undefined : companyId
      }
    }));
  }

  @bind
  private async _onSearchClick() {
    const errors = await validateSchema(DeliveredFreightReportSearchCriteriaValidationSchema, this.state.criteria);
    this.setState({ errors: errors });

    if (errors) {
      return;
    }

    this.props.onSubmit(this.state.criteria);
  }

  render() {
    const {
      companies
    } = this.props;

    const {
      criteria,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<DeliveredFreightSearchCriteria>(errors);

    return (
      <SearchControlsContainer
        className={styles.searchContainer}
        onSubmit={this._onSearchClick}
      >
        <DateRangePicker
          startDate={criteria.startDate}
          startError={validationParser.validationMessage("startDate")}
          endDate={criteria.endDate}
          endError={validationParser.validationMessage("endDate")}
          onChange={this._onDateRangeChange}
          horizontal
        />
        <FormControl style={{ flex: "0 1 13.875rem" }}>
          <InputLabel shrink>Company</InputLabel>
          <Select
            value={criteria.companyId ?? 0}
            name="companyId"
            onChange={(event) => this._onCompanyChange(event as React.ChangeEvent<HTMLInputElement>)}
            displayEmpty
          >
            <MenuItem value={0}>All</MenuItem>
            {companies.map((s, idx) => (
              <MenuItem value={s.tmcompanyId} key={idx}>{s.companyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </SearchControlsContainer>
    );
  }
}