import { 
    CardLinedHeader 
} from "$Components/common";
import { 
    CapacityReportRow, 
    CapacityReportTerminal 
} from "$Generated/api";
import { 
    React, 
    _ 
} from "$Imports/Imports";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow, 
    TableSortLabel, 
    Tooltip 
} from "$Imports/MaterialUIComponents";
import { 
    CheckCircle, 
    Error, 
    Info 
} from "$Imports/MaterialUIIcons";

const styles: {
    bordered: string;
    cellContent: string;
} = require("./ReportCard.scss");

type ReportFlavor = "inbound" | "outbound";

interface IReportCardProps {
    titleText: string;
    flavor: ReportFlavor;
    data?: CapacityReportTerminal;
    costPerMile?: number;
}

interface IColumnDef {
    id: string;
    label: string;
    format: (column: IColumnDef, value: string | number | undefined) => JSX.Element;
}

type DataKey = keyof CapacityReportRow;

type Order = "asc" | "desc" | undefined;

interface IReportCardState {
    orderBy: string | null;
    order: Order;
}

export const ReportCard: React.FunctionComponent<IReportCardProps> = (props: IReportCardProps) => {
    const defaultFormat = (column: IColumnDef, value: string | number | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } else if (typeof value === "string") {
            return <>{value}</>;
        } else {
            return <>{Number.isNaN(value) ? "-" : value.toLocaleString()}</>;
        }
    };

    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const currencyFormat = (column: IColumnDef, value: string | number | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } else if (typeof value === "string") {
            return <>{value}</>;
        } else {
            return <>
                <span className={styles.cellContent}>
                    { column.id === "rpm" && <>
                        { value > (props.costPerMile ?? 0) && <CheckCircle color="success" /> }
                        { value < (props.costPerMile ?? 0) && <Error color="error" /> }
                    </> }
                    {Number.isNaN(value) ? "-" : currencyFormatter.format(value)}
                </span>
            </>;
        }
    };

    const percentFormat = (column: IColumnDef, value: string | number | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } else if (typeof value === "string") {
            return <>{value}</>;
        } else {
            return <>
                <span className={styles.cellContent}>
                    { value >= 10 && <Error color="error" /> }
                    { value < 2 && <CheckCircle color="success" /> }
                    {Number.isNaN(value) ? "-" : `${value.toFixed(2)}%`}
                </span>
            </>;
        }
    };

    const bolFormat = (column: IColumnDef, value: string | number | undefined): JSX.Element => {
        if (value === undefined) {
            return <>{""}</>;
        } else if (typeof value === "string") {
            return <>{value}</>;
        } else {
            return <>{Number.isNaN(value) ? "-" : `${value.toFixed(1)}`}</>;
        }
    };
    
    const columns: IColumnDef[] = [
        { 
            id: "zone", 
            label: props.flavor === "outbound" ? "Destination Zone" : "Origin Zone",
            format: defaultFormat,
        },
        { 
            id: "tripCount", 
            label: "# of Trips",
            format: defaultFormat,
        },
        { 
            id: "miles", 
            label: props.flavor === "outbound" ? "Outbound Miles" : "Inbound Miles",
            format: defaultFormat,
        },
        { 
            id: "rpm", 
            label: props.flavor === "outbound" ? "Outbound RPM" : "Inbound RPM",
            format: currencyFormat,
        },
        { 
            id: "rpk", 
            label: props.flavor === "outbound" ? "Outbound RPK" : "Inbound RPK",
            format: currencyFormat,
        },
        { 
            id: "averageOpenWeight", 
            label: "Avg Open Weight",
            format: defaultFormat,
        },
        { 
            id: "openCapacity", 
            label: "% Open Capacity",
            format: percentFormat,
        },
        { 
            id: "averageBolCount", 
            label: "Avg BOL Count",
            format: bolFormat,
        },
    ];

    const dataRows: CapacityReportRow[] = props.data?.rows ?? [];

    const totalRow: CapacityReportRow = React.useMemo(() => {
        return {
            zone: "Totals",
            tripCount: _.sumBy(dataRows, "tripCount"),
            miles: _.sumBy(dataRows, "miles"),
            rpm: _.meanBy(dataRows, "rpm"),
            rpk: _.meanBy(dataRows, "rpk"),
            averageOpenWeight: _.round(_.meanBy(dataRows, "averageOpenWeight")),
            openCapacity: _.meanBy(dataRows, "openCapacity"),
            averageBolCount: _.meanBy(dataRows, "averageBolCount"),
        };
    }, [dataRows]);

    const rpmToolTipText = "Includes P+D miles for trips departing the terminal in the selected date range";

    const [state, setState] = React.useState<IReportCardState>({
        orderBy: null,
        order: undefined,
    });

    const updateSort = (columnId: string) => {
        const curState = state as IReportCardState;
        let newState = { ...curState };
        if (curState.orderBy === columnId) {
            if (curState.order === "asc") {
                newState.order = "desc";
            } else {
                newState.order = "asc";
            }
        } else {
            newState = {
                orderBy: columnId,
                order: "asc",
            }
        }
        setState(newState);
    }

    const sortedData: CapacityReportRow[] = React.useMemo(() => {
        if (state.orderBy === null || state.order === undefined) {
            return _.orderBy(dataRows, ["tripCount", "miles", "zone"], ["desc", "desc", "asc"]);
        } else {
            return _.orderBy(dataRows, [state.orderBy], [state.order]);
        }
    }, [dataRows, state.orderBy, state.order]);
    
    return (
        <>
            <CardLinedHeader titleText={props.titleText}>
                <Table className={styles.bordered}>
                    <TableHead className={styles.bordered}>
                        <TableRow>
                            {columns.map((column: IColumnDef) => 
                                <TableCell key={column.id}>
                                    <span className={styles.cellContent}>
                                        <TableSortLabel
                                            active={state.orderBy === column.id}
                                            direction={state.orderBy === column.id ? state.order : "asc"}
                                            onClick={(e) => updateSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                        { props.flavor === "outbound" && column.id === "rpm" && 
                                            <Tooltip title={rpmToolTipText}>
                                                <Info />
                                            </Tooltip>
                                        }
                                    </span>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row: CapacityReportRow) => 
                            <TableRow key={row.zone}>
                                {columns.map((column: IColumnDef) => 
                                    <TableCell key={`${row.zone}-${column.id}`}>
                                        {row.tripCount === 0 && column.id !== "zone" && column.id !== "tripCount" ? "-" : column.format(column, row[column.id as DataKey])}
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                    <TableHead className={styles.bordered}>
                        <TableRow>
                            {columns.map((column: IColumnDef) => 
                                <TableCell key={`totals-${column.id}`}>
                                    {column.format(column, totalRow[column.id as DataKey])}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                </Table>
            </CardLinedHeader>
        </>
    );
}