import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow,
  TableHead,
  TableCell
} from "$Imports/MaterialUIComponents";

import {
  DataGridHeaderSortColumn,
} from "./DataGridHeaderSortColumn";

import {
  TableContextConsumer
} from "./DataTable";

import {
  IDataTableColumn,
  directionType
} from "./IDataTableColumn";

interface IDataTableHeaderProps<T = unknown> {
  sortColumnName?: string;
  sortDirection?: directionType;
  columns: Array<IDataTableColumn<T>>;
  onSortColumnClick?: (event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortDirection: directionType) => void;
  suppressSorting?: boolean;
}

export class DataTableHeader<T = unknown> extends React.PureComponent<IDataTableHeaderProps<T>> {

  @bind
  private _onSortColumnClick(event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortColumnClick) {
      this.props.onSortColumnClick(event, sortColumnName, sortDirection);
    }
  }

  render() {
    const { sortDirection, sortColumnName, suppressSorting, columns } = this.props;

    return (
      <TableContextConsumer>
        {(context) => {
          return (
            <TableHead>
              <TableRow>
                {_.map(columns, (c, cIdx: number) => {
                  if (context && context.hiddenColumns && context.hiddenColumns.includes(c.columnName)) {
                    return;
                  }

                  if (context && context.disableAction && c.columnName === 'action') {
                    return;
                  }

                  const headerProps = c.headerProps ? c.headerProps : {};
                  const { onClick, ref, ...passThroughHeaderProps } = headerProps;

                  return (c.sortMethod !== undefined || suppressSorting) ? (
                    <DataGridHeaderSortColumn
                      key={cIdx}
                      label={c.headerValue}
                      {...passThroughHeaderProps}
                      propertyName={c.columnName}
                      direction={c.columnName === sortColumnName ? sortDirection : null}
                      onSortClick={this._onSortColumnClick}
                    />
                  ) : (
                    <TableCell
                      key={cIdx}
                      {...passThroughHeaderProps}
                    >
                      {c.headerValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )
        }}
      </TableContextConsumer>);
  }
}
